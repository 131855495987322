/* RESPONSIBLE TEAM: team-help-desk-experience */
import { tracked } from '@glimmer/tracking';

export enum Channel {
  Unknown = -1,
  Desktop = 0,
  Android = 1,
  IOS = 2,
  Email = 3,
  Twitter = 4,
  Facebook = 5,
  FacebookMessenger = 6,
  Whatsapp = 7,
  Instagram = 8,
  PhoneSwitch = 9,
  SMS = 10,
  PhoneCall = 11,
  ZendeskTicket = 12,
  ZendeskSunshineConversation = 13,
  SalesforceCase = 14,
}

export const UNSUPPORTED_CHANNELS_FOR_PREDICATES = [
  Channel.Unknown,
  Channel.ZendeskTicket,
  Channel.ZendeskSunshineConversation,
  Channel.SalesforceCase,
];

export const CHANNEL_NAME_TRANSLATION_KEYS: Record<Channel, string> = {
  [Channel.Unknown]: 'common.predicates.channel.name.unknown',
  [Channel.Desktop]: 'common.predicates.channel.name.desktop',
  [Channel.Android]: 'common.predicates.channel.name.android',
  [Channel.IOS]: 'common.predicates.channel.name.ios',
  [Channel.Email]: 'common.predicates.channel.name.email',
  [Channel.Twitter]: 'common.predicates.channel.name.twitter',
  [Channel.Facebook]: 'common.predicates.channel.name.facebook',
  [Channel.FacebookMessenger]: 'common.predicates.channel.name.facebook-messenger',
  [Channel.Whatsapp]: 'common.predicates.channel.name.whatsapp',
  [Channel.Instagram]: 'common.predicates.channel.name.instagram',
  [Channel.PhoneSwitch]: 'common.predicates.channel.name.phone-switch',
  [Channel.PhoneCall]: 'common.predicates.channel.name.phone-call',
  [Channel.SMS]: 'common.predicates.channel.name.sms',
  [Channel.ZendeskTicket]: 'common.predicates.channel.name.zendesk-ticket',
  [Channel.ZendeskSunshineConversation]:
    'common.predicates.channel.name.zendesk-sunshine-conversation',
  [Channel.SalesforceCase]: 'common.predicates.channel.name.salesforce-case',
};

export const SOCIAL_CHANNELS = [
  Channel.Whatsapp,
  Channel.Twitter,
  Channel.Facebook,
  Channel.Instagram,
  Channel.SMS,
];

export const CHAT_CHANNELS = [Channel.Desktop, Channel.Android, Channel.IOS];

export interface ChannelDataWireFormat {
  initial: Channel;
  current: Channel;
}

export class ChannelData {
  @tracked initial: Channel;
  @tracked current: Channel;

  constructor(initial: Channel, current: Channel) {
    this.initial = initial;
    this.current = current;
  }

  static deserialize(json: ChannelDataWireFormat) {
    return new ChannelData(json.initial, json.current);
  }

  get replyChannel() {
    if (
      this.current === undefined ||
      this.current === Channel.Unknown ||
      this.current === Channel.Email
    ) {
      return this.initial === undefined ? Channel.Unknown : this.initial;
    }
    return this.current === undefined ? Channel.Unknown : this.current;
  }
}
