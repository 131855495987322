/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { inject as service } from '@ember/service';
import { type Block } from 'embercom/models/common/blocks/block';
import Model, { attr, belongsTo, type SyncHasMany } from '@ember-data/model';
import type IntlService from 'embercom/services/intl';
import { type LocalizedKnowledgeContentVersion } from 'embercom/objects/knowledge-hub/localized-knowledge-content-version';
import type ContentSnippet from './content-snippet';
import { fragmentArray } from 'ember-data-model-fragments/attributes';

export default class ContentSnippetVersion
  extends Model
  implements LocalizedKnowledgeContentVersion
{
  @service appService: any;
  @service declare intl: IntlService;

  get app() {
    return this.appService.app;
  }

  @attr('string') declare createdBy: number;
  @attr('string') declare title: string;
  @fragmentArray('common/blocks/block', { polymorphic: true, typeKey: 'modelKey' })
  declare jsonBlocks: SyncHasMany<Block>;
  @fragmentArray('common/blocks/block', { polymorphic: true, typeKey: 'modelKey' })
  declare jsonBlocksForEditor: SyncHasMany<Block>;
  @belongsTo('content-service/content-snippet', { inverse: 'currentVersion', async: false })
  declare contentSnippet: ContentSnippet;

  get createdByTeammate() {
    return this.store.peekRecord('admin', this.createdBy);
  }

  get creatorId() {
    return this.createdBy;
  }

  get localisedJsonBlocks() {
    return this.jsonBlocks;
  }

  get localization() {
    return this.contentSnippet;
  }

  get versionTitle(): string | undefined {
    return this.title;
  }

  set versionTitle(value: string | undefined) {
    this.title = value ?? '';
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'content-service/content-snippet-version': ContentSnippetVersion;
  }
}
