/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-observers */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/use-ember-data-rfc-395-imports */
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { computed, observer } from '@ember/object';
import {
  alias,
  and,
  bool,
  equal,
  filterBy,
  mapBy,
  not,
  notEmpty,
  or,
  readOnly,
  reads,
  uniq,
} from '@ember/object/computed';
import { on } from '@ember/object/evented';
import { throttle } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { capitalize } from '@ember/string';
import { isBlank, isEmpty, isPresent } from '@ember/utils';
import {
  findBy,
  flatMap,
  isAny,
  isEvery,
  rejectBy,
  subtractProperties,
  ternaryToProperty,
} from '@intercom/pulse/lib/computed-properties';
import { task } from 'ember-concurrency';
import DS from 'ember-data';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import ENV from 'embercom/config/environment';
import ajax from 'embercom/lib/ajax';
import {
  ANSWER_BOT_ID,
  ARTICLES_ID,
  AppPricing5PriceModels,
  CORE_CONVERT_ID,
  CORE_ENGAGE_ID,
  CORE_ID,
  CORE_STARTER_ID,
  CORE_SUPPORT_ID,
  FIN_AI_COPILOT_ID,
  INBOX_ID,
  MESSAGES_ID,
  OPERATOR_ID,
  PRICING_5_X_CORE_ID,
  PRODUCTS,
  PRODUCT_TOURS_ID,
  SMS_ID,
  SURVEYS_ID,
} from 'embercom/lib/billing';
import AppColor from 'embercom/lib/color';
import containerLookup, { getEmberDataStore } from 'embercom/lib/container-lookup';
import IntercomradesProductsOverride from 'embercom/lib/intercomrades-products-override';
import PromiseArray from 'embercom/lib/promise-array';
import { BROWSER_SUPPORTS_TOUCH } from 'embercom/lib/screen-awareness';
import {
  COLLABORATOR_SEAT_TYPE,
  PRICING_5_X_LITE_SEAT_TYPE,
} from 'embercom/lib/settings/seats/constants';
import { formattedTimezone } from 'embercom/lib/time-zone';
import EmailTemplate from 'embercom/models/email-template';
import Translations from 'embercom/models/translations';
import moment from 'moment-timezone';
import { all } from 'rsvp';
import FeatureFlags from './mixins/feature-flags';

const OWNER_ID = '-1';
const PRICING_PAGE = 'https://www.intercom.com/pricing';
const PRICING_PAGE_FOR_P_5_0 = 'https://www.intercom.com/pricing-archive/p5-0';
const PRICING_PAGE_FOR_VBP_2 = 'https://www.intercom.com/pricing-archive/vbp-2';
const PRICING_PAGE_FOR_VBP_1_2 = 'https://www.intercom.com/pricing-archive/vbp-1-2';
const PRICING_PAGE_FOR_PPP = 'https://www.intercom.com/pricing-archive/ppp';
const METERED_MESSAGES_ARTICLE_ID_5_0 = 8259544;
const METERED_MESSAGES_ARTICLE_ID_5_1 = 9061703;
const PROACTIVE_SUPPORT_PRO_ARTICLE_ID_5_0 = 8205747;
const PROACTIVE_SUPPORT_PRO_ARTICLE_ID_5_1 = 9061648;

let App = Model.extend(FeatureFlags, {
  regionService: service(),
  modelDataCacheService: service(),
  store: service(),

  is_standalone_app: attr(),
  isStandaloneApp: readOnly('is_standalone_app'),

  standalone_platforms: attr(),
  hasStandalonePlatform(platformName) {
    return this.standalone_platforms?.some((plat) => plat.platform_type === platformName) ?? false;
  },

  customer_id: attr(),
  customerId: readOnly('customer_id'),
  // Fake relationship, manually reloaded via refreshCustomer function in
  // the customer-service
  customer: null,

  created_at: attr(),
  name: attr(),
  people_count: attr(),
  anonymous_count: attr(),
  company_count: attr(),
  article_count: attr(),
  companies_activated: attr(),
  companies_enabled: attr(),
  open_message_thread_count: attr(),
  admin_open_message_thread_count: attr(),

  base_color: attr(),
  is_test_app: attr(),
  isTestApp: alias('is_test_app'),
  isDeveloperWorkspace: alias('developer_workspace'),
  isNotDeveloperWorkspace: not('isDeveloperWorkspace'),
  scim_enabled: attr(),
  saml_enabled: attr(),
  test_app_id: attr(),
  commentPlaceholder: attr(),
  defaultAssigneeId: attr(),
  hideAllUnsubscribeLinks: attr(),
  is_activated: attr(),
  isActivated: readOnly('is_activated'),
  is_web_messenger_disabled: attr(),
  isWebMessengerDisabled: readOnly('is_web_messenger_disabled'),
  is_frozen_for_non_payment: attr(),
  isFrozenForNonPayment: readOnly('is_frozen_for_non_payment'),
  isSuspended: attr(),
  installed_at: attr(),
  parent_app_installed_at: attr(),
  parent_app_id: attr(),
  parent_app_opted_in_to_salesforce_beta: attr(),
  show_powered_by: attr(),
  company_size: attr(),
  has_mobile_integration: attr(),
  has_active_github_integration: attr(),
  has_active_jira_integration: attr(),
  has_enabled_github_integration: attr(),
  has_salesforce_integration: attr(),
  has_slack_notifications_integration: attr(),
  has_marketo_enrichment_integration: attr(),
  has_hubspot_integration: attr(),
  has_pipedrive_integration: attr(),
  has_started_trial: attr(),
  has_active_trials: attr(),
  in_cardless_trial: attr('boolean'),
  has_pending_stripe_migration: attr('boolean'),
  early_stage_partner: attr(),
  crm_profiles: attr(),
  primary_user_source_is_import_type: attr(),
  has_active_whatsapp_integration: attr(),
  pricing5PricingModel: attr(),
  isSalesforceContracted: attr('boolean'),
  isNotSalesforceContracted: not('isSalesforceContracted'),
  canSelfManageContract: attr('boolean'),
  // Temporary value set on app creation to enforce a stripe customer
  // We can retire this logic after we start defaulting to stripe customers in billing
  // Will always be undefined after app creation as we're not serializing this value up to embercom
  force_stripe_billing: attr('boolean'),

  unsubscribe_link_recipient_threshold: attr(),
  trustedForUnsubscribeLinks: equal('unsubscribe_link_recipient_threshold', 0),

  impersonation_session: attr(),
  awaiting_email_approval: attr(),
  invitingPermitted: attr(),
  restricted_to_engaged_only: attr(),
  email_rate_limited: attr(),
  android_devices: attr(),
  android_os_versions: attr(),
  android_app_versions: attr(),
  ios_app_versions: attr(),
  trialLengthInDays: attr('number', { key: 'trial_length_in_days' }),
  inbox_email: attr(),
  unread_mention_threads: attr(),
  content_events_config: attr(),
  android_sdk_version: attr(),
  ios_sdk_version: attr(),
  has_intershop_access: attr('boolean'),
  hasIntershopAccess: alias('has_intershop_access'),
  onPricing5: attr('boolean'),
  notOnPricing5: not('onPricing5'),
  onFinForPlatforms: attr('boolean'),
  onFinForPlatformsOrPricing5: or('onFinForPlatforms', 'onPricing5'),
  ai_answers_state: attr(),
  aiAnswersState: alias('ai_answers_state'),
  fin_free_usage_window: belongsTo('fin-free-usage-window', { async: false }),
  finFreeUsageWindow: alias('fin_free_usage_window'),
  wasCreatedAfterFinGeneralRelease: attr('boolean'),
  wasCreatedAfterCardlessFinTrialRelease: attr('boolean'),
  has_setup_email_forwarding: attr('boolean'),
  ai_assist_conversation_summarization_enabled: attr('boolean'),
  hasSummarizationSettingEnabled: alias('ai_assist_conversation_summarization_enabled'),
  ai_assist_text_transformation_enabled: attr('boolean'),
  hasTextTransformationSettingEnabled: alias('ai_assist_text_transformation_enabled'),
  ai_assist_auto_fill_enabled: attr('boolean'),
  hasAutoFillSettingsEnabled: alias('ai_assist_auto_fill_enabled'),
  messenger_cda_creation_legacy: attr('boolean'),
  isMessengerCdaCreationLegacy: alias('messenger_cda_creation_legacy'),
  messenger_cda_creation_enabled: attr('boolean'),
  hasMessengerCdaCreationEnabled: alias('messenger_cda_creation_enabled'),
  fin_copilot_enabled: attr('boolean'),
  hasCopilotEnabled: alias('fin_copilot_enabled'),
  stripe_app_installed: attr('boolean'),
  customer_subscription_will_be_cancelled_at: attr('date'),
  customer_has_succeeding_subscription: attr('boolean'),
  customerSubscriptionWillBeCancelledAt: alias('customer_subscription_will_be_cancelled_at'),
  customerHasSucceedingSubscription: alias('customer_has_succeeding_subscription'),
  hasStripeAppInstalled: alias('stripe_app_installed'),

  // Previously the API secret was serialized as an attributes on the app.
  // This is now fetched/set asynchronously through the `fetchAPISecret` task.
  api_secret: undefined,
  api_secrets: undefined,

  primary_user_source: attr(),
  email_sender_domain: attr(),
  default_email_template: attr(),
  default_email_template_id: attr(),
  use_admin_app_email: attr(),
  admin_reply_default_address_id: attr(),
  locale: attr(),
  timezone: attr(),
  authorized_sign_in_methods: attr(),
  identity_verified: attr(),
  identityVerified: alias('identity_verified'),
  has_active_subscription: attr(),
  last_interested_in_user_source: attr(),
  first_identified_request_at: attr(),
  first_anonymous_request_at: attr(),
  koala_api_version: attr(),
  signed_up_with_solution_id: attr(),
  signedUpWithSolutionId: alias('signed_up_with_solution_id'),
  companyCount: alias('company_count'),
  companySize: alias('company_size'),
  developer_workspace: attr(),
  can_use_messenger: attr(),
  canUseMessenger: alias('can_use_messenger'),
  hasActiveSubscription: alias('has_active_subscription'),
  hasNoActiveSubscription: not('hasActiveSubscription'),
  isOnFree: and('canUseFree', 'hasNoActiveSubscription'),
  isNotOnFree: not('isOnFree'),
  hasTrialAvailable: isAny('products', 'trialable'),
  earlyStagePartner: alias('early_stage_partner'),
  valid_early_stage_applicant: attr(),
  valid_early_stage_applicant_until: attr(),
  early_stage_application_solution_id: attr(),
  earlyStageApplicationSolutionId: alias('early_stage_application_solution_id'),
  early_stage_application_accepted: attr(),
  earlyStageApplicationAccepted: readOnly('early_stage_application_accepted'),
  earlyStageApplicationRejected: attr('boolean'),
  hasRelationshipManager: undefined,
  teammate_conversation_attachments_enabled: attr(),
  teammateConversationAttachmentsEnabled: alias('teammate_conversation_attachments_enabled'),
  teammateConversationAttachmentsNotEnabled: not('teammateConversationAttachmentsEnabled'),
  teammate_conversation_gifs_enabled: attr(),
  teammateConversationGifsEnabled: alias('teammate_conversation_gifs_enabled'),
  teammateConversationGifsNotEnabled: not('teammateConversationGifsEnabled'),
  has_opted_in_to_fin: attr(),
  should_deny_access: attr(),
  shouldDenyAccess: alias('should_deny_access'),
  canCreateFinFreeUsageWindow: attr('boolean', { defaultValue: false }),
  usesResolutionsWithCustomAnswers: attr('boolean'),
  hasOptedInToFin: alias('has_opted_in_to_fin'),
  has_consented_to_fin_ingestion: attr(),
  hasConsentedToFinIngestion: alias('has_consented_to_fin_ingestion'),
  has_consented_to_external_ai: attr(),
  hasConsentedToExternalAi: alias('has_consented_to_external_ai'),
  isNotActivated: not('isActivated'),
  activeProducts: filterBy('products', 'active', true),
  subscriptionTypes: hasMany('outbound-subscriptions/subscription-type', {
    defaultValue: () => [],
  }),
  customObjectTypes: hasMany('custom-objects/object-types', {
    defaultValue: () => [],
  }),
  inProductSalesExperience: attr('string'),
  hasTalkToSalesAssistEnabled: equal('inProductSalesExperience', 'sales_assist'),
  hasTalkToSalesEnabled: equal('inProductSalesExperience', 'talk_to_sales'),
  use_stripe_metering: attr('boolean'),
  use_new_billing_experience: attr('boolean'),
  canUseBillingSummaryRedesign: computed(
    'use_new_billing_experience',
    'localStorageFeatures.[]',
    function () {
      return (
        // this will allow us to override the feature flag via localStorage
        this.use_new_billing_experience || this.canUseFeature('team-pnp-billing-summary-redesign')
      );
    },
  ),
  useRealBillingCycle: computed('use_stripe_metering', function () {
    return this.use_stripe_metering ? 'true' : 'false';
  }),

  analyticsData: computed('id', function () {
    return {
      object: 'app',
      app_id: this.id,
    };
  }),

  validEarlyStageApplicantUntil: computed('valid_early_stage_applicant_until', function () {
    let validUntil = moment(this.valid_early_stage_applicant_until);
    if (!validUntil.isValid()) {
      return null;
    }
    return validUntil.toDate();
  }),

  validEarlyStageApplicant: computed(
    'valid_early_stage_applicant',
    'valid_early_stage_applicant_until',
    function () {
      if (this.validEarlyStageApplicantUntil && this.validEarlyStageApplicantUntil < new Date()) {
        return false;
      }
      return this.valid_early_stage_applicant;
    },
  ),

  canUseExternalAi: computed('canUseGlobalAiOptOut', 'hasConsentedToExternalAi', function () {
    return this.canUseGlobalAiOptOut ? this.hasConsentedToExternalAi : true;
  }),

  defaultEmailTemplate: computed('default_email_template_id', function () {
    return DS.PromiseObject.create({
      promise: this.store.findRecord('email-template', this.default_email_template_id),
    });
  }),
  products: hasMany('product', {
    defaultValue: () => [],
  }),

  show5_1PricingModal: computed('pricing5PricingModel', function () {
    return (
      this.pricing5PricingModel === AppPricing5PriceModels.PRICING_5_1 ||
      (this.onPricing5 &&
        (this.pricing5PricingModel === undefined || this.pricing5PricingModel === null))
    );
  }),

  segments: hasMany('segment', { defaultValue: () => [] }),
  segmentsUpdated: computed(function () {
    return this.segments.filter((segment) => {
      return segment.identifier !== 'slipping-away';
    });
  }),

  productsIntercomradesOverride: on(
    'init',
    observer({
      dependentKeys: ['products.[]'],

      fn() {
        if (ENV.environment === 'test') {
          return;
        } // This will break horribly otherwise :(

        let products = this.products;
        if (isBlank(products)) {
          return;
        }

        if (IntercomradesProductsOverride.isEnabled(this.id)) {
          IntercomradesProductsOverride.updateActiveProductsAndPlans(this, products);
        }
      },

      sync: true,
    }),
  ),

  onPricing5_1: equal('pricing5PricingModel', AppPricing5PriceModels.PRICING_5_1),

  webUserSources: filterBy('userSources', 'isMobile', false),
  hasExplicitWebUserSources: notEmpty('webUserSources'),

  hasWebUserSource: or('hasAnyInstalledAtDate', 'hasExplicitWebUserSources'),

  installedAtDate: or('installed_at', 'parent_app_installed_at'),
  hasAnyInstalledAtDate: bool('installedAtDate'),
  isNotImpersonationSession: not('impersonation_session'),
  companiesActive: and('companies_activated', 'companies_enabled'),

  canSeeNewPricingMigration: computed(
    'hasPricingMigrationUIFeature',
    'notOnPricing5',
    'isNotSalesforceContracted',
    'hasActiveSubscription',
    'canQAPricingMigrationPriceBreakdown',
    function () {
      return (
        this.notOnPricing5 &&
        this.isNotSalesforceContracted &&
        this.hasActiveSubscription &&
        (this.hasPricingMigrationUIFeature || this.canQAPricingMigrationPriceBreakdown)
      );
    },
  ),

  canSeePricingMigrationImprovedFlow: or(
    'hasPricingMigrationImprovedFlowFeature',
    'canQAPricingMigrationPriceBreakdown',
  ),

  canQAPricingMigrationPriceBreakdown: and(
    'impersonation_session',
    'canSeePricingMigrationUnderImpersonation',
  ),

  conversationsRoute: ternaryToProperty(
    'currentAdminHasInboxAccess',
    'inboxBaseRoute',
    'feedRoute',
  ),

  conversationRoute: ternaryToProperty(
    'currentAdminHasInboxAccess',
    'inboxConversationRoute',
    'feedConversationRoute',
  ),

  platformGuideRoute: 'apps.app.platform.guide',
  onboardingHomeRoute: 'apps.app.home',

  // use redirectTo query param to determine inbox or platform view
  conversationRedirectRoute: 'apps.app.conversation',

  inboxRoute: 'apps.app.inbox',
  inboxBaseRoute: 'apps.app.inbox.inbox',
  inboxConversationRoute: 'apps.app.inbox.inbox.conversation',
  inboxRedirectConversationRoute: 'apps.app.inbox.inbox.redirect.conversation',
  inboxConversationsRoute: 'apps.app.inbox.inbox.conversations',
  inboxConversationsConversationRoute: 'apps.app.inbox.inbox.conversations.conversation',
  feedRoute: 'apps.app.feed',
  feedConversationRoute: 'apps.app.feed.conversation',

  answersRoute: computed('canUseStandalone', function () {
    if (this.canUseStandalone) {
      return 'apps.app.standalone.knowledge.custom-answers';
    }
    return 'apps.app.automation.fin-ai-agent.custom-answers';
  }),

  unreadCount: computed(
    'inboxIsActive',
    'unread_mention_threads',
    'admin_open_message_thread_count',
    'open_message_thread_count',
    function () {
      let count;

      if (this.inboxIsActive) {
        count = this.unread_mention_threads + this.admin_open_message_thread_count;
      } else {
        count = this.open_message_thread_count;
      }

      return count > 99 ? '99+' : count;
    },
  ),

  userSources: computed(function () {
    return PromiseArray.create({
      promise: this.store.findAll('user-source'),
    });
  }),

  refreshUserSources() {
    return this.store.findAll('user-source').then((userSources) => {
      this.set('userSources', userSources);
      return userSources;
    });
  },

  insertionStats: {
    count: -1,
    rate: -1,
    hasContent: false,
  },

  _queryInsertionStats() {
    return ajax({
      url: '/ember/articles/insertions',
      data: { app_id: this.id },
    }).catch(function () {
      return {
        insertions: -1,
        insertion_rate: -1,
      };
    });
  },

  loadInsertionStats: task(function* () {
    let response = yield this._queryInsertionStats();
    this.set('insertionStats', {
      count: response.insertions,
      rate: response.insertion_rate,
      hasContent: response.has_insertable_content,
    });
  }).drop(),

  appLoadedOnTouchScreenDevice: BROWSER_SUPPORTS_TOUCH,
  appLoadedOnNormalScreenDevice: not('appLoadedOnTouchScreenDevice'),

  defaultAssignee: computed('defaultAssigneeId', function () {
    let assigneeId = this.defaultAssigneeIdIsNotEmpty ? this.defaultAssigneeId : 0;
    return this.admins.findBy('id', assigneeId.toString());
  }),

  defaultAssigneeIdIsNotEmpty: notEmpty('defaultAssigneeId'),
  hasDefaultAssignee: not('defaultAssigneeIsUnassigned'),
  defaultAssigneeIsUnassigned: equal('defaultAssignee.id', '0'),
  peopleCount: alias('people_count'),
  anonymousCount: alias('anonymous_count'),
  userCount: subtractProperties('peopleCount', 'anonymousCount'),
  articleCount: alias('article_count'),
  messagesProduct: findBy('products', 'id', MESSAGES_ID),
  featureIds: mapBy('features', 'id'),
  messagesIsActive: readOnly('canUseMessages'),
  articlesIsActive: readOnly('canUseArticles'),
  inboxIsActive: or('canUseInbox', 'canUseFree'),
  articlesProduct: findBy('products', 'id', ARTICLES_ID),
  inboxProduct: findBy('products', 'id', INBOX_ID),
  customBotsProduct: findBy('products', 'id', OPERATOR_ID),
  answerBotProduct: findBy('products', 'id', ANSWER_BOT_ID),
  productToursProduct: findBy('products', 'id', PRODUCT_TOURS_ID),
  surveysProduct: findBy('products', 'id', SURVEYS_ID),
  smsProduct: findBy('products', 'id', SMS_ID),
  copilotProduct: findBy('products', 'id', FIN_AI_COPILOT_ID),
  articlesIsNotActive: not('articlesProduct.active'),
  inboxIsNotActive: not('inboxIsActive'),
  customBotsIsActive: readOnly('customBotsProduct.active'),
  customBotsIsNotActive: not('customBotsProduct.active'),
  answerBotIsActive: readOnly('answerBotProduct.active'),
  answerBotIsNotActive: not('answerBotIsActive'),
  onlyOneProductIsActive: equal('activeProducts.length', 1),
  onlyArticlesIsActive: and('onlyOneProductIsActive', 'articlesProduct.active'),
  onlyInboxIsActive: and('onlyOneProductIsActive', 'inboxProduct.active'),

  activeProductByPlanId(planId) {
    return this.activeProducts.find((product) => {
      return product.get('plans').findBy('id', planId);
    });
  },

  productWithVbpSupport(productId) {
    if (!this.usesVbp) {
      this.products.findBy('id', productId);
    }

    let product = PRODUCTS[productId];
    product.active = this.hasActiveSubscription;
    product.id = productId;
    product.name = capitalize(product.key);

    return product;
  },

  agentCount: computed(
    'activePricingMetrics.[]',
    'humanAdmins',
    'adminsWithInboxAccess',
    function () {
      if (this.activePricingMetrics.includes('latest_daily_admin_count')) {
        return this.humanAdmins.length;
      } else if (this.activePricingMetrics.includes('inbox_seats')) {
        return this.adminsWithInboxAccess.length;
      }
      return 0;
    },
  ),

  activePricingMetrics: mapBy('activeProducts', 'pricingMetric'), // this computed property isn't accurate for products with > 1 pricing metric
  canSeeCostChangeCard: computed(
    'activePricingMetrics.[]',
    'features.[]',
    'localStorageFeatures.[]',
    function () {
      return this.activePricingMetrics.includes('latest_daily_admin_count');
    },
  ),

  canAccessIntershop: computed('hasIntershopAccess', 'hadIntershopAccessRevoked', function () {
    return this.hasIntershopAccess && !this.hadIntershopAccessRevoked;
  }),

  pricingMetrics: mapBy('products', 'pricingMetric'), // this computed property isn't accurate for products with > 1 pricing metric
  usesVbp: computed('pricingMetrics.[]', 'features.[]', function () {
    return this.pricingMetrics.includes('latest_daily_admin_count');
  }),
  allPlansOnPricingModel: flatMap('products', 'plans'),
  allPricingStrategiesOnPricingModel: flatMap('allPlansOnPricingModel', 'pricingStrategies'),
  allPricingMetricsOnPricingModel: mapBy('allPricingStrategiesOnPricingModel', 'pricingMetric'),
  uniquePricingMetricsOnPricingModel: uniq('allPricingMetricsOnPricingModel'),
  usesPeopleReached: computed('activeProducts.[]', function () {
    return this.activeProducts.any((product) =>
      product.activePlan.pricingStrategies.any(
        (strategy) => strategy.pricingMetric === 'thirty_day_messaged_contacts',
      ),
    );
  }),

  sdkApps: hasMany('sdk-app', { defaultValue: () => [] }),

  iosSdkApps: filterBy('sdkApps', 'isIOS', true),
  iosSdkApiKey: reads('iosSdkApps.firstObject.api_key'),
  iosSdkApiKeyIsActivated: reads('iosSdkApps.firstObject.is_activated'),
  androidSdkApps: filterBy('sdkApps', 'isAndroid', true),
  androidSdkApiKey: reads('androidSdkApps.firstObject.api_key'),
  androidSdkApiKeyIsActivated: reads('androidSdkApps.firstObject.is_activated'),
  hasIOSSdk: notEmpty('iosSdkApps'),
  hasAndroidSdk: notEmpty('androidSdkApps'),
  onlyHasAndroidSdk: isEvery('sdkApps', 'isAndroid'),
  hasMobileIntegration: readOnly('has_mobile_integration'),
  doesNotHaveMobileIntegration: not('hasMobileIntegration'),
  hasActiveGithubIntegration: alias('has_active_github_integration'),
  hasActiveJiraIntegration: alias('has_active_jira_integration'),
  hasEnabledGithubIntegration: alias('has_enabled_github_integration'),
  hasSalesforceIntegration: readOnly('has_salesforce_integration'),
  hasSlackNotificationsIntegration: readOnly('has_slack_notifications_integration'),
  hasMarketoEnrichmentIntegration: readOnly('has_marketo_enrichment_integration'),
  hasHubSpotIntegration: readOnly('has_hubspot_integration'),
  hasPipedriveIntegration: readOnly('has_pipedrive_integration'),
  hasActiveWhatsAppIntegration: readOnly('has_active_whatsapp_integration'),
  canUseCalling: alias('canUseChannelsVideoCall'),
  canMatchUserByPhoneNumber: alias('canFetchUserByPhoneNumber'),

  hasSingleDeliveryChannel: computed('hasIOSSdk', 'hasAndroidSdk', 'hasWebUserSource', function () {
    let numberOfIntegrations = 0;
    numberOfIntegrations += this.hasWebUserSource ? 1 : 0;
    numberOfIntegrations += this.hasIOSSdk ? 1 : 0;
    numberOfIntegrations += this.hasAndroidSdk ? 1 : 0;
    return numberOfIntegrations === 1;
  }),

  productIsAvailable(productId) {
    // This indicates if the workspace has access to a product.
    // Prior to general release some workspaces will have access while others will not.
    return this.products.mapBy('id').includes(productId);
  },

  coreP5Product: computed('products', function () {
    return this.products.find((product) => {
      return PRICING_5_X_CORE_ID === product.id;
    });
  }),

  coreProduct: computed('products', function () {
    return this.products.find((product) => {
      return [CORE_ID, CORE_STARTER_ID, PRICING_5_X_CORE_ID].includes(product.id);
    });
  }),

  hasValueBasedPricing: computed('products', function () {
    return this.products.find((product) => {
      return [CORE_ID, CORE_STARTER_ID].includes(product.id);
    });
  }),

  valueBasedPricing1Product: computed('products', function () {
    return this.products.find((product) => {
      return CORE_ID === product.id;
    });
  }),

  hasValueBasedPricing1: bool('valueBasedPricing1Product'),

  valueBasedPricing2Product: computed('products', function () {
    return this.products.find((product) => {
      return [CORE_STARTER_ID, CORE_SUPPORT_ID, CORE_ENGAGE_ID, CORE_CONVERT_ID].includes(
        product.id,
      );
    });
  }),

  isOnValueBasedPricing: or(
    'hasValueBasedPricing',
    'hasValueBasedPricing1',
    'hasValueBasedPricing2',
  ),

  hasValueBasedPricing2: bool('valueBasedPricing2Product'),

  meteredMessagesArticleId: computed(function () {
    if (this.pricing5PricingModel === AppPricing5PriceModels.PRICING_5_1) {
      return METERED_MESSAGES_ARTICLE_ID_5_1;
    } else {
      return METERED_MESSAGES_ARTICLE_ID_5_0;
    }
  }),

  proactiveSupportProArticleId: computed(function () {
    if (this.pricing5PricingModel === AppPricing5PriceModels.PRICING_5_1) {
      return PROACTIVE_SUPPORT_PRO_ARTICLE_ID_5_1;
    } else {
      return PROACTIVE_SUPPORT_PRO_ARTICLE_ID_5_0;
    }
  }),

  pricingPageUrl: computed('products', 'hasValueBasedPricing2', function () {
    if (this.pricing5PricingModel === AppPricing5PriceModels.PRICING_5_0) {
      return PRICING_PAGE_FOR_P_5_0;
    } else if (this.hasValueBasedPricing2) {
      return PRICING_PAGE_FOR_VBP_2;
    } else if (this.products.any((product) => product.id === CORE_ID)) {
      return PRICING_PAGE_FOR_VBP_1_2; // VBP 1.2
    } else if (this.canUsePerProductPricingFlow) {
      return PRICING_PAGE_FOR_PPP; // Per Product Pricing
    } else {
      return PRICING_PAGE; // General pricing
    }
  }),

  attributes: hasMany('attribute'),
  attributesWithoutRelationship: rejectBy('attributes', 'isRelationshipDataType', true),

  allowedAttributes: computed('attributesWithoutRelationship.[]', function () {
    if (this.companiesActive) {
      return this.attributesWithoutRelationship;
    }
    return this.attributesWithoutRelationship.rejectBy('isCompany', true);
  }),
  attribute_settings: hasMany('display-attribute-setting', {
    defaultValue: () => [],
  }),
  userAttributeSettings: filterBy('attribute_settings', 'display_on', 'users'),
  companyAttributeSettings: filterBy('attribute_settings', 'display_on', 'companies'),
  tags: hasMany('tag', { defaultValue: () => [] }),

  browser_locales: fragmentArray('browser_locale'),

  plans: hasMany('external-plan', {
    defaultValue: () => [],
  }),

  selfServeCorePlans: computed('allPlansOnPricingModel', function () {
    return this.allPlansOnPricingModel.filter((plan) => {
      return plan.selfServe && !plan.product.addon;
    });
  }),

  qualification_attributes: hasMany('people/qualification-attribute', {
    defaultValue: () => [],
  }),

  accounts: computed(function () {
    return this.store.peekAll('account');
  }),

  isIntercom: computed(function () {
    return this.id === this.regionService.intercomAppCode;
  }),

  features: hasMany('feature', {
    defaultValue: () => [],
  }),

  admins: hasMany('admin'),
  teams: computed('admins.[]', function () {
    let teams = this.admins.filterBy('is_team').filterBy('isNew', false);
    let team_json = teams.map((t) => t.toJSON({ includeId: true }));
    this.store.pushPayload({ team: team_json });
    return team_json.map((team) => {
      return this.store.peekRecord('team', team.id);
    });
  }),
  teamsWithoutAppTeam: filterBy('teams', 'isAppTeam', false),

  assignableTeams: readOnly('teams'),

  addTeam(team) {
    this.store.pushPayload({ admin: [team.toJSON({ includeId: true })] });
    let admin = this.store.peekRecord('admin', team.id);
    this.admins.pushObject(admin);
  },

  removeTeam(team) {
    let admin = this.admins.findBy('id', team.get('id'));
    this.admins.removeObject(admin);
  },

  appTeam: findBy('teams', 'isAppTeam', true),
  adminsWithBillingPermissions: filterBy('humanAdmins', 'canAccessBillingSettings', true),
  adminsWithBillingOrWorkspacePermissions: computed('humanAdmins', function () {
    return this.humanAdmins.filter((admin) => {
      return admin.canAccessBillingSettings || admin.canAccessWorkSpaceSettings;
    });
  }),
  adminsWhoCanInstallApps: filterBy('humanAdmins', 'canManageAppsAndIntegrations', true),
  adminsWhoCanManageTeammates: filterBy('humanAdmins', 'canCanManageTeammates', true),

  requiresInboxSeatAccess: readOnly('canUsePerProductPricingFlow'),

  adminsWithInboxAccess: filterBy('humanAdmins', 'hasInboxAccess', true),
  adminsWithoutInboxAccess: filterBy('humanAdmins', 'hasInboxAccess', false),
  adminsWithCopilotAccess: filterBy('humanAdmins', 'canInboxAccessCopilot', true),
  adminsWithLimitedCopilotAccess: filterBy('humanAdmins', 'hasLimitedCopilotAccess', true),
  adminsWithUnlimitedCopilotAccess: filterBy('humanAdmins', 'hasUnlimitedCopilotAccess', true),

  usersTitle: 'People',
  importedUsersAndIsNotActivated: and('primary_user_source_is_import_type', 'isNotActivated'),

  application_colors: computed('base_color', function () {
    return new AppColor().generate_message_box_colors(this.base_color);
  }),

  currentAdmin: computed('admins.[]', function () {
    // This compact is needed to get around a nasty problem caused by the
    // observers in `base-visibility-helper`. When the store is being destroyed this recomputes
    // with undefined values in the admins array which causes null pointer exception here
    return this.admins.compact().findBy('is_me');
  }),

  currentAdminHasInboxAccess: computed(
    'requiresInboxSeatAccess',
    'inboxIsNotActive',
    'currentAdmin.hasInboxAccess',
    function () {
      if (this.inboxIsNotActive) {
        return false;
      }

      if (this.requiresInboxSeatAccess) {
        return this.get('currentAdmin.hasInboxAccess');
      }

      return true;
    },
  ),

  doesNotRequiresInboxSeatAccess: not('requiresInboxSeatAccess'),
  currentAdminCanViewInbox: or('currentAdminHasInboxAccess', 'doesNotRequiresInboxSeatAccess'),
  customEmailAddresses: computed(function () {
    return PromiseArray.create({
      promise: getEmberDataStore().findAll('custom-email-address'),
    });
  }),

  verifiedCustomEmailAddresses: filterBy('customEmailAddresses', 'verified', true),
  hasAdminReplyDefaultAddress: notEmpty('admin_reply_default_address_id'),
  emailSenderAddressCustomized: or('use_admin_app_email', 'hasAdminReplyDefaultAddress'),

  updateDefaultEmailSenderSetting(isEnablingAdminAppEmail, adminReplyDefaultAddressId) {
    return ajax({
      url: `/ember/email_sender_settings`,
      type: 'PUT',
      data: JSON.stringify({
        app_id: this.id,
        use_admin_app_email: isEnablingAdminAppEmail,
        admin_reply_default_address_id: adminReplyDefaultAddressId,
      }),
    }).then(
      function () {
        this.set('use_admin_app_email', isEnablingAdminAppEmail);
        this.set('admin_reply_default_address_id', adminReplyDefaultAddressId);
      }.bind(this),
    );
  },

  async updateDefaultNotificationEmailAddress(defaultNotificationsAddressId) {
    return await ajax({
      url: `/ember/email_default_notifications_settings`,
      type: 'PUT',
      data: JSON.stringify({
        app_id: this.id,
        default_notifications_address_id: defaultNotificationsAddressId,
      }),
    });
  },

  dkim_settings: computed(function () {
    return PromiseArray.create({
      promise: getEmberDataStore().findAll('dkim-settings'),
    });
  }),

  adminEmailDKIMSettings: filterBy('dkim_settings', 'isUsedInAdminEmails', true),
  customSenderDKIMSettings: filterBy('dkim_settings', 'isUsedInCustomEmails', true),

  email_templates: computed(function () {
    let store = getEmberDataStore();
    let promise = this.translations.then((translations) => {
      EmailTemplate.populateDefaultTemplates(this, translations);
      let templates = store.peekAll('email-template');
      return store.findAll('email-template').then((items) => {
        templates.toArray().pushObjects(items.toArray());
        return templates;
      });
    });
    return PromiseArray.create({ promise });
  }),

  email_template_data: computed(function () {
    return PromiseArray.create({
      promise: getEmberDataStore().findAll('email-template-data'),
    });
  }),

  emailTemplates: computed(function () {
    return PromiseArray.create({
      promise: getEmberDataStore().findAll('email-template'),
    });
  }),

  translations: computed(function () {
    return Translations.fetch(this.id).then((translations) => {
      return translations.get('firstObject');
    });
  }),

  savedReplies: computed(function () {
    return PromiseArray.create({
      promise: getEmberDataStore().findAll('saved-reply'),
    });
  }),

  refreshSavedReplies() {
    let savedReplies = PromiseArray.create({
      promise: getEmberDataStore().findAll('saved-reply'),
    });
    this.set('savedReplies', savedReplies);
  },

  isCollaboratorView: computed('currentAdmin.seats.[]', function () {
    return (
      this.currentAdmin.seats &&
      this.currentAdmin.seats.length === 1 &&
      this.currentAdmin.seats[0] === COLLABORATOR_SEAT_TYPE
    );
  }),

  shouldHaveLightInbox: computed('isCollaboratorView', 'currentAdmin.seats.[]', function () {
    let hasPricing5LiteSeat = (this.currentAdmin.seats || []).includes(PRICING_5_X_LITE_SEAT_TYPE);

    return this.isCollaboratorView || hasPricing5LiteSeat;
  }),

  visibleSavedReplies: computed(
    'savedReplies.content',
    'teams',
    'canSetMacroVisibility',
    function () {
      if (!this.canSetMacroVisibility) {
        return this.savedReplies;
      }
      let currentAdminId = parseInt(this.currentAdmin.id, 10);
      let teamIds = this.teams
        .filter(function (team) {
          return team.get('member_ids').includes(currentAdminId);
        })
        .map((team) => team.id);
      return this.savedReplies.filter((reply) => {
        return (
          reply.visibleToTeamIds === null ||
          !reply.visibleToTeamIds.length ||
          reply.visibleToTeamIds.some((id) => teamIds.includes(id))
        );
      });
    },
  ),

  savedContents: computed(function () {
    return PromiseArray.create({
      promise: getEmberDataStore()
        .findAll('saved-reply')
        .then((replies) => {
          return replies.filter((reply) => reply.types.includes('opener'));
        }),
    });
  }),

  savedReplyTypes: computed(function () {
    return PromiseArray.create({
      promise: getEmberDataStore().findAll('saved-reply-type'),
    });
  }),

  companyEmailAddresses: computed(function () {
    return PromiseArray.create({
      promise: getEmberDataStore().findAll('company-email-address'),
    });
  }),

  refreshCompanyEmailAddresses() {
    this.companyEmailAddresses = PromiseArray.create({
      promise: getEmberDataStore().query('company-email-address', {}, { reload: true }),
    });
  },

  isEnglishLocale: equal('locale', 'en'),

  formattedTimezone: computed('timezone', function () {
    return formattedTimezone(this.timezone);
  }),

  formattedUtcOffset: computed('timezone', function () {
    return moment
      .tz(this.timezone)
      .format('[GMT]Z')
      .replace(/0(\d:)/, '$1')
      .replace(':00', ''); //Should look like GMT+1 or GMT+5:30
  }),

  humanReadableTimezone: computed('formattedTimezone', 'formattedUtcOffset', function () {
    return `${this.formattedTimezone} time (${this.formattedUtcOffset})`;
  }),

  hasNoPeople: equal('peopleCount', 0),
  humanAdmins: computed('admins.@each.isHuman', function () {
    let admins = this.admins.filterBy('isHuman');
    // Standalone dummy admins are 'human' but we don't want to include them in the lists of admins we can assign to etc
    return admins.filter((admin) => !admin.isStandaloneDummyAdmin);
  }),
  humanAdminsWithCoreSeat: filterBy('humanAdmins', 'hasPricing5CoreSeat', true),
  humanAdminsWithCopilotSeat: filterBy('humanAdmins', 'hasCopilotSeat', true),
  humanAdminsWithLiteSeat: filterBy('humanAdmins', 'hasPricing5LiteSeat', true),
  operatorBot: findBy('admins', 'isOperator', true),
  unassignedAdmin: findBy('admins', 'isUnassignedAdmin', true),

  assignableAdmins: computed(
    'admins.@each.{isAssignableUnderThePerSeatPricing,isBot,seats}',
    'requiresInboxSeatAccess',
    'inboxIsActive',
    function () {
      let nonBotAdmins = this.admins.filterBy('isBot', false).rejectBy('id', OWNER_ID);
      if (this.hasMultipleSeatTypes) {
        return nonBotAdmins.filterBy('multipleSeatTypesAssignableAdmins');
      } else if (this.requiresInboxSeatAccess && this.inboxIsActive) {
        return nonBotAdmins.filterBy('isAssignableUnderThePerSeatPricing');
      }
      return nonBotAdmins;
    },
  ),

  assignableCreators: computed('humanAdmins', function () {
    if (this.hasMultipleSeatTypes) {
      return this.humanAdmins.filterBy('multipleSeatTypesAssignableAdmins');
    }
    return this.humanAdmins;
  }),

  assignableAdminsAndOperator: computed('assignableAdmins.[]', 'operatorBot', function () {
    return [].concat(this.assignableAdmins, [this.operatorBot]);
  }),

  assignableAdminsWithoutUnassigned: filterBy('assignableAdmins', 'isUnassignedAdmin', false),

  owner: computed(function () {
    let admin = this.store.createRecord('admin');
    admin.setProperties({
      id: OWNER_ID,
      name: 'Owner',
    });
    return admin;
  }),

  assignableAdminsIncludingOwner: computed('assignableAdmins', function () {
    let admins = this.assignableAdmins;
    admins.unshift(this.owner);
    return admins;
  }),

  assignableHumanAdmins: filterBy('assignableAdmins', 'isHuman'),
  userSegments: filterBy('segmentsUpdated', 'isUser'),
  editableUserSegments: filterBy('userSegments', 'is_editable'),
  companySegments: filterBy('segmentsUpdated', 'isCompany'),
  editableCompanySegments: filterBy('companySegments', 'is_editable'),
  verifiedLoggedInInstallAt: null,
  verifiedLoggedOutInstallAt: null,

  forceEmailVerification: not('currentAdmin.email_verified'),

  insertableContent: [],

  get canDisplayExpiredSubscriptionPage() {
    /**
     * To speed up sign-up, subscription creation now runs asynchronously. When the app loads, the expired subscription page is shown
     * as if the subscription is inactive. This logic prevents showing the expired subscription page for newly created apps (within the last 2 minutes),
     * ensuring enough time for the subscription to be set up before enforcing paywalls.
     */
    if (this.created_at && moment().diff(moment(this.created_at), 'minutes') <= 2) {
      return false;
    }
    return !!(
      this.envAllowsExpiredSubscriptionPage &&
      this.canUseExpiredSubscriptionPage &&
      (!this.hasActiveSubscription || this.isFrozenForNonPayment)
    );
  },

  // By default the expired subscription page is disabled in development environment - to enable it enable the feature flag team-purchase-experience-expired-subscription-page-enabled-for-dev
  get envAllowsExpiredSubscriptionPage() {
    return !!(ENV.environment !== 'development' || this.isExpiredSubscriptionPageEnabledForDev);
  },

  get canAccessAiAssistBeta() {
    return true;
  },

  get finBlockedInTestApp() {
    return this.isTestApp && !this.canUseFeature('fin-in-test-apps');
  },

  finPricingLearnMoreUrl: computed('usesResolutionsWithCustomAnswers', function () {
    if (this.usesResolutionsWithCustomAnswers) {
      return 'https://www.intercom.com/help/en/articles/8205718-fin-resolutions-and-custom-answers';
    } else {
      return 'https://www.intercom.com/help/en/articles/7837512-fin-pricing';
    }
  }),

  get canUseCopilotOnStandalone() {
    return (
      this.canUseStandalone &&
      this.hasStandalonePlatform('zendesk') &&
      this.canUseCopilotSeatProvisioningOnStandalone
    );
  },

  async loadInsertableContent() {
    try {
      let content = await ajax({
        url: `/ember/content_service/contents/insertable_content?app_id=${this.id}&multi_help_center_aware=true`,
        type: 'GET',
        data: {},
      });
      this.set('insertableContent', content);
    } catch (error) {
      this.set('insertableContent', null);
    }
  },

  async loadAndUpdateTags() {
    // Query ensures that deleted tags are not included. findAll does not purge objects deleted remotely.
    let tags = await this.store.query('tag', {});
    let existingTagIds = this.tags.map((tag) => tag.id);
    let newTags = tags.filter((tag) => existingTagIds.indexOf(tag.id) === -1);

    let newTagIds = tags.map((tag) => tag.id);
    let deletedTags = this.tags.filter((tag) => newTagIds.indexOf(tag.id) === -1);

    this.tags.removeObjects(deletedTags);
    this.tags.pushObjects(newTags);

    this.updateLocalCache(this.modelDataCacheService);

    return tags;
  },

  loadEmailDomains() {
    return new Promise(async (resolve, reject) => {
      let response = await this._fetchEmailDomains();
      if (response) {
        this._peekAndUnloadFromStore(
          response.dkim_settings,
          this.store.peekAll('dkim-settings'),
          'domain',
        );
        this.store.pushPayload({
          'dkim-settings': response.dkim_settings,
        });
        if (
          response.custom_bounce_settings &&
          !isEmpty(Object.keys(response.custom_bounce_settings))
        ) {
          this._peekAndUnloadFromStore(
            response.custom_bounce_settings,
            this.store.peekAll('custom-bounce-settings'),
            'rootDomain',
          );
          this.store.pushPayload({
            'custom-bounce-settings': response.custom_bounce_settings,
          });
        }
        this.set('dkim_settings', this.store.peekAll('dkim-settings'));
        this.set('customBounceSettings', this.store.peekAll('custom-bounce-settings'));
        resolve();
      } else {
        reject();
      }
    });
  },

  areCustomBounceSettingsValidForEmail(email) {
    if (!this.customBounceSettings) {
      return false;
    }

    let customBounceSettings = this.customBounceSettings.find((setting) =>
      email.toLowerCase().endsWith(`@${setting.rootDomain}`),
    );

    return isPresent(customBounceSettings) && customBounceSettings.validRecordExists;
  },

  isDmarcValidForEmail(email) {
    if (!this.dkim_settings) {
      return false;
    }

    let dkimSettings = this.dkim_settings.find((setting) =>
      email.toLowerCase().endsWith(`@${setting.domain}`),
    );

    return isPresent(dkimSettings) && dkimSettings.validDmarcExists;
  },

  _fetchEmailDomains() {
    let app_id = this.id;
    return this._ajaxGet({
      url: `/ember/dkim_settings`,
      data: { app_id },
    });
  },

  _peekAndUnloadFromStore(response_array, store_array, key) {
    // Removing domains from store explicitly to prevent stale UI response in settings/domains page
    if (!response_array || !store_array || !key || response_array.length === store_array.length) {
      return;
    }
    response_array = [].concat(response_array);
    let domains_list = response_array.map((item) => item[key]);
    store_array.forEach((item) => {
      if (!domains_list.includes(item[key])) {
        this.store.unloadRecord(item);
      }
    });
  },

  refreshNavCounters() {
    throttle(this, this._fetchCounters, ENV.APP._5000MS);
  },

  _fetchCounters() {
    let options = {
      url: `/ember/apps/${this.id}/counters.json`,
      type: 'GET',
    };

    return ajax(options).then((response) => this.setProperties(response));
  },

  fetchAndUpdateAllAdminPermissions() {
    return this.store.findAll('permission', { reload: true }).then((permissions) => {
      this.admins.forEach((admin) => {
        admin.permissions.clear();
        let newPermissions = permissions.filterBy('admin_id', admin.id);
        admin.permissions.pushObjects(newPermissions);
      });
    });
  },

  fetchAPISecret: task(function* () {
    try {
      let app_id = this.id;
      let keys = yield this._ajaxGet({
        url: `/ember/apps/${app_id}/api_keys.json`,
        data: { app_id },
      });
      this.set('api_secret', keys.api_secret);
      this.set('api_secrets', keys.api_secrets);
    } catch (error) {
      this.set('api_secret', null);
      this.set('api_secrets', null);
    }
  }),

  updateLocalCache(modelDataCacheService) {
    if (modelDataCacheService.hasCachedItem('app', this.id)) {
      modelDataCacheService.updateCachedItem('app', this.id, this.serialize());
    }
  },

  _ajaxGet(options) {
    return ajax(Object.assign(options, { type: 'GET' }));
  },
}).reopenClass({
  fetchJSON(appId) {
    let allowlist1 = [
      'browser_locales',
      'android_os_versions',
      'android_devices',
      'android_app_versions',
      'ios_os_versions',
      'ios_app_versions',
      'plans',
      'subscription_types',
    ].join(',');
    let allowlist2 = ['admins'].join(',');
    let allowlist3 = [
      'segments',
      'attributes',
      'tags',
      'user_sources',
      'content_events_config',
      'custom_object_types',
    ].join(',');

    let promises = [
      App.getWithRedirectOn403(
        `/ember/apps/${appId}.json?attribute_denylist=${allowlist1},${allowlist2},${allowlist3}`,
      ),
      App.getWithRedirectOn403(`/ember/apps/${appId}.json?attribute_allowlist=${allowlist1}`),
      App.getWithRedirectOn403(`/ember/apps/${appId}.json?attribute_allowlist=${allowlist2}`),
      App.getWithRedirectOn403(`/ember/apps/${appId}.json?attribute_allowlist=${allowlist3}`),
    ];
    return all(promises).then((results) => Object.assign(...results));
  },
  getWithRedirectOn403(url) {
    return ajax({
      url,
      type: 'GET',
      redirectOn403: true,
    });
  },
  loadJSON(data) {
    let frontendStatsService = containerLookup('service:frontendStatsService');
    let store = getEmberDataStore();

    frontendStatsService.startInteractionTime('model:app.loadJSON');
    store.pushPayload({ app: [data] });
    frontendStatsService.stopInteractionTime('model:app.loadJSON');

    return store.findRecord('app', data.id, { backgroundReload: false });
  },
});

export default App;
