/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-new-mixins */
import { computed } from '@ember/object';
import { and, not, or, readOnly, reads } from '@ember/object/computed';
import Mixin from '@ember/object/mixin';
import { ARTICLES_FEATURE, INBOX_FEATURE, MESSAGES_FEATURE } from 'embercom/lib/billing';
import IntercomradesProductsOverride from 'embercom/lib/intercomrades-products-override';
import localFeatureFlags from 'embercom/lib/local-feature-flags';

export const nameForTestFeatureEnabledByGlobalFeatureFlagDefaults =
  '_test-ff-enabled-by-global-defaults';
export const nameForTestFeatureDisabledByGlobalFeatureFlagDefaults =
  '_test-ff-disabled-by-global-defaults';

const generateFeatureFlagConfig = ({ flattenComputed } = { flattenComputed: false }) => {
  let ComputedCanUseFeature;
  if (flattenComputed) {
    ComputedCanUseFeature = (featureKey) => featureKey;
  } else {
    ComputedCanUseFeature = function (featureKey) {
      return computed('features.[]', 'localStorageFeatures.[]', function () {
        return this.canUseFeature(featureKey);
      });
    };
  }
  return {
    //team-data-foundations
    canBulkExport: ComputedCanUseFeature('bulk-export-conversation-data'),
    canLogSamlEventData: ComputedCanUseFeature('workspace-management-log-saml-event-data'),
    canUseSamlSso: ComputedCanUseFeature('saml_sso'),
    canUseSamlMultiWorkspaces: ComputedCanUseFeature('rails-dev-platform-saml-multi-workspaces'),
    canManageTeamsGranularly: ComputedCanUseFeature('manage-teams-permission'),
    canIncludeAttachmentsInDataExport: ComputedCanUseFeature('data-export-attachments'),

    //team-actions
    canUseGithubWorkflowsAlpha: ComputedCanUseFeature('github-workflows-alpha'),
    canUseJiraInboundWorkflows: ComputedCanUseFeature('inbound-jira-webhooks'),
    useAppCardApi: ComputedCanUseFeature('marketplace-app-card-api'),
    showAppsInBotsPaywall: ComputedCanUseFeature('marketplace-validate-apps-in-operator'),
    isDevHubAppPartnerEnabled: ComputedCanUseFeature('app-ecosystem-dev-hub-app-partner'),
    isExistingAppPartner: ComputedCanUseFeature(
      'app-ecosystem-dev-hub-app-partner-existing-partner',
    ),
    notExistingAppPartner: not('isExistingAppPartner'),
    canShowDevHubAppPartner: and('isDevHubAppPartnerEnabled', 'notExistingAppPartner'),
    canUseWorkflowConnectorActionMacros: ComputedCanUseFeature('ae-wfc-macros'),
    canUseNewWorkflowsJiraInstallation: ComputedCanUseFeature(
      'team-data-interop-new-workflows-jira-installation-flow',
    ),
    canUseOauthTokenForCustomActions: ComputedCanUseFeature(
      'team-actions-oauth-token-for-custom-actions',
    ),
    canUseUserTokenForCustomActions: ComputedCanUseFeature(
      'team-actions-user-token-for-custom-actions',
    ),
    canUseConsolidatedActions: ComputedCanUseFeature('fin-processes-actions-consolidation'),
    canUseFinWorkflowsStepsInAllWorkflows: ComputedCanUseFeature(
      'fin-workflows-steps-in-all-workflows',
    ),
    canUseFinWorkflowPreview: ComputedCanUseFeature('team-actions-fin-workflow-preview'),
    canUseFinTaskSimpleMode: ComputedCanUseFeature('team-actions-fin-task-simple-mode'),
    canUseWorkflowsWaitForCallback: ComputedCanUseFeature('team-actions-wait-for-webhook'),
    canUseAgenticWorkflows: ComputedCanUseFeature('team-actions-agentic-workflows'),

    //team-phone
    canUseChannelsVideoCall: ComputedCanUseFeature('channels-video-call'),
    canUseChannelsPhoneCall: ComputedCanUseFeature('channels-phone-call'),
    canUseInboundPhoneCall: ComputedCanUseFeature('inbound-phone-call'),
    canUsePhoneWorkflowTrigger: ComputedCanUseFeature('phone-workflow-trigger'),
    isCallingKillswitchEnabled: ComputedCanUseFeature('ae-calling-killswitch'),
    canFetchUserByPhoneNumber: ComputedCanUseFeature('ae-fetch-user-by-phone-number'),
    canUseDialpad: ComputedCanUseFeature('phone-dev-dialpad'),
    canUsePhoneCsat: ComputedCanUseFeature('phone-csat'),
    canUseAutomaticPhonePorting: ComputedCanUseFeature('automatic-phone-porting'),
    callingDropdownKillswitch: ComputedCanUseFeature('calling-dropdown-killswitch'),
    canManualAnswer: ComputedCanUseFeature('calling-manual-answer'),

    //team-data-foundations
    canUseHistoricalBulkSync: ComputedCanUseFeature('crm-salesforce-bulk-sync'),
    canSeeSyncErrorsNotificationIndicator: ComputedCanUseFeature('crm-new-sync-errors-indicator'),
    canOptInToSalesforceBeta: ComputedCanUseFeature('dev-platform-salesforce-opt-in-flow'),
    canUseCustomObjectsRequiredAttributes: ComputedCanUseFeature(
      'cdp-custom-objects-required-attributes',
    ),
    canMigrateFromZendesk: ComputedCanUseFeature('cdp-migrate-from-zendesk'),
    canImportFromZendeskNewUi: ComputedCanUseFeature('cdp-import-data-from-zendesk-new-ui'),
    canBypassIdVForFinPersistedDataDemo: ComputedCanUseFeature(
      'answerbot-bypass-idv-for-fin-persisted-data-demo',
    ),

    canUseActionsInputMapping: ComputedCanUseFeature(
      'answerbot-fin-actions-input-parameter-mapping',
    ),
    canListJiraSitesOnJiraIntegrationSettings: ComputedCanUseFeature(
      'team-actions-jira-integration-list-sites',
    ),
    canUseOTPAuthentication: ComputedCanUseFeature('team-actions-otp-authentication'),
    canUseFinTaskV3: ComputedCanUseFeature('answerbot-fin-task-v3'),
    canUseFinTaskAITaskGeneration: ComputedCanUseFeature(
      'team-actions-fin-task-ai-task-generation',
    ),

    // actions-1-team
    canUseAiAgentWorkflows: ComputedCanUseFeature('answerbot-fin-actions-workflow-write-actions'),

    // team-conversational-knowledge
    canUseAiInsightsSuggestionsUi: ComputedCanUseFeature('ai-insights-suggestions-ui'),
    canUseExtractionExternalSources: ComputedCanUseFeature(
      'conversation-extraction-external-sources',
    ),
    // knowledge and data group features
    canUseDedicatedIPs: ComputedCanUseFeature('engage-channels-dedicated-ips'),
    canBumpCSVImportFileSizeLimit: ComputedCanUseFeature(
      'engage-systems-fixed-list-messaging-bump-filesize-limit',
    ),
    canOverrideMobileSmartLinkCondition: ComputedCanUseFeature(
      'group-engage-systems-smart-links-opt-in',
    ),
    canDisableInboundConversations: ComputedCanUseFeature('disable_inbound_conversations'),
    hasSavedContentFeature: ComputedCanUseFeature('macros_with_actions_billing_feature'),
    hasVersioningBillingFeature: ComputedCanUseFeature('ruleset_versioning'),
    canUseSenderRoleFilter: ComputedCanUseFeature('orchestration-sender-role-filter'),
    useCustomerTwilioAccount: ComputedCanUseFeature('orchestration-sms-customer-twilio-account'),
    canUseCustomKeywordResponses: ComputedCanUseFeature(
      'orchestration-sms-custom-keyword-responses',
    ),
    canUseSmsBeta: ComputedCanUseFeature('messages-sms-beta'),
    canUseDelayedAssigneeDropdown: ComputedCanUseFeature(
      'engage-systems-delayed-assignee-dropdown',
    ),
    canUseAlphanumericSenderId: ComputedCanUseFeature('engage-systems-sms-alphanumeric-sender-id'),
    canUseSeriesEmailUnsubscribeLinks: ComputedCanUseFeature(
      'engage-systems-series-email-unsubscribe-links',
    ),
    canUseSeriesMetaData: ComputedCanUseFeature('engage-systems-series-metadata'),
    ccIntercomPages: ComputedCanUseFeature('team-cc-pages'),
    // Context: https://github.com/intercom/intercom/issues/134467
    canUseManualAccountCreation: ComputedCanUseFeature('team-cc-manual-account-creation'),
    canUseMessagePointers: ComputedCanUseFeature('team-oe-message-pointers'),
    checklistRemindersQA: ComputedCanUseFeature('proactive-support-checklists-reminders-qa'),
    canUseArticleContentEvents: ComputedCanUseFeature('engage-channels-1-article-content-events'),
    canUseReducedMatchSpacingPeriod: ComputedCanUseFeature(
      'proactive-support-reduced-match-spacing-period',
    ),
    canUseApifyAgressivePruning: ComputedCanUseFeature(
      'psg-external-content-apify-aggressive-pruning',
    ),
    canUseExternalContentReadibility: ComputedCanUseFeature(
      'can-use-readability-filter-for-extenal-content',
    ),
    canUseDeletedSurveyResponseStats: ComputedCanUseFeature('psg-deleted-survey-response-stats'),

    canUsePostsPreview: ComputedCanUseFeature('group-ps-posts-preview'),

    canUseSurveysResponseOrdering: ComputedCanUseFeature('psg-surveys-response-ordering'),
    shouldBypassTlsCutoffDate: ComputedCanUseFeature('tls-bypass-cutoff-date'),
    disableAutoSave: ComputedCanUseFeature('articles-disable-autosave'),
    canUseArticleReviews: ComputedCanUseFeature('article_reviews'),
    canUseDraftArticlePermission: ComputedCanUseFeature('articles-edit-draft-articles-permission'),
    canUseArticlesAi: ComputedCanUseFeature('articles-ai'),
    canUseChecklistsMultiHcInserters: ComputedCanUseFeature(
      'psg-multi-help-center-checklists-inserters',
    ),
    canModifyHelpCenterRedirects: ComputedCanUseFeature('psg-can-modify-help-center-redirects'),
    showMultiHelpCenterPaywalling: ComputedCanUseFeature('psg-multi-hc-show-paywalling'),
    canUseLargerEmailPanelInSeries: ComputedCanUseFeature('psg-larger-email-panel-series'),
    canUseInboxCopilot: ComputedCanUseFeature('answerbot-inbox-copilot'),
    canUseFinPlayground: ComputedCanUseFeature('answerbot-fin-playground'),
    canUseFinPlaygroundColdStart: ComputedCanUseFeature('fin-playground-cold-start'),
    canUseFinPlaygroundRedesign: ComputedCanUseFeature('fin-playground-redesign'),
    canUseFinVoicePlayground: computed(
      'features.[]',
      'localStorageFeatures.[]',
      'currentAdmin.id',
      function () {
        return (
          this.canUseFeature('answerbot-fin-voice-playground') ||
          this.canUseFeature(`answerbot-fin-voice-playground-for-${this.currentAdmin.id}`)
        );
      },
    ),
    canUseFinVoicePlaygroundDev: ComputedCanUseFeature('answerbot-fin-voice-playground-dev'),
    canUseFinVoicePlaygroundForAllAdmins: ComputedCanUseFeature('answerbot-fin-voice-playground'),
    canSetupFinVoicePhoneEasySetup: computed(
      'features.[]',
      'localStorageFeatures.[]',
      'canUseFinVoicePlayground',
      function () {
        return this.canUseFinVoicePlayground && this.canUseFeature('phone-fin-voice-easy-setup');
      },
    ),
    canUseFinPlaygroundBrandTargeting: ComputedCanUseFeature('fin-playground-brand-targeting'),
    canUseFinPlaygroundUserTargeting: ComputedCanUseFeature('fin-playground-user-targeting'),
    canUseFileSourceInCopilot: ComputedCanUseFeature('group-ps-copilot-file-source-content'),
    canUseExternalContentInCopilot: ComputedCanUseFeature('group-ps-copilot-external-content'),
    canUseKnowledgeWebSyncQualityImprovement: ComputedCanUseFeature(
      'team-ki-web-sync-quality-improvement',
    ),
    canSelectConfluenceSubFolder: ComputedCanUseFeature('team-ki-select-confluence-sub-folder'),
    canUseIntegrationAppForSalesforce: ComputedCanUseFeature(
      'team-knowledge-interop-sfdc-integration',
    ),
    canUseFieldMapperForImportSource: ComputedCanUseFeature(
      'team-knowledge-foundations-import-source-field-mapping',
    ),
    canUseIntegrationAppForBox: ComputedCanUseFeature('team-knowledge-interop-box-integration'),
    canUseDoc360Integration: ComputedCanUseFeature('team-knowledge-foundations-doc360-integration'),
    canUseDatesInWebsync: ComputedCanUseFeature('team-rd-services-dates-in-websync'),
    canUseAudienceForCopilot: computed('features.[]', 'localStorageFeatures.[]', function () {
      return (
        this.canUseFeature('psg-audience-for-copilot') &&
        !this.canUseFeature('team-kds-copilot-audience-targeting-opt-out')
      );
    }),
    canUseSantizeImagesBeforeSave: ComputedCanUseFeature(
      'team-knowledge-foundations-sanitize-images-before-save',
    ),

    showRegulatoryComplianceCountriesForSMS: ComputedCanUseFeature(
      'ps-regulatory-compliance-countries-for-sms',
    ),
    canUseHelpCenterFooterTextCustomization: ComputedCanUseFeature(
      'team-kds-customizable-help-center-footer-text',
    ),

    canUseEventMetadataGoals: ComputedCanUseFeature('psg-event-metadata-matching'),
    canUseUnlistedArticlesForFin: ComputedCanUseFeature(
      'kds-allow-unlisted-articles-as-fin-content',
    ),
    canUseArticleResultsShown: ComputedCanUseFeature('kds-can-use-article-results-shown'),

    // team-proactive-support
    canUseTourFontProximaNova: ComputedCanUseFeature('outbound-tour-font-proxima-nova'),

    //team-psg-articles
    canUseArticleVersionSteps: ComputedCanUseFeature('articles-version-steps'),
    canUseArticleSuggestions: ComputedCanUseFeature('articles-content-suggestions'),

    //team-pricing-and-packaging
    canUseBulkEditSeats: ComputedCanUseFeature('can-bulk-edit-seats'),
    hasMultipleSeatTypes: ComputedCanUseFeature('multiple_seat_types'),
    hasRoles: ComputedCanUseFeature('role_based_permissions'),
    hasMultipleSeatTypesPaywalled: ComputedCanUseFeature('multiple_seat_types_paywalled'),
    canSeeLastSeenDatesOnTeammateList: ComputedCanUseFeature(
      'can-see-last-seen-dates-on-teammate-list',
    ),
    canSeeBulkWhatsapp: ComputedCanUseFeature('team-pnp-can-see-bulk-whatsapp'),
    canFilterTeammateListDataByLastActive: ComputedCanUseFeature(
      'can-filter-teammate-list-data-by-last-active',
    ),
    canBulkDeleteTeammates: ComputedCanUseFeature('can-bulk-delete-teammates'),
    canUseSelectAllInTeammateList: ComputedCanUseFeature('can-use-select-all-in-teammate-list'),
    cannotSeePaymentFailureModal: ComputedCanUseFeature('team-pbe-hide-payment-failure-modal'),
    canSeePaymentFailureModal: not('cannotSeePaymentFailureModal'),
    preventDeletingToBeEmptyTeams: ComputedCanUseFeature(
      'team-pnp-prevent-deleting-to-be-empty-teams',
    ),

    cannotAssignWiderAccess: ComputedCanUseFeature('wm-permissions-cannot-assign-wider-access'),

    canUsePerProductPricingFlow: ComputedCanUseFeature('per-product-pricing-flow'),
    canUseArticles: ComputedCanUseFeature(ARTICLES_FEATURE),
    canUseInbox: ComputedCanUseFeature(INBOX_FEATURE),
    canUseMessages: ComputedCanUseFeature(MESSAGES_FEATURE),
    canUsePeopleReachedInBillingCycle: ComputedCanUseFeature(
      'team-pnp-show-people-reached-in-billing-cycle',
    ),

    hasPricingMigrationUIFeature: ComputedCanUseFeature('show-stripe-migration-ui'),
    hasPricingMigrationImprovedFlowFeature: ComputedCanUseFeature('pnp-improved-ss-migration-flow'),
    canSeePricingMigrationUnderImpersonation: ComputedCanUseFeature(
      'pnp-qa-price-breakdowns-under-impersonation',
    ),

    canSeeSelfServeChurnSurvey: ComputedCanUseFeature('self-serve-churn-survey'),
    hasCopilotSeatType: ComputedCanUseFeature('copilot_seat_type_billing_feature'),
    hasUnlimitedCopilotAccessAddOn: ComputedCanUseFeature(
      'unlimited_fin_ai_copilot_access_billing_feature',
    ),
    canSkipActiveInvitesLimitCheck: ComputedCanUseFeature('pnp-skip-active-invites-limit-check'),
    //team-self-serve
    hasIntercomHomeDevelopmentMode: ComputedCanUseFeature('intercom-home-development-mode'),
    canSeeProfilingSurvey: ComputedCanUseFeature('team-activation-profiling-survey'),
    canSeeIASettingsGA: ComputedCanUseFeature('team-product-exploration-ia-settings-ga'),
    canUseGlobalSearch: ComputedCanUseFeature('team-product-guidance-global-search'),
    canUseHelpdeskSetupWip: ComputedCanUseFeature('team-product-guidance-helpdesk-setup-wip'),
    canUseNewProgressionExperience: ComputedCanUseFeature(
      'team-growth-opportunities-new-progression-experience',
    ),
    canPauseEarlyStageSubscription: ComputedCanUseFeature(
      'team-growth-opportunities-pause-subscription-experiment',
    ),
    disableExplicitFinOptIn: ComputedCanUseFeature(
      'team-growth-opportunities-disable-explicit-fin-opt-in',
    ),
    canSeeChurnSaveInterventionChanges: ComputedCanUseFeature(
      'team-self-serve-churn-save-intervention-wip',
    ),
    canUseIncreasedFinAllowance: ComputedCanUseFeature('team-self-serve-es-fin-allowance-increase'),

    //team-purchase
    canUseSeatOverages: ComputedCanUseFeature('team-sales-led-growth-seat-overages'),
    hasSeatOveragesEnabled: or('canUseSeatOverages', 'isOptedOutOfSeatBlocking'),
    hideGlobalTrialBanner: ComputedCanUseFeature('team-expansion-hide-global-trial-banner'),
    hadIntershopAccessRevoked: ComputedCanUseFeature('team-expansion-revoke-intershop-access'),
    isCreatedAfterFinGeneralReleaseDate: ComputedCanUseFeature(
      'team-expansion-fin-general-release',
    ),
    teamPurchaseExperienceTrialExtensionsVariant: ComputedCanUseFeature(
      'team-purchase-experience-trial-extensions-variant',
    ),
    teamPurchaseExperienceTrialExtensionsControl: ComputedCanUseFeature(
      'team-purchase-experience-trial-extensions-control',
    ),
    disableFrontendAddressValidation: ComputedCanUseFeature(
      'team-purchase-experience-disable-address-validation',
    ),
    canUseReferrals: ComputedCanUseFeature('team-purchase-experience-referrals'),
    canUseMultipleMonthlySeatsExperiment: ComputedCanUseFeature(
      'team-purchase-experience-multiple-monthly-seats-experiment',
    ),
    canUseExpiredSubscriptionPage: ComputedCanUseFeature(
      'team-purchase-experience-expired-subscription-page',
    ),
    isExpiredSubscriptionPageEnabledForDev: ComputedCanUseFeature(
      'team-purchase-experience-expired-subscription-page-enabled-for-dev',
    ),
    canUseNewConversionsApi: ComputedCanUseFeature('team-purchase-conversions-api-improvement'),
    //team-messenger
    canUseConditionalDisplayForVisitors: ComputedCanUseFeature(
      'visitor_messenger_conditional_display',
    ),
    canUseNewVamPredicates: ComputedCanUseFeature('messenger-new-vam-predicates'),
    hasTargetInboundConversationsFeature: ComputedCanUseFeature('target_inbound_conversations'),
    canUseUnbrandedMessenger: ComputedCanUseFeature('unbranded'),
    canUseNews: ComputedCanUseFeature('news'),
    canUseNewsPathPrefixForCustomDomain: ComputedCanUseFeature(
      'news_path_prefix_for_custom_domain',
    ),
    canUseSalesPreview: ComputedCanUseFeature('team-mobile-sales-preview'),
    canSearchBrowseWithoutHelpSpace: ComputedCanUseFeature('search-browse-without-help-space'),
    canUseIdvEnabledSignUp: ComputedCanUseFeature(
      'team-messenger-idv-enabled-on-signup-experiment-candidate',
    ),
    isNewInstallationPageCandidate: ComputedCanUseFeature(
      'team-messenger-messenger-install-page-experiment-candidate',
    ),
    hasNewMessengerInstallationPage: or('canUseIdvEnabledSignUp', 'isNewInstallationPageCandidate'),
    canUseAliasNames: ComputedCanUseFeature('team-messenger-admin-alias'),
    canUseMultipleIdvSecrets: ComputedCanUseFeature('workstream-idv-cycling'),
    canUseJwtSecuritySettings: ComputedCanUseFeature('messenger-jwt-security-settings'),
    hideQuickReplies: ComputedCanUseFeature('team-messenger-hide-quick-replies'),

    //team-operator
    canUseOperatorQualifications: ComputedCanUseFeature('operator_qualification'),
    canUseVisitorAutoMessaging: ComputedCanUseFeature('visitor_auto_messaging'),
    canUseAppsInOperator: ComputedCanUseFeature('apps_in_operator'),
    hasHelpCenter: ComputedCanUseFeature('help_center'),
    hasEmbeddedArticles: ComputedCanUseFeature('embedded_articles'),
    canUseArticleAudienceTargeting: ComputedCanUseFeature('article_audience_targeting'),
    canUseReusableWorkflowContentDependency: ComputedCanUseFeature(
      'self-serve-reusable-workflows-content-dependency-read',
    ),
    canUseWorkflowsNetNewExperience: ComputedCanUseFeature('workflows-net-new-experience'),
    hasVolumeControlPredictedAssignmentDeprecated: ComputedCanUseFeature(
      'predicted-assignment-volume-control-deprecated',
    ),
    canUseExternalContentLibrary: ComputedCanUseFeature('psg-external-content-library'),
    canSeeTaskBotsRedesign: ComputedCanUseFeature('task-bots-redesign'),
    hasFinIdentity: ComputedCanUseFeature('fin-identity'),
    canUseConversationAttributeChangedWorkflows: ComputedCanUseFeature(
      'conversation-attribute-changed-workflows',
    ),
    canUseAiSentimentAnalysis: ComputedCanUseFeature('ai-sentiment-analysis'),
    canUseCollectableDatetimeAttributes: ComputedCanUseFeature('collectable-datetime-attributes'),
    canUseMidPathReusableWorkflows: ComputedCanUseFeature('mid-path-reusable-workflows'),
    canUseSetUserDataAttribute: ComputedCanUseFeature('team-workflows-set-user-data-attribute'),
    canUseFinAiCategories: ComputedCanUseFeature('team-workflows-fin-ai-routing'),
    canEnableComposerWhileWaitingForRating: ComputedCanUseFeature(
      'enable-composer-while-waiting-for-rating',
    ),
    canUseCSATRequestedForPredicate: ComputedCanUseFeature(
      'workflows-can-use-csat-requested-for-predicate',
    ),
    canUseKnownComparisonForListPredicates: computed(
      'features.[]',
      'localStorageFeatures.[]',
      function () {
        return (
          this.canUseFeature('team-workflows-fin-ai-routing') ||
          this.canUseFeature('ai-conversation-classification')
        );
      },
    ),
    canUseInboundBotsSansReplyButtons: ComputedCanUseFeature('inbound-bots-sans-reply-buttons'),
    canSeeAiInsightsTopicsV1: ComputedCanUseFeature('ai-insights-topics-v1-ui'),
    canUseMultilingualWorkflows: ComputedCanUseFeature('multilingual-workflows'),

    //team-ai-insights
    canDrillInFromTableCells: ComputedCanUseFeature('reporting-drill-in-from-table-cells'),

    //team-reporting
    canSeeReportsSharePermission: ComputedCanUseFeature('reporting--reports--share-permission'),
    isOnCsvBlockedList: ComputedCanUseFeature('csv-import-blockedlist'),
    canSeeCustomReportsAcademyLink: ComputedCanUseFeature(
      'custom-reports-enablement-banner-academy-link',
    ),
    canUseOperatorTeammateFilter: ComputedCanUseFeature('operator-teammate-filter'),
    canUseAttributesInFilters: ComputedCanUseFeature('reporting-attributes-in-filters'),

    isOptedOutOfSeatBlocking: ComputedCanUseFeature('team-people-seat-blocking-opt-out'),
    teammateTableDynamicQueries: ComputedCanUseFeature('teammate-table-dynamic-queries'),
    canSeeMetricsPlayground: ComputedCanUseFeature('reporting-metrics-playground'),
    canUseGoogleAnalytics4Integration: ComputedCanUseFeature('google-analytics-4-integration'),
    canUseTopicFilter: readOnly('hasConversationalInsightsBillingFeature'),

    hasConversationalInsightsBillingFeature: ComputedCanUseFeature('conversational_insights'),
    hasConverationalInsightsIntegrationExplicitlyDisabled: ComputedCanUseFeature(
      'insights-conversational-insights-disable-inbox-integration',
    ),
    canQueryPowerfulIndices: ComputedCanUseFeature('insights-powerful-indices'),
    hasCustomReportsBillingFeature: ComputedCanUseFeature('custom_reports'),
    hasCopilotReporting: ComputedCanUseFeature('answerbot-inbox-copilot'),
    canUseSyntheticReportingData: ComputedCanUseFeature('reporting-synthetic-data'),
    canSeeChatbotCsatReporting: ComputedCanUseFeature('chatbot-csat-reporting'),
    canUseCSATVOne: ComputedCanUseFeature('csat-version-one'),
    canSeeFullR2Experience: ComputedCanUseFeature('reporting-r2-full-experience'),
    cannotSeeDeprecatedReports: computed('features.[]', 'localStorageFeatures.[]', function () {
      return (
        this.canUseFeature('reporting-no-deprecated-reports') ||
        this.canUseFeature('reporting-r2-full-experience')
      );
    }),
    canShareReportsInternally: ComputedCanUseFeature('reporting-internal-sharing'),
    // if we want to override the current date for a sales demo, we can opt the workspace
    // into a feature flag, e.g. reporting-override-today-as-2021-02-03
    reportingCurrentDateOverride: computed('features.[]', function () {
      let featureFlagMatch = this.features
        .map((f) => f.id.match(/^reporting-override-today-as-(?<date>.+)$/))
        .compact().firstObject;
      if (!featureFlagMatch) {
        return null;
      }
      return featureFlagMatch.groups['date'];
    }),
    canSeeNewChartLayout: ComputedCanUseFeature('reporting-new-chart-layout'),
    cannotSeeCSOverviewReport: ComputedCanUseFeature('reporting-no-cs-overview-report'),
    hasDeprecatedCSOverviewReport: ComputedCanUseFeature('reporting-deprecate-cs-overview-report'),
    canUseNewDataExport: ComputedCanUseFeature('reporting-new-data-export'),

    //team-tickets-1 billing
    canUseConversationRatings: ComputedCanUseFeature('conversation_ratings'),
    canUseInboxSlas: ComputedCanUseFeature('inbox_conversation_sla_rules'),
    canUsePriorityRules: ComputedCanUseFeature('inbox_priority_rules'),
    canUseTagRules: ComputedCanUseFeature('inbox_conversation_tag_rules'),

    //team-tickets-1 features
    canSeeSnowfall: ComputedCanUseFeature('respond-inbox-of-love-snowflakes'),
    canUseBackgroundInboxes: ComputedCanUseFeature('ember-inbox-background-inboxes'),
    canUseSavedRepliesFuzzySearch: ComputedCanUseFeature('team-inbox-saved-replies-fuzzy-search'),
    canSnoozeByHours: ComputedCanUseFeature('team-inbox-can-snooze-by-hours'),
    canUseEmailNotificationDestinationUrl: ComputedCanUseFeature(
      'email-notification-destination-url',
    ),
    canUseEmailAttachments: ComputedCanUseFeature('direct-mail-send-email-attachments'),

    canAssignToTeamAndTeammate: ComputedCanUseFeature('maintain-conversation-context'),
    canUseCollapseConversationDetails: ComputedCanUseFeature('collapse-conversation-details'),
    canUseDisableNotificationForRepliesToUnassigned: ComputedCanUseFeature(
      'disable-notification-for-replies-to-unassigned',
    ),
    canUseElasticsearchInboxes: ComputedCanUseFeature('es-inboxes'),
    canUseCustomAddressPermission: ComputedCanUseFeature('custom-address-permission'),

    canOverrideSenderOnOsc: ComputedCanUseFeature('inbox_override_sender_on_osc'),
    canUseUserUpdaterListeners: ComputedCanUseFeature('inbox_allow_user_updater_listeners'),
    canSeeInboxConversationTranscriptsExport: ComputedCanUseFeature(
      'inbox__conversation_transcripts__export_permission',
    ),
    canUseSLAAdvanceWarningTrigger: ComputedCanUseFeature('sla-breach-advance-warning-trigger'),
    canUseSlackChannelInput: ComputedCanUseFeature('team-collaboration-slack-notifications-input'),
    canNotifyPrivateSlackChannel: ComputedCanUseFeature('notify-private-slack-channel'),
    canUseSlackLegacyApp: ComputedCanUseFeature('dev-platform-legacy-slack-integration'),
    excludeTicketsBalancedAssignment: ComputedCanUseFeature('filter-tickets-balanced-assignment'),
    canUseTicketsAssignmentSwitch: ComputedCanUseFeature(
      'team-tickets-2-tickets-assignment-switch',
    ),

    //team-tickets-1 features
    canUseWorkloadManagement: readOnly('workloadManagementEnabled'),
    canUseAwaitingFirstReplyTrigger: ComputedCanUseFeature(
      'inbox_rules_awaiting_first_reply_event',
    ),
    hasMacros: ComputedCanUseFeature('macros_with_actions_billing_feature'),
    canUseMacros: reads('hasMacros'),
    canUseRoleCapacity: ComputedCanUseFeature('team-support-retain-role-level-capacity'),
    trackMacroSearches: ComputedCanUseFeature('team-sops-track-macro-searches'),
    canSetMacroVisibility: ComputedCanUseFeature('team_macros'),
    hasRealtimeMonitoring: ComputedCanUseFeature('realtime_monitoring'),
    canSeeConversationsPerHourMetric: ComputedCanUseFeature(
      'team-sops-realtime-conversations-per-hour',
    ),
    canSeeReassignAction: ComputedCanUseFeature('team-sops-reassign-action'),
    canSeeTeammateWorkloadPage: ComputedCanUseFeature('team-sops-wlm-teammates-table'),
    canUseLbaTagMatch: ComputedCanUseFeature('lba-tag-match'),
    canUseDisableEmailReassignment: ComputedCanUseFeature('team-sops-wlm-stop-email-reassignment'),
    canUseTeammateStatusPerWorkspace: ComputedCanUseFeature(
      'team-sops-use-per-workspace-away-status',
    ),
    canUseTeammateStatusAutoAwayMode: ComputedCanUseFeature(
      'team-sops-teammate-status-auto-away-mode',
    ),
    canEditTicketDesciptionDescriptions: ComputedCanUseFeature(
      'edit-ticket-description-descriptions',
    ),
    canUseWorkflowsTicketsV3: ComputedCanUseFeature('workflows-tickets-v3'),
    canSkipTicketsPortalIdvRequirement: ComputedCanUseFeature(
      'skip-tickets-portal-idv-requirement',
    ),
    canEditAwayStatusReasons: ComputedCanUseFeature('hsg-tickets-away-mode-reasons-crud'),
    canUseAwayModeAgentStatusCustomReasons: ComputedCanUseFeature(
      'hsg-tickets-away-mode-agent-status-custom-reasons',
    ),

    // team-ai-agent
    canUseFinThumbsDown: ComputedCanUseFeature('fin-thumbs-down'),
    canUseFinGuidance: ComputedCanUseFeature('fin-guidance-closed-beta'),
    canUseThemeBasedRollout: ComputedCanUseFeature('ai-agent-theme-based-rollout'),
    canUseFinGuidanceReusablePreview: ComputedCanUseFeature('fin-guidance-use-reusable-preview'),
    canUseFinGuidanceGA: ComputedCanUseFeature('fin-guidance-ga'),
    canUseFinPreviewImpersonation: ComputedCanUseFeature('fin-preview-impersonation'),
    canUseFinPreviewAutoLanguageDetection: ComputedCanUseFeature(
      'fin-preview-auto-language-detection',
    ),
    canUseFinPlaygroundFinTasks: ComputedCanUseFeature('fin-playground-fin-tasks'),

    //team-help-desk-experience features
    canUseAiAssistInArticles: ComputedCanUseFeature('articles-ai'),
    canUseAiAssistAutoFillTickets: ComputedCanUseFeature('ai_assist_auto_fill_tickets'),
    canUseMultiBrandEmailOpenBeta: ComputedCanUseFeature('multibrand-email-open-beta'),
    canUseAiConversationClassification: ComputedCanUseFeature('ai-conversation-classification'),
    canByPassMultibrandPaywall: ComputedCanUseFeature('multibrand-ignore-paywall'),
    canUseMultibrandBillingFeature: ComputedCanUseFeature('messenger_multibrand'),
    canUseMultibrandEmailBrandSda: ComputedCanUseFeature('multibrand-email-brand-sda'),
    hasBeenOnMultibrandBeta: ComputedCanUseFeature('has-been-on-multibrand-beta'),

    // team-ml features
    hasAnswerBot: ComputedCanUseFeature('answer_workflows'),
    hasDetailedActivityTracking: ComputedCanUseFeature('ember-ml-detailed-activity-tracking'),
    hasSampledActivityTracking: ComputedCanUseFeature('ember-ml-sampled-activity-tracking'),
    canUseGlobalAiOptOut: ComputedCanUseFeature('answerbot-ai-global-opt-out'),
    canUseFinCustomStep: ComputedCanUseFeature('fin-custom-step'),
    canUseFinCustomImageRecognition: ComputedCanUseFeature(
      'answerbot-fin-custom-image-recognition',
    ),

    //misc features
    shouldShowIntercomradeWarning: ComputedCanUseFeature('ember-enable-intercomrade-warning'),

    //BILLING:
    hasMultipleLanguages: ComputedCanUseFeature('multiple_language_support'),
    canUseRotatingAssignment: ComputedCanUseFeature('round_robin'),
    canUseFree: ComputedCanUseFeature('ember-purchase-free'),
    canUseRevenueReports: ComputedCanUseFeature('revenue_reports'),
    canUseCSVExport: ComputedCanUseFeature('inbox_csv_export'),
    workloadManagementEnabled: ComputedCanUseFeature('manage_capacity'),
    canUseBranchingQualification: ComputedCanUseFeature('branching_qualification'),
    canUseConversationRouting: ComputedCanUseFeature('conversation_routing'),
    hasAccountMarketingBillingFeature: ComputedCanUseFeature('account_marketing'),
    hasUpfrontEmailCollectionBillingFeature: ComputedCanUseFeature('upfront_email_collection'),
    hasDynamicAccountMessagesBillingFeature: ComputedCanUseFeature('dynamic_account_messages'),
    hasSalesforceSandboxFeatureInPlan: ComputedCanUseFeature('salesforce_sandbox'),
    canUseEmailForwarding: computed(
      'features.[]',
      'localStorageFeatures.[]',
      'products.[]',
      function () {
        return this.canUseFeature('email_forwarding');
      },
    ),

    canUseInboxRules: ComputedCanUseFeature('inbox_rules'),
    hasDefaultSenderAddress: ComputedCanUseFeature('default_sender_address'),

    //design systems
    canUseHasFeatureTest: ComputedCanUseFeature('has-feature-test-feature'),

    //team-app-security
    canUsePaginatedActivityLogs: ComputedCanUseFeature('ember-paginated-activity-logs'),

    // team-conversations
    isForbiddenFromUsingUserPresenceService: ComputedCanUseFeature(
      'ember-conversations-is-forbidden-from-using-user-presence-service',
    ),

    // team-channels
    canUseSwitchStatusFilter: ComputedCanUseFeature('switch-status-filter'),
    useWhatsappCloudApi: ComputedCanUseFeature('whatsapp-cloud-api'),
    canUseWhatsappOutbound: computed(
      'features.[]',
      'localStorageFeatures.[]',
      'products.[]',
      function () {
        return (
          this.canUseFeature('whatsapp_integration') &&
          this.canUseFeature('whatsapp_outbound_billing_feature')
        );
      },
    ),
    canRemoveConversationHistory: ComputedCanUseFeature(
      'channels-conversation-history-removal-setting',
    ),
    enableTwitterIntegration: ComputedCanUseFeature('enable-twitter-integration'),
    enableTwitterIntegrationBots: ComputedCanUseFeature('enable-twitter-integration-bots'),

    // team-standalone

    canUseStandalone: readOnly('is_standalone_app'),
    canUseStandaloneCopilot: ComputedCanUseFeature('team-standalone-copilot'),

    canUseEnglishVariations: ComputedCanUseFeature('language-support-english-versions'),
    allowAccessOutsideStandaloneSection: ComputedCanUseFeature(
      'team-standalone-allow-access-outside-standalone-section',
    ),
    canUseExternalArticlesAsFinContent: ComputedCanUseFeature(
      'ai-allow-external-articles-as-fin-content',
    ),
    canUseHandoffToSalesforceChat: ComputedCanUseFeature(
      'team-standalone-handoff-to-salesforce-chat',
    ),
    canUseHandoffToSalesforceInAppMessaging: ComputedCanUseFeature(
      'team-standalone-handoff-to-salesforce-in-app-messaging',
    ),
    canChangeContentImportState: ComputedCanUseFeature(
      'team-standalone-change-content-import-state',
    ),
    canUseZendeskPrivateArticleImport: ComputedCanUseFeature(
      'team-standalone-zendesk-private-articles-sync',
    ),
    canUseCopilotSeatProvisioningOnStandalone: ComputedCanUseFeature(
      'team-standalone-copilot-seat-provisioning',
    ),

    canUseStripeAppAuthFlow: ComputedCanUseFeature('cdp-stripe-auth-with-stripe-app-flow'),

    canUseFeatureWithoutLocalOverrides(featureIdentifier) {
      return this.canUseFeature(featureIdentifier, false);
    },
    canUseFeature(featureIdentifier, allowLocalOverrides = true) {
      let appId = this.id;

      if (IntercomradesProductsOverride.isEnabled(appId)) {
        return IntercomradesProductsOverride.features(this).includes(featureIdentifier);
      } else {
        return (
          (allowLocalOverrides && this.localStorageFeatures.includes(featureIdentifier)) ||
          this.features.findBy('id', featureIdentifier) !== undefined
        );
      }
    },

    // team-billing
    usagePageTweaksForFinBuckets: ComputedCanUseFeature(
      'billing-usage-page-tweaks-for-fin-buckets',
    ),

    // default-ff-tests do not change or activate in prod env
    testFeatureEnabledByGlobalFeatureFlagDefaults: ComputedCanUseFeature(
      nameForTestFeatureEnabledByGlobalFeatureFlagDefaults,
    ),
    testFeatureDisabledByGlobalFeatureFlagDefaults: ComputedCanUseFeature(
      nameForTestFeatureDisabledByGlobalFeatureFlagDefaults,
    ),

    init() {
      this._super(...arguments);
      this.refreshLocalStorageFeatures();
    },
    refreshLocalStorageFeatures() {
      this.set('localStorageFeatures', localFeatureFlags.list());
    },
    addLocalStorageFeature(featureKey) {
      localFeatureFlags.add(featureKey);
      this.refreshLocalStorageFeatures();
    },
    removeLocalStorageFeature(featureKey) {
      localFeatureFlags.remove(featureKey);
      this.refreshLocalStorageFeatures();
    },
  };
};

export default Mixin.create(generateFeatureFlagConfig());

// this list only includes features from the above config generation that have
// a direct call to ComputedCanUseFeature(). it DOES NOT include complex
// computed() fields, unless the feature names used inside the computed happen
// to also have sibling static flags using ComputedCanUseFeature()
export const ALL_STATIC_FEATURE_FLAG_NAMES = Object.values(
  generateFeatureFlagConfig({ flattenComputed: true }),
).filter((value) => typeof value === 'string');
