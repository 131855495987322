/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';

export type HeaderAlignment = 'start' | 'center';
export type HeaderJustify = 'start' | 'center' | 'end';

export default class HeaderStyle extends Fragment {
  @attr('string') declare height: string;
  @attr('string') declare align: HeaderAlignment;
  @attr('string') declare justify: HeaderJustify;
}
