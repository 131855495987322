/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Model, { attr } from '@ember-data/model';
import type { Block } from '@intercom/interblocks.ts';
import { type Status } from '../data/content-service/content-review-statuses';

export enum ReviewType {
  CREATE_CONTENT = 'create_content',
  EDIT_CONTENT = 'edit_content',
  DELETE_CONTENT = 'delete_content',
}

export enum BlockEditType {
  EDIT_EXISTING = 'edit_existing',
  APPEND_NEW_BLOCKS = 'append_new_blocks',
}

export type Suggestion = {
  indices: [number, number];
  editedBlocks: Block[];
  includedConversationIds: number[];
  blockEditType: BlockEditType;
};

export default class ContentReviewRequest extends Model {
  @attr('number') declare reviewerId: number;
  @attr('number') declare status: Status;
  @attr('number') declare entityId: number;
  @attr('number') declare entityType: number;
  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: Date;
  @attr('string') declare reviewReason: string;
  @attr('string') declare reviewType: ReviewType;
  @attr() declare updatedSuggestionBlocks: Array<Block>;
  @attr() declare suggestion?: Suggestion | null;
}
