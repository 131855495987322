/* RESPONSIBLE TEAM: team-knowledge-foundations */

import Fragment from 'ember-data-model-fragments/fragment';
import { attr } from '@ember-data/model';

export default class Brand extends Fragment {
  @attr('array') declare colors: string[];
  @attr('string') declare websiteUrl: string;

  setDefaultValues() {
    if (!this.colors) {
      this.colors = [];
    }

    if (!this.websiteUrl) {
      this.websiteUrl = '';
    }
  }
}
