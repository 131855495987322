/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { fragmentArray } from 'ember-data-model-fragments/attributes';
import Fragment from 'ember-data-model-fragments/fragment';
import { tinycolor } from '@intercom/intercom-messenger-colors';
import type GradientStep from './gradient-step';

const MIN_STEPS = 2;
const MAX_STEPS = 3;

export default class Gradient extends Fragment {
  @fragmentArray('customization-options/gradient-step') declare steps: GradientStep[];

  addStep(color?: string) {
    if (!color) {
      if (this.steps.length === 0) {
        color = '#000000';
      } else {
        let lastStep = this.steps.lastObject;
        color = tinycolor(lastStep?.color || 'ffffff')
          .spin(90)
          .toHexString();
      }
    }
    let stepFragment = this.store.createFragment('customization-options/gradient-step', {
      color,
    });
    this.steps.pushObject(stepFragment);
  }

  removeStep(index: number) {
    this.steps.removeAt(index);
  }

  get hasSteps() {
    return this.steps.length > 0;
  }

  get canRemoveSteps() {
    return this.steps.length > MIN_STEPS;
  }

  get canAddSteps() {
    return this.steps.length < MAX_STEPS;
  }
}
