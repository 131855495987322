/* RESPONSIBLE TEAM: team-ai-agent */
import { inject as service } from '@ember/service';
import { setOwner } from '@ember/application';
import { post } from 'embercom/lib/ajax';
import { tracked } from '@glimmer/tracking';
// @ts-ignore
import { copy } from 'ember-copy';
import type IntlService from 'ember-intl/services/intl';
import type Store from '@ember-data/store';
import type BotPart from 'embercom/models/operator/bot-intro/part';
import type Group from 'embercom/models/operator/visual-builder/group';
import type Step from 'embercom/models/operator/visual-builder/step';
import type ChatMessage from 'embercom/models/operator/visual-builder/step/chat-message';

export type ChannelType = 'chat' | 'email' | 'phone';
export type HandoverType = 'route_to_inbox' | 'close_conversation';
type BotMode = 'looping' | 'bot_only';
export const FIN_WORKFLOW_PREVIEW_ID = 'visual-builder-object-workflow-preview';

export default class FinWorkflowPreview {
  @service declare appService: $TSFixMe;
  @service intercomEventService: $TSFixMe;
  @service declare store: Store;
  @service declare intl: IntlService;

  @tracked ruleset: $TSFixMe;
  @tracked channelType: ChannelType = 'chat';
  @tracked handoverType: HandoverType = 'route_to_inbox';
  @tracked csatEnabled = false;
  @tracked csatEnabledForHardResolution = false;
  @tracked csatEnabledForSoftResolution = false;
  @tracked preHandoverAnswerEnabled = false;
  @tracked botMode: BotMode = 'looping';

  @tracked csatBlockUpdateAfter: number | undefined;

  @tracked endUserInactivityTimer = 180;

  @tracked autoCloseWhenAnswer = true;
  @tracked autoCloseWhenNoAnswer = false;
  @tracked autoCloseWhenNoQuestion = true;

  @tracked assigneeId: string;
  preHandoverAnswerAllowed = true;
  @tracked parts: BotPart[] = [];
  @tracked introParts: BotPart[] = [];
  @tracked autoCloseMessageParts: BotPart[] = [];

  constructor(owner: unknown) {
    setOwner(this, owner);
    this.assigneeId = this.appService.app.currentAdmin.id || '0';
    this.addInitialPart();
    this.generatePreview();
  }

  setChannelType(channelType: ChannelType) {
    this.channelType = channelType;
  }

  setHandoverType(type: HandoverType) {
    this.handoverType = type;
    if (this.handoverType === 'close_conversation') {
      this.botMode = 'bot_only';
      this.preHandoverAnswerEnabled = false;
    } else {
      this.botMode = 'looping';
    }
  }

  setAssigneeId(assigneeId: string) {
    this.assigneeId = assigneeId;
  }

  addPart() {
    this.parts.pushObject(this.store.createFragment('operator/bot-only-message/part'));
  }

  addAutoCloseMessagePart() {
    this.autoCloseMessageParts.pushObject(
      this.store.createFragment('operator/fin/auto-close-message'),
    );
  }

  addInitialPart() {
    let defaultBlock = {
      type: 'paragraph',
      text: this.intl.t(
        'operator.fin.setup.setup-and-go-live.close-conversation.body.initial-part',
      ),
    };
    let block = this.store.createFragment('common/blocks/paragraph', defaultBlock);
    this.parts.pushObject(
      this.store.createFragment('operator/bot-only-message/part', {
        blocks: [block],
      }),
    );
  }

  removePart(part: BotPart) {
    this.parts.removeObject(part);
    if (this.parts.length === 0) {
      this.addPart();
    }
  }

  addIntroPart() {
    this.introParts.pushObject(this.store.createFragment('operator/bot-intro/part'));
  }

  removeIntroPart(part: BotPart) {
    this.introParts.removeObject(part);
    if (this.introParts.length === 0) {
      this.addIntroPart();
    }
  }

  reorderIntroParts(parts: BotPart[]) {
    this.introParts = parts;
  }

  addInitialIntroParts() {
    let introSteps: ChatMessage[] =
      this.ruleset?.rulesetLinks?.firstObject?.object?.visualBuilderObject?.groups
        ?.find((group: Group) => group.isStart)
        ?.steps?.filter((step: Step) => step.type === 'operator/visual-builder/step/chat-message');

    let introBlocks = introSteps?.map((step) => step.blocks);

    introBlocks?.forEach((blocks) => {
      let introPart = this.store.createFragment('operator/bot-intro/part', {
        blocks: copy(blocks),
      });
      this.introParts.pushObject(introPart);
    });
  }

  resetToDefaultIntroParts() {
    this.introParts = [];
    this.addInitialIntroParts();
  }

  removeAutoCloseMessagePart(part: BotPart) {
    this.autoCloseMessageParts.removeObject(part);
    if (this.autoCloseMessageParts.length === 0) {
      this.addAutoCloseMessagePart();
    }
  }

  reorderAutoCloseMessageParts(parts: BotPart[]) {
    this.autoCloseMessageParts = parts;
  }

  updatePredicates(predicates: $TSFixMe) {
    this.ruleset.set('predicateGroup.predicates', copy(predicates));
  }

  updateRolePredicates(rolePredicates: $TSFixMe) {
    this.ruleset.set('rolePredicateGroup.predicates', copy(rolePredicates));
  }

  get isAnyCSATSettingEnabled() {
    return this.csatEnabledForHardResolution || this.csatEnabledForSoftResolution;
  }

  async generatePreview() {
    let existingRuleset = this.ruleset;

    let response = await post('/ember/operator_workflows/preview_fin_workflow', {
      app_id: this.appService.app.id,
      channel_type: this.channelType,
      handover_type: this.handoverType,
      predicate_group: this.ruleset?.predicateGroup,
      role_predicate_group: this.ruleset?.rolePredicateGroup,
      assignee_id: this.assigneeId,
      csat_enabled: this.csatEnabled,
      csat_enabled_for_hard_resolution: this.csatEnabledForHardResolution,
      csat_enabled_for_soft_resolution: this.csatEnabledForSoftResolution,
      csat_block_update_after: this.csatBlockUpdateAfter,
      pre_handover_answer_enabled: this.preHandoverAnswerEnabled,
      bot_mode: this.botMode,
      inactivity_timer: this.endUserInactivityTimer,
      auto_close_no_answer: this.autoCloseWhenNoAnswer,
      auto_close_no_question: this.autoCloseWhenNoQuestion,
      auto_close_with_answer: this.autoCloseWhenAnswer,
      auto_close_follow_up_messages: this.autoCloseMessageParts,
      parts: this.parts,
      bot_intro_parts: this.introParts,
    });

    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: `fin_workflow_preview_generated`,
    });

    this.ruleset = this.store.push(this.store.normalize('matching-system/ruleset', response));

    if (existingRuleset) {
      this.ruleset.set(
        'predicateGroup.predicates',
        copy(existingRuleset.predicateGroup.predicates),
      );
      this.ruleset.set(
        'rolePredicateGroup.predicates',
        copy(existingRuleset.rolePredicateGroup.predicates),
      );
    }

    if (this.introParts.length === 0) {
      this.addInitialIntroParts();
    }
  }

  async save() {
    let response = await post('/ember/operator_workflows/create_fin_workflow', {
      app_id: this.appService.app.id,
      channel_type: this.channelType,
      handover_type: this.handoverType,
      predicate_group: this.ruleset.predicateGroup,
      role_predicate_group: this.ruleset.rolePredicateGroup,
      assignee_id: this.assigneeId,
      csat_enabled: this.csatEnabled,
      csat_enabled_for_hard_resolution: this.csatEnabledForHardResolution,
      csat_enabled_for_soft_resolution: this.csatEnabledForSoftResolution,
      csat_block_update_after: this.csatBlockUpdateAfter,
      pre_handover_answer_enabled: this.preHandoverAnswerEnabled,
      bot_mode: this.botMode,
      inactivity_timer: this.endUserInactivityTimer,
      auto_close_no_answer: this.autoCloseWhenNoAnswer,
      auto_close_no_question: this.autoCloseWhenNoQuestion,
      auto_close_with_answer: this.autoCloseWhenAnswer,
      auto_close_follow_up_messages: this.autoCloseMessageParts,
      parts: this.parts,
      bot_intro_parts: this.introParts,
    });

    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: `fin_workflow_created`,
    });

    return this.store.push(this.store.normalize('matching-system/ruleset', response));
  }
}
