/* RESPONSIBLE TEAM: team-knowledge-foundations */

export enum State {
  published = 0,
  unpublished = 1,
}

export enum ContentState {
  available = 0,
  hidden = 1,
}
