/* RESPONSIBLE TEAM: team-ml */
import Step, {
  type CreateStepParams,
  IsCustomerFacing,
} from 'embercom/models/operator/visual-builder/step';
import type Store from '@ember-data/store';
import { attr, hasMany, type SyncHasMany } from '@ember-data/model';
import type { Block } from 'embercom/models/common/blocks/block';
import type AttributeDescriptor from 'embercom/models/operator/visual-builder/attribute-descriptor';
import { LOCAL_VARIABLE_IDENTIFIER_PREFIX } from 'embercom/models/operator/visual-builder/attribute-descriptor';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import { buildValidations, validator } from 'ember-cp-validations';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { SUPPORTED_CHANNELS } from 'embercom/lib/operator/custom-bots/constants-2';
import { TargetContext } from 'embercom/objects/operator/configuration/custom-bot';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { equal } from '@ember/object/computed';
import { extractTemplateVariables } from 'embercom/lib/workflows/template-variables';
import type ConnectionPoint from '../connection-point';
// eslint-disable-next-line no-restricted-imports
import { getApp } from 'embercom/lib/container-lookup';

export const finCustomStepBlockValidators = {
  blocks: [
    validator('blocks-not-empty', {
      message: 'operator.workflows.visual-builder.validations.fin-custom.blank-prompt-error',
      messageTranslated: true,
    }),
  ],
  finOutputParams: [
    validator('presence', {
      presence: true,
      messageKey: 'operator.workflows.visual-builder.validations.fin-custom.no-output-params',
      disabled: equal('model.isV3', true),
    }),
    validator('inline', {
      validate(value: AttributeDescriptor[], _options: any, model: FinCustom) {
        let invalidParams = value.filter(
          (param) => !param.name?.trim() || !param.description?.trim(),
        );
        if (invalidParams.length > 0) {
          return model.intl.t(
            'operator.workflows.visual-builder.validations.fin-custom.output-cannot-be-empty',
          );
        }

        return true;
      },
    }),
  ],
  outwardConnectionPoints: [validator('has-many')],
  instructions: [
    validator('inline', {
      validate(value: any[], _options: any, model: FinCustom) {
        if (model.isV3) {
          let validInstructions = value.filter((blocks) =>
            blocks.any((block: $TSFixMe) => block.text.length > 0),
          );

          if (value.length > 0 && validInstructions.length > 0) {
            return true;
          }

          return model.intl.t(
            'operator.workflows.visual-builder.validations.fin-custom.instruction-blank-prompt-error',
          );
        }

        return true;
      },
      dependentKeys: ['instructions.[]'],
    }),
    validator('ai-prompt-data-is-valid', {
      dependentKeys: ['instructions.[]'],
    }),
  ],
};

const Validations = buildValidations({
  ...finCustomStepBlockValidators,
});

export default class FinCustom extends Step.extend(Validations) {
  @service declare intl: IntlService;
  @fragmentArray('common/blocks/block', { polymorphic: true, typeKey: 'modelKey' })
  declare blocks: SyncHasMany<Block>;

  @fragmentArray('common/blocks/block', { polymorphic: true, typeKey: 'modelKey' })
  declare guideline: SyncHasMany<Block>;

  @attr('array') declare instructions: any[];

  @hasMany('operator/visual-builder/attribute-descriptor', { async: false })
  declare finOutputParams: SyncHasMany<AttributeDescriptor>;
  @attr('boolean') declare allowMultiTurn: boolean;
  @attr('boolean', { defaultValue: false }) declare allowContentSearch: boolean;
  @attr('string') declare name: string;
  @attr('string') declare version: string;

  get isV3() {
    return this.version === 'v3';
  }

  static get canBeCustomerFacing(): IsCustomerFacing {
    return IsCustomerFacing.Maybe;
  }

  get isCustomerFacing() {
    // The step is deemed customer facing if it allows multi-turn tasks, i.e. the task could require input from the user.
    return this.allowMultiTurn;
  }

  get supportedChannels() {
    return SUPPORTED_CHANNELS.finCustom;
  }

  static get supportedContexts() {
    return [TargetContext.Conversation, TargetContext.CustomerTicket];
  }

  get jsonBlocks() {
    return this.blocks.serialize();
  }

  get app() {
    return this.appService.app;
  }

  @computed('blocks', 'isV3', 'instructions.[]')
  get localVariableIdentifiersUsed() {
    if (this.isV3) {
      return this.instructions
        .flatMap((instruction) => {
          return extractTemplateVariables(instruction);
        })
        .filter((variable) => variable.startsWith(LOCAL_VARIABLE_IDENTIFIER_PREFIX));
    } else {
      let templateVariables = extractTemplateVariables(this.blocks.toArray());
      return templateVariables.filter((variable) =>
        variable.startsWith(LOCAL_VARIABLE_IDENTIFIER_PREFIX),
      );
    }
  }

  get serializedBlocks() {
    // We only need to serialize the blocks if they are a FragmentArray (in tests they are already serialized)
    if (this.blocks.type === 'common/blocks/block') {
      return this.blocks.serialize();
    } else {
      return this.blocks;
    }
  }

  get serializedGuideline() {
    // We only need to serialize the blocks if they are a FragmentArray (in tests they are already serialized)
    if (this.guideline.type === 'common/blocks/block') {
      return this.guideline.serialize();
    } else {
      return this.guideline;
    }
  }

  get serializedInstructions() {
    return this.instructions.map((instruction) => instruction.serialize());
  }

  @computed(
    'hasDirtyAttributes',
    'outwardConnectionPoints.@each.hasUnsavedChanges',
    'instructions.[]',
  )
  get hasUnsavedChanges() {
    return (
      this.hasDirtyAttributes ||
      this.outwardConnectionPoints.isAny('hasUnsavedChanges') ||
      this.instructions.some((instruction) =>
        instruction.some((block: $TSFixMe) => block.hasDirtyAttributes),
      )
    );
  }

  static get requiredBillingFeature() {
    return 'workflows_core_billing_feature';
  }

  static createNewStep(store: Store, params: CreateStepParams = {}): FinCustom {
    let app = getApp();
    let version = params.inFinTask && app.canUseFinTaskV3 ? 'v3' : 'v2';

    let blocks = [
      store.createFragment('common/blocks/paragraph', {
        type: 'paragraph',
        text: '', // let the component decide what the default or placeholder text should be
      }),
    ];

    let guideline = [
      store.createFragment('common/blocks/paragraph', {
        type: 'paragraph',
        text: '', // let the component decide what the default or placeholder text should be
      }),
    ];

    let instructions: $TSFixMe = [];
    if (version === 'v3') {
      instructions = [
        [
          store.createFragment('common/blocks/paragraph', {
            type: 'paragraph',
            text: '', // let the component decide what the default or placeholder text should be
          }),
        ],
        [
          store.createFragment('common/blocks/paragraph', {
            type: 'paragraph',
            text: '', // let the component decide what the default or placeholder text should be
          }),
        ],
      ];
    }

    let outwardConnectionPoints = [
      store.createRecord('operator/visual-builder/connection-point', { type: 'fallback' }),
    ] as ConnectionPoint[];

    return store.createRecord('operator/visual-builder/step/fin-custom', {
      type: 'operator/visual-builder/step/fin-custom',
      version,
      blocks,
      guideline,
      instructions,
      outwardConnectionPoints,
    });
  }
}
