/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';

export default class ButtonOptions extends Fragment {
  @attr('number') declare borderRadius: number;
  @attr('string') declare fontColor: string;
  @attr('string') declare backgroundColor: string;
}
