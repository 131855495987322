/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Model, { attr, belongsTo } from '@ember-data/model';
import { dependentKeyCompat } from '@ember/object/compat';
import { inject as service } from '@ember/service';
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';
import Admin from 'embercom/models/admin';
import { defaultBlockWithModelKey, senderTypes } from 'embercom/models/data/outbound/constants';
import LocalizedChatContentValidations from 'embercom/validations/localized-chat-content';
import HasFeatureHelper from '../../helpers/has-feature';
import UserFacingAdminProxy from '../../utils/user-facing-admin-proxy';

export default class LocalizedChatContent extends Model.extend(LocalizedChatContentValidations) {
  @service store;
  @belongsTo('chats/chat', { inverse: 'localizedChatContents' }) chat;
  @fragmentArray('common/blocks/block', {
    polymorphic: true,
    typeKey: 'modelKey',
    defaultValue: () => [defaultBlockWithModelKey],
  })
  blocks;
  @attr('string') locale;
  @attr('string') senderId;
  @attr('string') assignToId;
  @attr('number') senderType;
  @attr('number') assignToType;
  @fragment('content-service/follow-up-actions') followUpActions;

  // @ts-ignore
  hasFeatureHelper = HasFeatureHelper.create(this);

  get isSenderOwner() {
    return this.senderType === senderTypes.owner;
  }

  get isAssignToOwner() {
    return this.assignToType === senderTypes.owner;
  }

  get contentHash() {
    let hash = this.serialize();
    delete hash['chat'];
    delete hash['id'];
    return hash;
  }

  @dependentKeyCompat
  get hasUnsavedChanges() {
    return this.hasDirtyAttributes || this.blocks.any((block) => block.hasDirtyAttributes);
  }

  get sender() {
    return Admin.peekAndMaybeLoad(this.store, this.senderId);
  }

  get assignTo() {
    return Admin.peekAndMaybeLoad(this.store, this.assignToId);
  }

  get senderName() {
    return new UserFacingAdminProxy(this.sender).firstNameOrNameOrEmail || 'Unknown';
  }
}
