/* RESPONSIBLE TEAM: team-proactive-support */
import Model, { attr } from '@ember-data/model';
import { dependentKeyCompat } from '@ember/object/compat';
import { inject as service } from '@ember/service';
import { isBlank } from '@ember/utils';
import { buildValidations, validator } from 'ember-cp-validations';

/*
 *  Note – properties added to this object will be persisted as part of the
 *  RulesetClientData's `metadata` field in the DB. You will not be able to
 *  query them.
 */

const Validations = buildValidations({
  title: [
    validator('inline', {
      validate(value, _options, model) {
        return isBlank(value)
          ? model.intl.t(
              'operator.workflows.visual-builder.workflow-trigger-node.fin-task.missing-title',
            )
          : true;
      },
    }),
  ],
});

export default class ClientData extends Model.extend(Validations) {
  @service intl;

  @attr('string', { defaultValue: () => '' }) title;
  @attr('array', { defaultValue: () => [] }) openedPanels;
  @attr('boolean', { defaultValue: false }) openedSeriesEditor;
  @attr('string', { defaultValue: () => '' }) shareableUrl;
  @attr('array', { defaultValue: () => [] }) templateMigrationReverted;
  @attr('array', { defaultValue: () => [] }) templateMigrationAccepted;

  // Title is the only key that is directly editable by the Teammate.
  // If a change occurs to, say, openedPanels, we do not want to block
  // leaving the page as this is not critical data and the Teammate
  // has no way to action this.
  // For this getter to propagate changes, we must use dependentKeyCompat
  // and access the `hasDirtyAttributes` computed property.
  @dependentKeyCompat
  get hasUnsavedChanges() {
    if (this.hasDirtyAttributes) {
      return Object.keys(this.changedAttributes()).includes('title');
    } else {
      return false;
    }
  }
}
