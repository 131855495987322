/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { attr } from '@ember-data/model';
import OutboundContent from './outbound-content';
import { tracked } from '@glimmer/tracking';
import { CollectionCardImageType } from './collection-card-options';

export default class CollectionCardGlobalOptions extends OutboundContent {
  @attr('boolean', { defaultValue: true, allowNull: true }) declare showIcons: boolean;

  @tracked imageType: CollectionCardImageType = CollectionCardImageType.Icons;
}
