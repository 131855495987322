/* RESPONSIBLE TEAM: team-reporting */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import type { BlockList } from '@intercom/interblocks.ts';

export type Color =
  | 'bg-transparent'
  | 'bg-beta-container'
  | 'bg-success-container'
  | 'bg-neutral-container'
  | 'bg-error-container'
  | 'bg-notice-container';
export type BorderColor =
  | 'border-neutral-border'
  | 'border-beta-container-emphasis'
  | 'border-success-container-emphasis'
  | 'border-neutral-container-emphasis'
  | 'border-error-container-emphasis'
  | 'border-notice-container-emphasis';
export default class Component extends Fragment {
  @attr('string', { defaultValue: 'bg-neutral-container' }) declare color?: Color;
  @attr('string', { defaultValue: 'border-neutral-container-emphasis' })
  declare borderColor?: BorderColor;
  @attr('boolean') declare isMigrationBanner?: boolean;
  @attr('array') declare blocks?: BlockList;
}
