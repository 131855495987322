/* RESPONSIBLE TEAM: team-tickets-1 */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';

export default class ListOption extends Fragment {
  @attr('string') declare listOptionId: string;
  @attr('string') declare label: string;
  @attr('string') declare description: string | null;
  @attr('boolean') declare archived: boolean;

  get trimmedLabel() {
    return this.label.trim();
  }
}
