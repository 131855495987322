/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { attr } from '@ember-data/model';
import HelpCenterBlock from './help-center-block';

export default class ContentBlock extends HelpCenterBlock {
  @attr('string') declare type: 'content-block';
  @attr('boolean') declare enabled: boolean;

  get title(): string {
    return this.intl.t('articles.settings.stylings.layout-and-sections.content-block.header');
  }

  preview(_locale: string) {
    if (!this.enabled) {
      return null;
    }

    return {
      type: this.type,
    };
  }
}
