/* RESPONSIBLE TEAM: team-purchase */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';

export type PlanLimit = {
  minimum?: number;
  maximum?: number;
};

export default class PricingStrategy extends Fragment {
  @attr('string') declare pricingMetric: string;
  @attr() declare planLimit: PlanLimit;
  get minimum() {
    return this.planLimit.minimum ?? 0;
  }
  get maximum() {
    return this.planLimit.maximum ?? Infinity;
  }
}
