/* RESPONSIBLE TEAM: team-knowledge-foundations */
import { type TreeParent, type TreeItem } from 'embercom/objects/tree-list';
import type Folder from './folder';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import { inject as service } from '@ember/service';
import { capitalize } from '@ember/string';
import { humanReadableObjectNames } from '../data/matching-system/matching-constants';
import type IntlService from 'ember-intl/services/intl';
import type RouterService from '@ember/routing/router-service';
import { type SafeString } from 'handlebars';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { objectNames } from 'embercom/models/data/matching-system/matching-constants';
import type { EntityType } from 'embercom/models/data/entity-types';
import { type Movable } from 'embercom/objects/content-service/folders/movable';

/**
 * Interface for items in the Knowledge Hub which can be placed in folders.
 * Note: this is NOT for Content items, please use KnowledgeHubContent for that.
 * If implementing this interface, you should probably also extend the KnowledgeHubItemMixin below
 * Documentation for this interface can be found in the KnowledgeHubItemMixin below
 */
export interface KnowledgeHubItem extends Movable {
  /** Get the parent folder of this item, in most cases you should probably call getParentMaybeFindAll instead to avoid duplicate network requests */
  get parent(): Folder | undefined;
  /** Set the parent folder of this item. This needs to call `onParentChange` */
  set parent(parent: Folder | undefined);
  /** Returns the parent id of the item. Can be used to peek record without making a network request. */
  get parentId(): string | undefined;
  /**
   * This checks that the folder models have been loaded before trying to access the parent.
   * If the folders have not been loaded, it requests them and waits for them to be loaded.
   */
  getParentMaybeFindAll(): Promise<Folder | undefined>;
  /** Return the title of the item to be displayed in the UI, or a placeholder if empty */
  get displayName(): string;
  /** Returns true if the item is at root level (i.e has no parent) */
  isRoot: boolean;
  /** Returns the depth of the item in the tree, index starts at 1 for root level. */
  depth: number;
  /** Returns true if the item has been moved to a new parent. This depends on the treeItem property being set. */
  hasMoved({ oldParent }: { oldParent: TreeParent }): boolean;
  /** Updates the treeItem to match the new parent. This is used to keep the tree & linked list in sync. This only needs to be implemented by objects that appear in the left nav. */
  updateLocationToMatch({ treeItem }: { treeItem: TreeItem<KnowledgeHubItem> }): void;
  /** Returns whether the item is editable in the UI. It controls drag & drop, menus. */
  get isEditable(): boolean;
  /**
   * Returns the name of the entity type, e.g. 'article' for EntityType.Article.
   * For the the human readable name, see humanReadableContentName.
   */
  get entityName(): string;
  /** Get the entity type of the item, this is used to display success modals, handle router transitions, etc */
  get entityType(): EntityType;
  /** Allows storing the tree item that is used to display this item in the nav bar / move modal / handle drag & drop */
  treeItem?: TreeItem<KnowledgeHubItem>;
  /** Returns true if there is a parent id and the folders are still being loaded. */
  get loadingParent(): boolean;
  /** Returns the success notification text for moving the item to the specified parent. */
  successNotificationText(parent: Folder | undefined): string | SafeString;
  router?: RouterService;
  intl?: IntlService;
}

type Constructable = new (...args: any[]) => object;

/** Provides some default implementation of the `KnowledgeHubItem` interface. */
export default function KnowledgeHubItemMixin<BC extends Constructable>(Base: BC) {
  abstract class KnowledgeHubItemMix extends Base implements KnowledgeHubItem {
    @service declare router: RouterService;
    @service declare intl: IntlService;
    @service declare knowledgeHubService: KnowledgeHubService;
    @service declare store: Store;
    @service declare notificationsService: $TSFixMe;

    declare treeItem: TreeItem<KnowledgeHubItem> | undefined;
    /** Stores the id of the object's parent folder, this allows for refresh if the parent is changed  */
    @tracked _parentId: string | undefined;

    abstract get parent(): Folder | undefined;
    abstract set parent(_parent: Folder | undefined);
    /**
     * Get the parent id from the relationship, this allows for us to check if the object has a parent folder without making a network request.
     * See implementation on the `InternalArticle` object for an example.
     */
    abstract get parentIdFromRelationship(): string | undefined;
    abstract get displayName(): string;
    abstract get entityType(): EntityType;
    abstract moveToFolderAndSave({
      folder,
      disableConfirmation,
    }: {
      folder?: Folder;
      disableConfirmation?: boolean;
    }): Promise<void>;

    async getParentMaybeFindAll(): Promise<Folder | undefined> {
      if (!this.parentId) {
        return undefined;
      }
      await this.knowledgeHubService.fetchFoldersOnce();
      this.parent = this.store.peekRecord('content-service/folder', this.parentId);
      return this.parent;
    }

    get isEditable(): boolean {
      return !this.parent?.isSynced ?? true;
    }

    get isRoot(): boolean {
      return this.parentId === undefined || this.parentId === null;
    }

    get depth(): number {
      if (this.isRoot) {
        return 1;
      } else {
        return this.parent!.depth + 1;
      }
    }

    get parentId(): string | undefined {
      if (this._parentId) {
        return this._parentId;
      }
      return this.parentIdFromRelationship;
    }

    get loadingParent(): boolean {
      if (this.parentId === undefined) {
        return false;
      }
      return this.knowledgeHubService.loadingFolders;
    }

    /** This should be called whenever the parent is changed. */
    onParentChange(parent?: Folder) {
      this._parentId = parent?.id;
    }

    /** Triggers a success notification after an object has been moved */
    notifyMoveConfirmation(parent = this.parent) {
      this.notificationsService.notifyConfirmation(this.successNotificationText(parent));
    }

    /** Triggers an error notification after an object has been moved */
    notifyMoveError() {
      this.notificationsService.notifyError(this.errorNotificationText());
    }

    successNotificationText(parent = this.parent): string | SafeString {
      if (!parent) {
        return this.moveToRootSuccessNotificationText();
      }
      try {
        return this.intl.t('knowledge-hub.folders.did-drop-item.success', {
          folderLink: parent.url,
          folderName: parent.displayName,
          entityType: this.entityType
            ? capitalize(humanReadableObjectNames[this.entityType])
            : this.intl.t('knowledge-hub.folders.did-drop-item.item'),
          htmlSafe: true,
        });
      } catch (e) {
        // Don't show an error for the folder move if there's an issue building the folder link
        return this.intl.t('knowledge-hub.folders.did-drop-item.root');
      }
    }

    /** Returns the success notification text for moving the item to the root. */
    moveToRootSuccessNotificationText(): string | SafeString {
      let link = this.router.urlFor('apps.app.knowledge-hub.all-content');
      return this.intl.t('knowledge-hub.folders.did-drop-item.root', {
        link,
        entityType: this.entityType
          ? capitalize(humanReadableObjectNames[this.entityType])
          : this.intl.t('knowledge-hub.folders.did-drop-item.item'),
        htmlSafe: true,
      });
    }

    /** Returns the error notification text if the move fails. */
    errorNotificationText(): string {
      return this.intl.t('knowledge-hub.folders.did-drop-item.error', {
        entityType: this.entityType
          ? capitalize(humanReadableObjectNames[this.entityType])
          : this.intl.t('knowledge-hub.folders.did-drop-item.item'),
      });
    }

    hasMoved({ oldParent }: { oldParent: TreeParent }) {
      let oldParentItem = oldParent as TreeItem<Folder>;
      return this.treeItem?.parent !== oldParentItem;
    }

    updateLocationToMatch({ treeItem }: { treeItem: TreeItem<KnowledgeHubItem> }): void {
      throw new Error(`This is only for folders to handle the tree & linked list. ${treeItem}`);
    }

    get entityName() {
      if (this.entityType) {
        return objectNames[this.entityType];
      }
      return '';
    }
  }
  return KnowledgeHubItemMix;
}
