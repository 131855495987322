/* RESPONSIBLE TEAM: team-knowledge-foundations */
/* eslint-disable @intercom/intercom/no-bare-strings */
export const SFT_ICONS = [
  'cpu-chip',
  'megaphone',
  'adjustments-horizontal',
  'tag',
  'cursor-arrow-rays',
  'microphone',
  'signal',
  'cog-8-tooth',
  'speaker-wave',
  'at-symbol',
  'wrench-screwdriver',
  'server',
  'cloud-arrow-up',
  'code-bracket-square',
  'bell',
  'funnel',
  'flag',
  'cube',
  'wifi',
  'squares-2x2',
  'cube-transparent',
  'eye-dropper',
  'identification',
  'wrench',
  'cloud-arrow-down',
  'swatch',
  'squares-plus',
  'square-3-stack-3d',
  'clock',
  'command-line',
  'eye',
  'square-2-stack',
  'map-pin',
  'magnifying-glass',
  'lock-closed',
  'finger-print',
  'window',
  'rectangle-stack',
  'calendar-days',
];

const developmentCommonKeywords = [
  'code',
  'bracket',
  'programming',
  'development',
  'software',
  'syntax',
  'square',
  'script',
  'coding',
];

export const SFT_KEYWORDS = {
  [SFT_ICONS[0]]: [
    'CPU',
    'chip',
    'processor',
    'technology',
    'hardware',
    'computer',
    'circuit',
    'electronics',
    'microprocessor',
  ],
  [SFT_ICONS[1]]: [
    'megaphone',
    'loudspeaker',
    'announcement',
    'amplification',
    'sound',
    'communication',
    'public address',
    'broadcast',
    'voice',
  ],
  [SFT_ICONS[2]]: [
    'adjustments',
    'settings',
    'controls',
    'configuration',
    'tuning',
    'customization',
    'alignment',
    'modification',
    'dj',
  ],
  [SFT_ICONS[3]]: [
    'tag',
    'label',
    'price',
    'identifier',
    'category',
    'shopping',
    'annotation',
    'marker',
    'product',
  ],
  [SFT_ICONS[4]]: [
    'cursor',
    'arrow',
    'rays',
    'pointer',
    'navigation',
    'mouse',
    'click',
    'interaction',
    'interface',
  ],
  [SFT_ICONS[5]]: [
    'microphone',
    'audio',
    'recording',
    'sound',
    'speech',
    'voice',
    'broadcast',
    'music',
    'device',
  ],
  [SFT_ICONS[6]]: [
    'signal',
    'reception',
    'network',
    'communication',
    'strength',
    'connection',
    'transmission',
    'waves',
    'frequency',
  ],
  [SFT_ICONS[7]]: [
    'cog',
    'gear',
    'settings',
    'mechanical',
    'adjustments',
    'engineering',
    'configuration',
    'system',
  ],
  [SFT_ICONS[8]]: [
    'speaker',
    'sound',
    'wave',
    'audio',
    'volume',
    'music',
    'amplification',
    'broadcast',
    'acoustics',
  ],
  [SFT_ICONS[9]]: [
    'at',
    'symbol',
    '@',
    'email',
    'address',
    'contact',
    'communication',
    'internet',
    'network',
    'identifier',
    'social media',
    'profile',
  ],
  [SFT_ICONS[10]]: [
    'wrench',
    'screwdriver',
    'tools',
    'repair',
    'maintenance',
    'fix',
    'adjustments',
    'mechanic',
    'hardware',
  ],
  [SFT_ICONS[11]]: [
    'server',
    'data',
    'network',
    'technology',
    'hosting',
    'computer',
    'database',
    'system',
    'infrastructure',
    'driver',
  ],
  [SFT_ICONS[12]]: [
    'cloud',
    'up',
    'upload',
    'data',
    'storage',
    'internet',
    'network',
    'technology',
    'sync',
    'send',
  ],
  [SFT_ICONS[13]]: [...developmentCommonKeywords],
  [SFT_ICONS[14]]: [
    'bell',
    'notification',
    'alert',
    'ring',
    'sound',
    'reminder',
    'alarm',
    'signal',
    'alert',
  ],
  [SFT_ICONS[15]]: [
    'funnel',
    'filter',
    'sorting',
    'data',
    'refinement',
    'selection',
    'narrowing',
    'processing',
    'analysis',
    'searching',
    'clean',
  ],
  [SFT_ICONS[16]]: [
    'flag',
    'signal',
    'marker',
    'country',
    'banner',
    'emblem',
    'notification',
    'indicator',
    'symbol',
    'feature',
  ],
  [SFT_ICONS[17]]: [
    ...developmentCommonKeywords,
    'cube',
    '3D',
    'object',
    'geometry',
    'box',
    'model',
    'shape',
    'design',
    'structure',
  ],
  [SFT_ICONS[18]]: [
    'wifi',
    'signal',
    'network',
    'connection',
    'internet',
    'wireless',
    'data',
    'communication',
    'access',
  ],
  [SFT_ICONS[19]]: [
    'squares',
    'grid',
    'layout',
    'blocks',
    'design',
    'pattern',
    'matrix',
    'organization',
  ],
  [SFT_ICONS[20]]: [
    ...developmentCommonKeywords,
    'cube',
    'transparent',
    '3D',
    'object',
    'geometry',
    'box',
    'model',
    'shape',
    'design',
  ],
  [SFT_ICONS[21]]: [
    'eye dropper',
    'color',
    'sampling',
    'palette',
    'design',
    'art',
    'tool',
    'pick',
    'selection',
    'copy',
  ],
  [SFT_ICONS[22]]: [
    'identification',
    'ID',
    'profile',
    'verification',
    'authentication',
    'personal',
    'document',
    'identity',
    'credentials',
  ],
  [SFT_ICONS[23]]: [
    'wrench',
    'tool',
    'repair',
    'maintenance',
    'adjustments',
    'fix',
    'mechanic',
    'hardware',
    'service',
  ],
  [SFT_ICONS[24]]: [
    'cloud',
    'download',
    'data',
    'storage',
    'internet',
    'network',
    'technology',
    'sync',
  ],
  [SFT_ICONS[25]]: [
    'swatch',
    'color',
    'palette',
    'design',
    'art',
    'samples',
    'selection',
    'patterns',
    'customization',
  ],
  [SFT_ICONS[26]]: [
    'squares',
    'plus',
    'grid',
    'layout',
    'blocks',
    'design',
    'pattern',
    'organization',
    'add',
    'continue',
    'edit',
  ],
  [SFT_ICONS[27]]: [
    'square',
    'blocks',
    'design',
    'layers',
    'stacked',
    'geometry',
    'structure',
    'paper',
    'copy',
    'duplicate',
  ],
  [SFT_ICONS[28]]: [
    'clock',
    'time',
    'watch',
    'hours',
    'minutes',
    'schedule',
    'alarm',
    'timekeeping',
    'measurement',
  ],
  [SFT_ICONS[29]]: [
    ...developmentCommonKeywords,
    'command line',
    'terminal',
    'script',
    'interface',
  ],
  [SFT_ICONS[30]]: [
    'eye',
    'vision',
    'sight',
    'view',
    'observation',
    'focus',
    'monitoring',
    'perception',
    'eyesight',
  ],
  [SFT_ICONS[31]]: [
    'square',
    'windows',
    'blocks',
    'design',
    'layers',
    'stacked',
    'geometry',
    'structure',
    'organization',
    'duplicate',
    'copy',
  ],
  [SFT_ICONS[32]]: [
    'pin',
    'location',
    'marker',
    'navigation',
    'geography',
    'address',
    'destination',
    'pointer',
    'map',
    'google',
  ],
  [SFT_ICONS[33]]: [
    'magnifying glass',
    'search',
    'zoom',
    'exploration',
    'investigation',
    'analysis',
    'focus',
    'discovery',
    'tool',
  ],
  [SFT_ICONS[34]]: [
    'lock',
    'closed',
    'security',
    'privacy',
    'protection',
    'authentication',
    'safe',
    'access',
    'password',
    'quarentine',
  ],
  [SFT_ICONS[35]]: [
    'fingerprint',
    'biometrics',
    'security',
    'authentication',
    'identity',
    'verification',
    'personal',
    'recognition',
    'privacy',
  ],
  [SFT_ICONS[36]]: [
    'window',
    'view',
    'opening',
    'pane',
    'glass',
    'interface',
    'screen',
    'viewpoint',
    'frame',
    'browser',
  ],
  [SFT_ICONS[37]]: [
    'rectangle',
    'stack',
    'layers',
    'design',
    'geometry',
    'blocks',
    'structure',
    'organization',
    'arrangement',
  ],
  [SFT_ICONS[38]]: [
    'calendar',
    'days',
    'schedule',
    'dates',
    'planner',
    'events',
    'time',
    'organization',
    'months',
    'appointment',
    'meeting',
  ],
};
