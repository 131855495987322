/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { attr } from '@ember-data/model';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { not } from '@ember/object/computed';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';
import { buildValidations, validator } from 'ember-cp-validations';
import Fragment from 'ember-data-model-fragments/fragment';

const Validations = buildValidations({
  title: [
    validator('presence', {
      presence: true,
      messageKey:
        'articles.settings.stylings.layout-and-sections.content-block.validation-errors.missing-title',
    }),
  ],
  buttonTitle: [
    validator('presence', {
      presence: true,
      messageKey:
        'articles.settings.stylings.layout-and-sections.content-block.validation-errors.missing-button-title',
      disabled: not('model.withButton'),
    }),
  ],
  buttonUrl: [
    validator('format', {
      regex:
        /^(https?:\/\/)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/,
      messageKey:
        'articles.settings.stylings.layout-and-sections.content-block.validation-errors.invalid-url',
      disabled: not('model.withButton'),
    }),
  ],
});
export default class LocalizedContentBlock extends Fragment.extend(Validations) {
  @attr('string') declare locale: string;
  @attr('string') declare title: string;
  @attr('boolean') declare withButton: boolean;
  @attr('string') declare description: string;
  @attr('string') declare buttonTitle: string;
  @attr('string') declare buttonUrl: string;

  @computed('title', 'withButton', 'buttonTitle', 'buttonUrl')
  get dirtyAttributes() {
    return Object.keys(this.changedAttributes());
  }
}
