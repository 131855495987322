/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Model, { attr, belongsTo } from '@ember-data/model';
import type Folder from './folder';
import { type EntityType } from 'embercom/models/data/entity-types';

export default class FolderMembership extends Model {
  @attr('number') declare entityId: number;
  @attr('number') declare _entityType: number;

  @belongsTo('content-service/folder', { async: false })
  declare parentFolder?: Folder;

  get entityType(): EntityType {
    return this._entityType;
  }

  set entityType(value: EntityType) {
    this._entityType = value;
  }

  get parentId(): string | undefined {
    return this.parentIdFromRelationship;
  }

  get parentIdFromRelationship(): string | undefined {
    // @ts-ignore - TS doesn't like the 'folder' key here for some reason
    let folderRelationship = this.belongsTo('parentFolder');
    return folderRelationship?.id();
  }

  async moveToFolderAndSave({ folder }: { folder?: Folder }): Promise<void> {
    if (folder) {
      this.parentFolder = folder;
      this.save();
    } else {
      this.destroyRecord();
      this.parentFolder = undefined;
    }
  }
}
