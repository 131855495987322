/* RESPONSIBLE TEAM: team-messenger */

import Model, { attr } from '@ember-data/model';
import TrustedListUrlValidator from 'embercom/lib/trusted-list-url-validator';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

export const MILLISECONDS = {
  minute: 60000,
  hour: 3600000,
  day: 86400000,
  week: 604800000,
} as const;

export const MIN_DURATION = MILLISECONDS.hour; // 1 hour
export const MAX_DURATION = 400 * MILLISECONDS.day; // 400 days
export const DEFAULT_DURATION = 7 * MILLISECONDS.day; // 7 days

export default class Security extends Model {
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;

  get app() {
    return this.appService.app;
  }

  @attr('array') declare parsedAllowedDomains: string[];
  @attr('number') declare sessionDuration: number | null;
  @attr('boolean') declare hideNotificationContentHippaCompliance: boolean;

  get hasUnsavedChanges() {
    return this.hasDirtyAttributes;
  }

  get allowedDomains() {
    return this.parsedAllowedDomains.join(',');
  }

  get domainValidator() {
    return TrustedListUrlValidator.create({ urlString: this.allowedDomains });
  }

  get isSessionDurationValid() {
    if (this.sessionDuration === null || this.sessionDuration === undefined) {
      return true;
    }
    return this.sessionDuration >= MIN_DURATION && this.sessionDuration <= MAX_DURATION;
  }

  get sessionDurationValidationMessage(): string | null {
    if (this.sessionDuration === null || this.sessionDuration === undefined) {
      return null;
    }
    if (this.sessionDuration > MAX_DURATION) {
      return this.intl.t('messenger.security-settings.session-duration.max-duration');
    }
    if (this.sessionDuration < MIN_DURATION) {
      return this.intl.t('messenger.security-settings.session-duration.min-duration');
    }
    return null;
  }

  beforeSave() {
    // @ts-expect-error TypeScript thinks this is a function because of hasDirtyAttributes being a computed property.
    if (this.hasUnsavedChanges) {
      this.updateParsedAllowedDomains();
    }
  }

  updateParsedAllowedDomains() {
    this.parsedAllowedDomains = this.domainValidator.domainHostNames;
  }

  refresh() {
    this.rollbackAttributes();
  }
}
