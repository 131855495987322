/* RESPONSIBLE TEAM: team-purchase */

export interface IntershopProductFeatureModal {
  heading: string;
  description?: string;
  mediaUrl: string;
}

export interface IntershopProductFeature {
  feature: string;
  includedInPlans: Array<string>;
  modal?: IntershopProductFeatureModal;
  tooltip?: string;
  icon?: string;
  comingSoon?: string;
}

export interface IntershopProductMetricPlan {
  id: string;
  includes?: string;
}

export interface IntershopProductMetric {
  name: string;
  type?: 'seats';
  includedInPlans: Array<IntershopProductMetricPlan>;
}

export interface IntershopProductFeatureSection {
  header?: string;
  features: Array<IntershopProductFeature>;
}

export interface IntershopProduct {
  usageButtonLabel?: string;
  billingSummaryLinkIcon?: string;
  billingSummaryLinkText?: string;
  metrics: Array<IntershopProductMetric>;
  featureSections: Array<IntershopProductFeatureSection>;
}

export interface IntershopProductPageData {
  heading: string;
  description?: {
    content: string;
  };
}

export interface IntershopProductCardData {
  productImage: string;
  productNameOverride?: string;
  shortDescription: string;
  features?: Array<{ icon: string; name: string }>;
  complementaryDescription?: string;
  brandColor?: string;
}

export default {};
