/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
const ONE_TO_ONE = 'one_to_one';
const ONE_TO_MANY = 'one_to_many';
const MANY_TO_ONE = 'many_to_one';
const MANY_TO_MANY = 'many_to_many';
const CARDINALITY_NAME_MAP = {
  [ONE_TO_ONE]: '1 : 1',
  [ONE_TO_MANY]: '1 : Many',
  [MANY_TO_ONE]: 'Many : 1',
  [MANY_TO_MANY]: 'Many : Many',
};
const REVERSE_CARDINALITY_NAME_MAP = {
  [ONE_TO_ONE]: ONE_TO_ONE,
  [ONE_TO_MANY]: MANY_TO_ONE,
  [MANY_TO_ONE]: ONE_TO_MANY,
  [MANY_TO_MANY]: MANY_TO_MANY,
};

export {
  ONE_TO_ONE,
  ONE_TO_MANY,
  MANY_TO_ONE,
  MANY_TO_MANY,
  CARDINALITY_NAME_MAP,
  REVERSE_CARDINALITY_NAME_MAP,
};
