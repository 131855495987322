/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Model from '@ember-data/model';
import { dependentKeyCompat } from '@ember/object/compat';
import {
  type EmberCPValidationsCompat,
  type EmberCPValidationsResult,
} from 'embercom/validations/typescript/ember-cp-validations-compat';
import { getOwner } from '@ember/application';
import aiContentSegmentEditConfigGenerator from 'embercom/objects/content-editor/configuration-generators/ai-content-segment';
import { attr } from '@ember-data/model';
import { fragment } from 'ember-data-model-fragments/attributes';
import { validateSegment } from 'embercom/validations/typescript/ai-content-segments/segment';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

export default class AiContentSegment extends Model implements EmberCPValidationsCompat {
  @attr('string') declare name: string;
  @attr('number') declare rulesetId: number;
  @attr('date') declare createdAt: Date;

  @service declare intl: IntlService;

  @fragment('predicates/predicate-group') declare predicateGroup: any;

  @dependentKeyCompat
  get validations(): EmberCPValidationsResult {
    return validateSegment(this);
  }

  get nameWithFallback() {
    return this.name || this.intl.t('ai-content-library.segments.untitled');
  }

  editorConfig() {
    let container = getOwner(this);
    return aiContentSegmentEditConfigGenerator({ container });
  }
}
