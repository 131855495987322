/* eslint-disable @intercom/intercom/no-bare-strings */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { attr } from '@ember-data/model';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import Fragment from 'ember-data-model-fragments/fragment';
import { type MediaHelper } from 'embercom/objects/media/media-helper';

import { parse } from 'opentype.js';
import type FontFace from './font-face';

export const DEFAULT_FONT = 'System Default';

export const AVAILABLE_GOOGLE_FONTS = [
  {
    fontFamily: DEFAULT_FONT,
  },
  {
    fontFamily: 'Bitter',
  },
  {
    fontFamily: 'Inter',
  },
  {
    fontFamily: 'Lato',
  },
  {
    fontFamily: 'Lora',
  },
  {
    fontFamily: 'Montserrat',
  },
  {
    fontFamily: 'Noto Sans',
  },
  {
    fontFamily: 'Noto Serif',
  },
  {
    fontFamily: 'Open Sans',
  },
  {
    fontFamily: 'Oswald',
  },
  {
    fontFamily: 'Playfair Display',
  },
  {
    fontFamily: 'Poppins',
  },
  {
    fontFamily: 'Raleway',
  },
  {
    fontFamily: 'Roboto',
  },
  {
    fontFamily: 'Roboto Mono',
  },
  {
    fontFamily: 'Roboto Slab',
  },
];
class FontFileParser {
  fontFamily = '';
  fontSubfamily = '';
  file: File;

  constructor(file: File) {
    this.file = file;
  }

  async parse() {
    return new Promise<void>((resolve) => {
      let reader = new FileReader();
      reader.onload = (event) => {
        try {
          let font = parse(event.target?.result as ArrayBuffer);
          this.fontFamily = font?.names?.fontFamily?.en || this.file.name;
          this.fontSubfamily = font?.names?.fontSubfamily?.en;
        } catch (error) {
          this.fontFamily = this.file.name;
          this.fontSubfamily = '';
        } finally {
          resolve();
        }
      };
      reader.readAsArrayBuffer(this.file);
    });
  }
}
export type FontFamilyOption = {
  fontFamily: string;
  url?: string;
};
export default class Font extends Fragment {
  @attr('string') declare primary: string;
  @attr('string') declare secondary: string;

  @fragmentArray('customization-options/global-options/font-face')
  declare customFontFaces: FontFace[];

  preview() {
    return {
      primary: this.primary,
      secondary: this.secondary,
      customFontFaces: this.customFontFaces.map((face) => face.preview()),
    };
  }

  async addFontFace(mediaHelper: MediaHelper) {
    let file: File = mediaHelper.file as unknown as File;
    let fileParser = new FontFileParser(file);
    await fileParser.parse();

    let fontFace = this.store.createFragment('customization-options/global-options/font-face', {
      fontFamily: fileParser.fontFamily,
      fileName: fileParser.file.name,
    }) as FontFace;
    fontFace.mediaHelper = mediaHelper;
    let searcheableText = `${fileParser.fontFamily} ${fileParser.fontSubfamily} ${fileParser.file.name}`;
    fontFace.matchWeight(searcheableText);
    fontFace.matchStyle(searcheableText);
    this.customFontFaces.pushObject(fontFace);
  }

  removeFontFace(fontFace: FontFace) {
    let removingFamily = fontFace.fontFamily;
    this.customFontFaces.removeObject(fontFace);
    let fontFamilyExists = this.customFontFaces.find(
      (fontFace) => fontFace.fontFamily === removingFamily,
    );
    if (!fontFamilyExists) {
      if (this.primary === removingFamily) {
        this.primary = DEFAULT_FONT;
      }
      if (this.secondary === removingFamily) {
        this.secondary = DEFAULT_FONT;
      }
    }
  }

  ensureSelectedValuesUpdated(changingFontFace: FontFace, fontFamily: string) {
    let fontFacesForFamily = this.fontFacesByFontFamily[changingFontFace.fontFamily];
    if (fontFacesForFamily.length > 1) {
      return;
    }
    if (this.primary === changingFontFace.fontFamily) {
      this.primary = fontFamily;
    }
    if (this.secondary === changingFontFace.fontFamily) {
      this.secondary = fontFamily;
    }
  }

  get fontFaceMediaHelpers() {
    return this.customFontFaces
      .map((fontFace) => fontFace.mediaHelper)
      .filter((mediaHelper) => mediaHelper);
  }

  get fontFamilyOptions(): FontFamilyOption[] {
    return [...AVAILABLE_GOOGLE_FONTS, ...this.customFontFamilyOptions];
  }

  private get fontFacesByFontFamily() {
    return this.customFontFaces.reduce(
      (fontFacesByFontFamily, fontFace) => {
        let fontFamily = fontFace.fontFamily;
        if (!fontFacesByFontFamily[fontFamily]) {
          fontFacesByFontFamily[fontFamily] = [];
        }
        fontFacesByFontFamily[fontFamily].push(fontFace);
        return fontFacesByFontFamily;
      },
      {} as Record<string, FontFace[]>,
    );
  }

  get customFontFamilyOptions() {
    return Object.keys(this.fontFacesByFontFamily).map((fontFamily) => ({ fontFamily }));
  }
}
