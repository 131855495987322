/* RESPONSIBLE TEAM: team-knowledge-foundations */
import { ARROW_SYMBOLS_ICONS, ARROW_SYMBOLS_KEYWORDS } from './arrow-symbols-icons-data';
import { BIZZ_FIN_ICONS, BIZZ_FIN_KEYWORDS } from './bizz-fin-icons-data';
import { CHARTS_ICONS, CHARTS_KEYWORDS } from './charts-icons-data';
import { COMMUNICATION_ICONS, COMMUNICATION_KEYWORDS } from './communication-icons-data';
import { CONTENT_ICONS, CONTENT_KEYWORDS } from './content-icons-data';
import { FF_ICONS, FF_KEYWORDS } from './ff-icons-data';
import { NATURE_ICONS, NATURE_KEYWORDS } from './nature-icons-data';
import { OTHER_ICONS, OTHER_KEYWORDS } from './other-icons-data';
import { OTHER_DEVICES_ICONS, OTHER_DEVICES_KEYWORDS } from './other-devices-icons-data';
import { PEOPLE_CHAT_GETS_ICONS, PEOPLE_CHAT_GETS_KEYWORDS } from './people-chat-gets-icons-data';
import { SFT_ICONS, SFT_KEYWORDS } from './sft-icons-data';

export const contentWrapperTypes = {
  article: 3,
};

export const collectionIcons = {
  ARROW_SYMBOLS_ICONS,
  BIZZ_FIN_ICONS,
  CHARTS_ICONS,
  COMMUNICATION_ICONS,
  CONTENT_ICONS,
  FF_ICONS,
  NATURE_ICONS,
  OTHER_ICONS,
  OTHER_DEVICES_ICONS,
  PEOPLE_CHAT_GETS_ICONS,
  SFT_ICONS,
};

// this is done to avoid needing to get the translation for all keywords all the time
let translationCache = {};

export const collectionIconsKeywords = {
  // ARROW_SYMBOLS_ICONS: arr-sym
  ...ARROW_SYMBOLS_KEYWORDS,

  // BIZZ_FIN_ICONS: bizz-fin
  ...BIZZ_FIN_KEYWORDS,

  // CHARTS_ICONS : charts
  ...CHARTS_KEYWORDS,

  // COMMUNICATION_ICONS : communication
  ...COMMUNICATION_KEYWORDS,

  // CONTENT_ICONS : content
  ...CONTENT_KEYWORDS,

  // FF_ICONS : ff
  ...FF_KEYWORDS,

  // NATURE_ICONS : nature
  ...NATURE_KEYWORDS,

  // OTHER_ICONS : other
  ...OTHER_KEYWORDS,

  // OTHER_DEVICES_ICONS : other-devices
  ...OTHER_DEVICES_KEYWORDS,

  // PEOPLE_CHAT_GETS_ICONS : people-chat-gets
  ...PEOPLE_CHAT_GETS_KEYWORDS,

  // SFT_ICONS : sft
  ...SFT_KEYWORDS,

  doesTermMatchIconKeywords: (iconName, term, translationService) => {
    // in terms with multi words, all words must match with the icon
    return term.split(' ').every((termSubWord) =>
      collectionIconsKeywords[iconName]?.any((keyword) => {
        if (!translationCache[keyword]) {
          let translatedKeyword = translationService
            ? translationService.t(`articles.collections-icons.keywords.${keyword}`)
            : keyword;
          translationCache[keyword] = translatedKeyword;
        }

        return translationCache[keyword].includes(termSubWord);
      }),
    );
  },
};
