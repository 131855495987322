/* RESPONSIBLE TEAM: team-knowledge-foundations */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';

export default class ImportSourceContext extends Fragment {
  @attr('boolean') declare unauthorized: boolean;
  // Guru context
  @attr('string') declare collectionId?: string;
  @attr('string') declare collectionName?: string;

  // Confluence context
  @attr('string') declare siteId: string;
  @attr('string') declare spaceId: string;
  @attr('string') declare spaceName: string;

  // Notion context
  @attr('string') declare workspaceName: string;

  // box context
  @attr('array') declare file_types: string[];
  @attr('array') declare selectedItems: string[];

  //general context
  @attr('array') declare metadata: any;
  @attr('array') declare entityMetadata: any;

  // Document360 context
  @attr('string') declare hostingRegion: string;
  @attr('string') declare projectId: string;
  @attr('array') declare languageCodes: string[];
  @attr('string') declare projectName: string;
}
