/* RESPONSIBLE TEAM: team-reporting */
import Fragment from 'ember-data-model-fragments/fragment';
import { attr } from '@ember-data/model';
import { formatRangeRoundedTime } from 'embercom/lib/duration-formatter';
import { SECONDS_IN } from 'embercom/models/reporting/custom/chart';
import { cached } from 'tracked-toolbox';
import { tracked } from '@glimmer/tracking';
import { isPresent } from '@ember/utils';

export type Label = 's' | 'm' | 'h' | 'd';
export interface BucketRange {
  from: number;
  to?: number;
}

export type UnitInterval = { value: number; unit: Label | null };
export const INFINITY = '∞';

export class RangeInterval {
  @tracked isDeleted: boolean;
  @tracked fromUnitValue: UnitInterval;
  @tracked toUnitValue: UnitInterval;

  constructor(range: BucketRange) {
    let to = range.to || Infinity;
    this.isDeleted = false;
    this.fromUnitValue = this.convertToUnitInterval(range.from);
    this.toUnitValue = this.convertToUnitInterval(to);
  }

  static ofFromUnit(from: UnitInterval) {
    let interval = new RangeInterval({ from: from.value });
    interval.fromUnitValue = from;
    return interval;
  }

  get from() {
    return this.convertToSeconds(this.fromUnitValue);
  }

  get to() {
    return this.convertToSeconds(this.toUnitValue);
  }

  updateUnitInterval(from: UnitInterval, to: UnitInterval) {
    this.fromUnitValue = from;
    this.toUnitValue = to;
  }

  private convertToSeconds(unitInterval: UnitInterval) {
    switch (unitInterval.unit) {
      case 's':
        return unitInterval.value;
      case 'm':
        return unitInterval.value * SECONDS_IN.minute;
      case 'h':
        return unitInterval.value * SECONDS_IN.hour;
      case 'd':
        return unitInterval.value * SECONDS_IN.day;
      default:
        return unitInterval.value;
    }
  }

  private convertToUnitInterval(value: number): UnitInterval {
    if (Number.isFinite(value)) {
      let splitValue: string[] = formatRangeRoundedTime(value).split('');
      let indexOfNumberEnd = splitValue.findIndex((char) => isNaN(Number(char)));

      let unit = splitValue.slice(indexOfNumberEnd).join('');
      let formattedValue = splitValue.slice(0, indexOfNumberEnd).join('');
      return { value: Number(formattedValue), unit: unit as Label };
    } else {
      return {
        value: Infinity,
        unit: null,
      };
    }
  }

  get fromLabel() {
    if (this.from > 0) {
      return `>${formatRangeRoundedTime(this.from)}`;
    } else {
      return `<${formatRangeRoundedTime(this.to)}`;
    }
  }

  get toLabel() {
    if (Number.isFinite(this.to)) {
      return `<${formatRangeRoundedTime(this.to)}`;
    } else {
      return `>${formatRangeRoundedTime(this.from)}`;
    }
  }

  delete() {
    this.isDeleted = true;
  }

  toRange(): BucketRange {
    if (Number.isFinite(this.toUnitValue.value)) {
      return { from: this.from, to: this.to };
    } else {
      return { from: this.from };
    }
  }

  static toRangeKey(range: BucketRange) {
    if (isPresent(range.to) && isPresent(range.from)) {
      return `${range.from}.0-${range.to}.0`;
    } else if (isPresent(range.to)) {
      return `*-${range.to}.0`;
    } else {
      return `${range.from}.0-*`;
    }
  }
}

export default class BucketInterval extends Fragment {
  @attr('array', {
    defaultValue: () => {
      return [];
    },
  })
  declare range: BucketRange[];

  @cached
  get intervals(): RangeInterval[] {
    return this.range.map((range) => new RangeInterval(range));
  }
}
