/* RESPONSIBLE TEAM: team-knowledge-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import Model, { attr, hasMany, belongsTo } from '@ember-data/model';
import { isEqual } from '@ember/utils';
import { computed } from '@ember/object';
import { sort, readOnly, equal, not, notEmpty, or } from '@ember/object/computed';
import ajax from 'embercom/lib/ajax';
import { ternary } from '@intercom/pulse/lib/computed-properties';
import promise from 'embercom/lib/promise';

export default Model.extend({
  app_id: attr('string'),
  name: attr('string'),
  description: attr('string'),
  icon: attr('string', { defaultValue: 'book-bookmark' }),
  order: attr('number'),
  count: attr('number'),

  children: hasMany('article-folder', { inverse: 'parent' }),
  parent: belongsTo('article-folder', { inverse: 'children' }),

  articles: hasMany({ inverse: 'folder', async: true }),
  sortedArticles: sort('articles', 'sortOrder'),
  sortedChildren: sort('children', 'sortOrder'),
  sortedSections: readOnly('sortedChildren'),
  sortedSiblings: readOnly('parent.sortedChildren'),
  sortOrder: ['order'],

  isHome: equal('id', 'home'),
  isNotHome: not('isHome'),
  hasChildren: notEmpty('children'),
  hasArticles: notEmpty('articles'),
  hasContent: or('hasChildren', 'hasArticles'),
  shouldFocusName: false,

  allArticles: computed('articles', 'children.[]', function () {
    let allArticles = this.articles.toArray();
    this.children.forEach((section) => {
      allArticles = allArticles.concat(section.get('articles').toArray());
    });
    return allArticles;
  }),

  isFirstSection: computed('parent.sortedSections.[]', function () {
    return isEqual(this.get('sortedSiblings.firstObject.id'), this.id);
  }),

  isLastSection: computed('parent.sortedSections.[]', function () {
    return isEqual(this.get('sortedSiblings.lastObject.id'), this.id);
  }),

  isCollection: equal('parent.id', 'home'),
  isSection: not('isCollection'),
  type: ternary('isCollection', 'collection', 'section'),

  reorder(newIndex) {
    return ajax({
      type: 'POST',
      url: `/ember/article_folders/${this.id}/reorder`,
      data: JSON.stringify({ order: newIndex, app_id: this.app_id }),
    });
  },

  saveIfDirty() {
    if (this.hasDirtyAttributes && !this.isSaving) {
      return this.save();
    } else {
      return promise();
    }
  },
}).reopenClass({
  createNewFolder(parent, prefix = 'Section heading') {
    let newFolderName = `${prefix}`;
    let existingFolders = parent.get('children');
    let nameInUse = false;
    let nextIndex = 1;

    do {
      nameInUse = existingFolders.findBy('name', newFolderName);
      if (nameInUse) {
        newFolderName = `${prefix} (${nextIndex++})`;
      }
    } while (nameInUse);

    let maxOrder = Math.max(...existingFolders.map((f) => f.get('order')));
    let folder = parent.store.createRecord('article-folder', {
      name: newFolderName,
      order: maxOrder + 1,
      shouldFocusName: true,
    });
    folder.set('parent', parent);
    return folder;
  },
});
