/* RESPONSIBLE TEAM: team-standalone */
import Step from 'embercom/models/operator/visual-builder/step';
import type Store from '@ember-data/store';
import { SUPPORTED_CHANNELS } from 'embercom/lib/operator/custom-bots/constants-2';
import { TargetContext } from 'embercom/objects/operator/configuration/custom-bot';

export default class HandoffToSalesforceInAppMessaging extends Step {
  get supportedChannels() {
    return SUPPORTED_CHANNELS.handoffToSalesforceInAppMessaging;
  }

  static get supportedContexts() {
    return [TargetContext.Conversation];
  }

  static get isStepGroupEnding() {
    return true;
  }

  static get isStepTerminal() {
    return true;
  }

  static createNewStep(store: Store): HandoffToSalesforceInAppMessaging {
    return store.createRecord(
      'operator/visual-builder/step/handoff-to-salesforce-in-app-messaging',
      {
        type: 'operator/visual-builder/step/handoff-to-salesforce-in-app-messaging',
      },
    );
  }
}
