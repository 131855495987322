/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Model, { attr } from '@ember-data/model';
import { dependentKeyCompat } from '@ember/object/compat';
import { inject as service } from '@ember/service';
import { getApp, getEmberDataStore } from 'embercom/lib/container-lookup';
import { keys, isFunction, pick } from 'underscore';
import { getUnallowedPermissionsForSeats } from 'embercom/lib/seats-permissions-map';
import { isEqual } from 'underscore';
import { COPILOT_PERMISSION } from 'embercom/lib/settings/copilot-access-editor/constants';

// The current version of ED we use does not seem to export the AttrOptions type individually. (3.16)
// We need to grab it from the global export.
// eslint-disable-next-line
import DS from 'ember-data';

const permission_with_options = ({
  shouldSerialize,
}: {
  shouldSerialize: boolean | (() => boolean);
}) =>
  attr('boolean', { defaultValue: false, shouldSerialize } as unknown as DS.AttrOptions<boolean>);
const readonlyPermission = permission_with_options({ shouldSerialize: false });
const permission = permission_with_options({ shouldSerialize: true });

function app() {
  // getApp can fail in tests that haven't mocked the appService, so we fall back to the store
  return getApp() || getEmberDataStore().peekAll('app').firstObject;
}

export type PermissibleLabel = keyof ModelWithPermissions & `can_${string}`;

export const PERMISSIONS_EXCLUDED_FROM_TOGGLED_PERMISSIONS = [COPILOT_PERMISSION];

export default class ModelWithPermissions extends Model {
  @service declare appService: any;
  @service declare customerService: any;
  @service declare permissionsHierarchyService: any;

  @permission declare can_export_data: boolean;
  @permission declare can_send_messages: boolean;
  @permission declare can_redact_conversation_parts: boolean;
  @permission declare can_reassign_conversations: boolean;
  @permission declare can_access_billing_settings: boolean;
  @permission declare can_access_product_settings: boolean;
  @permission declare can_access_workspace_settings: boolean;
  @permission declare can_create_and_edit_bots: boolean;
  @permission declare can_manage_articles: boolean;
  @permission declare can_manage_messenger_settings: boolean;
  @permission declare can_manage_messages_settings: boolean;
  @permission declare can_access_developer_hub: boolean;
  @permission declare can_manage_apps_and_integrations: boolean;
  @permission declare can_manage_teammates: boolean;
  @permission declare can_manage_inbox_views: boolean;
  @permission declare can_manage_saved_replies: boolean;
  @permission declare can_manage_tags: boolean;
  @permission declare can_manage_inbox_rules: boolean;
  @permission declare can_access_reporting: boolean;
  @permission declare can_manage_workload_management: boolean;
  @permission declare can_manage_round_robin_assignment: boolean;
  @permission declare can_manage_outbound_custom_bots: boolean;
  @permission declare can_send_from_custom_addresses: boolean;
  @permission declare can_manage_product_tours: boolean;
  @permission declare can_export_outbound_data: boolean;
  @permission declare can_access_contacts: boolean;
  @permission declare can_access_user_profiles: boolean;
  @permission declare can_manage_surveys: boolean;
  @permission declare can_bulk_import_contacts: boolean;
  @permission declare can_settings__default_sender__manage: boolean;
  @permission declare can_export_csv: boolean;
  @permission declare can_teammates__away_mode__change: boolean;
  @permission declare can_inbox__teammate_presence__manage: boolean;
  @permission declare can_reporting__drillin__access: boolean;
  @permission declare can_manage_knowledge_base_content: boolean;
  @permission declare can_reporting__custom_reports__read: boolean;
  @permission declare can_reporting__custom_reports__change: boolean;
  @permission declare can_reporting__custom_reports__delete: boolean;
  @permission declare can_calling__listening_and_barging: boolean;
  @permission declare can_reporting__navigation_folders__manage: boolean;
  @permission declare can_outbound__news__publish: boolean;
  @permission declare can_articles__saved_views__manage: boolean;
  @permission declare can_use_personal_macros: boolean;
  @permission declare can_outbound__surveys_data__manage: boolean;
  @permission declare can_remove_conversation_slas: boolean;

  @permission_with_options({ shouldSerialize: () => app().canManageTeamsGranularly })
  declare can_manage_teams: boolean;

  @permission_with_options({ shouldSerialize: () => app().canUseTeammateStatusAutoAwayMode })
  declare can_manage_auto_away_mode: boolean;
  @permission_with_options({ shouldSerialize: () => app().hasRealtimeMonitoring })
  declare can_access_real_time_dashboard: boolean;
  // can_reply_to_inbound_conversations is not present on PermissionEditor
  // readonlyPermission ensures that it is never serialized to the backend
  // the code on the backend defults to this permission to true on the db.
  // and calculate effective permissions flow ensures that this permission is
  // serialized as false for VBP2.0 teammates without support seat
  // for everyone else it should be true
  // for more info see: https://github.com/intercom/intercom/pull/212127
  @readonlyPermission declare can_reply_to_inbound_conversations: boolean;
  @permission_with_options({
    shouldSerialize: () => app().canSeeInboxConversationTranscriptsExport,
  })
  declare can_inbox__conversation_transcripts__export: boolean;
  @permission_with_options({ shouldSerialize: () => app().canSeeReportsSharePermission })
  declare can_reporting__reports__share: boolean;
  @permission_with_options({ shouldSerialize: () => app().canShareReportsInternally })
  declare can_reporting__reports__update: boolean;
  @permission_with_options({ shouldSerialize: () => app().canShareReportsInternally })
  declare can_reporting__reports__delete: boolean;
  @permission_with_options({ shouldSerialize: () => app().canUseDraftArticlePermission })
  declare can_create_and_edit_draft_articles: boolean;
  @permission_with_options({ shouldSerialize: () => true })
  declare can_inbox__access_copilot: boolean;
  // Override in child class
  get seatTypes(): Array<string> {
    return [];
  }

  @dependentKeyCompat
  get hasFullAccess() {
    let permissionsKeys = ModelWithPermissions.editablePermissionKeys;

    if (app().hasMultipleSeatTypes) {
      if (isEqual(this.seatTypes, [])) {
        return false;
      }

      let unallowedPermissions = getUnallowedPermissionsForSeats(
        this.customerService.customer.availableSeatTypes,
        this.permissionsHierarchyService.seatPermissionMap,
      );

      return permissionsKeys.every(
        (permissionKey) =>
          unallowedPermissions.includes(permissionKey) ||
          this[permissionKey] === true ||
          PERMISSIONS_EXCLUDED_FROM_TOGGLED_PERMISSIONS.includes(permissionKey),
      );
    } else {
      return permissionsKeys.every(
        (permissionKey) =>
          this[permissionKey] === true ||
          PERMISSIONS_EXCLUDED_FROM_TOGGLED_PERMISSIONS.includes(permissionKey),
      );
    }
  }

  setAllPermissions(value: boolean) {
    ModelWithPermissions.editablePermissionKeys.forEach((attr) => (this[attr] = value));
  }

  setPermissions(permissions: Record<string, boolean>) {
    ModelWithPermissions.editablePermissionKeys.forEach((attr) => (this[attr] = permissions[attr]));
  }

  permissions() {
    return this.getProperties(ModelWithPermissions.editablePermissionKeys);
  }

  numberOfPermissionsChanged() {
    let permissionKeys = ModelWithPermissions.editablePermissionKeys as Array<string>;
    let changedKeys = Object.keys(this.changedAttributes()) as unknown as Array<string>;
    return changedKeys.filter((key) => permissionKeys.includes(key)).length;
  }

  static get editablePermissionKeys(): Array<PermissibleLabel> {
    let attributesHash = Object.fromEntries(
      ModelWithPermissions.attributes as unknown as Iterable<readonly any[]>,
    );
    // Only permissions that are serialized are editable
    let keyList = keys(
      pick(attributesHash, (attribute) => {
        if (!attribute.name.startsWith('can_')) {
          return false;
        }
        return isFunction(attribute.options.shouldSerialize)
          ? attribute.options.shouldSerialize()
          : attribute.options.shouldSerialize;
      }),
    );
    return keyList as unknown as Array<PermissibleLabel>;
  }
}
