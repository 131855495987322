/* RESPONSIBLE TEAM: team-knowledge-foundations */

import Fragment from 'ember-data-model-fragments/fragment';
import { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

export default abstract class HelpCenterBlock extends Fragment {
  @attr('string') declare type: string;
  @attr('number') declare columns?: 1 | 2 | 3;
  @service declare intl: IntlService;

  setColumns(columns: number) {
    if (columns < 1 || columns > 3) {
      return;
    }

    this.columns = columns as 1 | 2 | 3;
  }

  get disableable(): boolean {
    return true;
  }

  get editable(): boolean {
    return !this.disableable || this.enabled;
  }

  abstract get enabled(): boolean;
  abstract get title(): string;
  abstract preview(locale: string): any;
}
