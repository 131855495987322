/* RESPONSIBLE TEAM: team-knowledge-foundations */
/* eslint-disable @intercom/intercom/no-bare-strings */
export const ARROW_SYMBOLS_ICONS = [
  'arrow-up-circle',
  'play-circle',
  'arrows-up-down',
  'arrow-path',
  'arrows-right-left',
  'arrow-down-tray',
  'check-circle',
  'arrow-trending-up',
  'information-circle',
  'question-mark-circle',
  'arrow-trending-down',
  'arrow-down-circle',
  'shopping-cart',
];

const downloadCommonKeywords = [
  'download',
  'save',
  'file',
  'data-transfer',
  'downward',
  'cloud',
  'receive',
  'action',
];

export const ARROW_SYMBOLS_KEYWORDS = {
  [ARROW_SYMBOLS_ICONS[0]]: ['increase', 'send', 'chevron', 'upload', 'lift', 'circle'],
  [ARROW_SYMBOLS_ICONS[1]]: ['play', 'triangle', 'media', 'start', 'playback', 'watch', 'circle'],
  [ARROW_SYMBOLS_ICONS[2]]: ['exchange', 'trade', 'up-down', 'reverse'],
  [ARROW_SYMBOLS_ICONS[3]]: [
    'refresh',
    'sync',
    'reload',
    'reset',
    'rotate',
    'update',
    'cycle',
    'repeat',
  ],
  [ARROW_SYMBOLS_ICONS[4]]: [
    'back',
    'forward',
    'left-right',
    'swap',
    'reverse',
    'bidirectional',
    'direction',
    'exchange',
    'trade',
  ],
  [ARROW_SYMBOLS_ICONS[5]]: [...downloadCommonKeywords, 'box', 'inside'],
  [ARROW_SYMBOLS_ICONS[6]]: [
    'checkmark',
    'success',
    'verified',
    'tick',
    'confirmation',
    'complete',
    'approval',
    'correct',
    'done',
    'circle',
  ],
  [ARROW_SYMBOLS_ICONS[7]]: [
    'growth',
    'upward',
    'graph',
    'increase',
    'rising',
    'trend',
    'chart',
    'progress',
  ],
  [ARROW_SYMBOLS_ICONS[8]]: [
    'notification',
    'reminder',
    'information',
    'question',
    'alert',
    'help',
    'circle',
  ],
  [ARROW_SYMBOLS_ICONS[9]]: ['question', 'information', 'inquiry', 'help', 'circle'],
  [ARROW_SYMBOLS_ICONS[10]]: [
    'downward',
    'decline',
    'negative',
    'falling',
    'decrease',
    'downward',
    'graph',
    'falling',
    'chart',
    'trend',
  ],
  [ARROW_SYMBOLS_ICONS[11]]: [...downloadCommonKeywords, 'circle'],
  [ARROW_SYMBOLS_ICONS[12]]: [
    'shopping',
    'cart',
    'basket',
    'ecommerce',
    'store',
    'buy',
    'retail',
    'market',
    'purchase',
    'super',
  ],
};
