/* RESPONSIBLE TEAM: team-knowledge-foundations */

import Fragment from 'ember-data-model-fragments/fragment';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import { attr } from '@ember-data/model';
import type ArticleLink from './article-link';
import type ArticleSummaryService from 'embercom/services/article-summary-service';
import { inject as service } from '@ember/service';
import type HelpCenterSite from 'embercom/models/help-center-site';

const MAX_ARTICLE_SLOTS_COUNT = 12;
const INITIAL_ARTICLE_SLOTS_COUNT = 6;
const ARTICLE_SLOT_ID = 'empty_article_slot';

export default class LocalizedArticleListContent extends Fragment {
  @attr('string') declare locale: string;
  @attr('string') declare title: string;
  @service declare articleSummaryService: ArticleSummaryService;

  @fragmentArray('customization-options/layout-options/home-page/article-link')
  declare links: ArticleLink[];

  get isArticleSlotReachedLimit() {
    return this.links.length >= MAX_ARTICLE_SLOTS_COUNT;
  }

  createDefaultLinks() {
    if (this.links.length > 0) {
      return;
    }

    for (let i = 0; i < INITIAL_ARTICLE_SLOTS_COUNT; i++) {
      this.addArticleLinkSlot();
    }
  }

  private createDefaultArticleLink() {
    return this.store.createFragment(
      'customization-options/layout-options/home-page/article-link',
      {
        type: 'article-link',
        article_id: ARTICLE_SLOT_ID,
      },
    );
  }

  hasLink(articleId: string) {
    return this.links.any((link) => link.article_id === articleId);
  }

  get articleLinkIds() {
    let links = this.links && !this.articleSummaryService.isLoading ? this.links : [];
    return links.map((link) => {
      return this.articleSummaryService.isAvailable(link.article_id, this.locale)
        ? link.article_id
        : undefined;
    });
  }

  /**
   * Ensures that the article summaries are available for the article links.
   * @param site Help Center site to fetch article summaries for.
   */
  refreshAvailableArticleLinks(site: HelpCenterSite) {
    if (this.links.length > 0) {
      this.articleSummaryService.fetchAllArticleSummaries(site);
    }
  }

  get availableArticleLinks() {
    let links = this.links && !this.articleSummaryService.isLoading ? this.links : [];
    return links
      .filter((link) => this.articleSummaryService.isAvailable(link.article_id, this.locale))
      .map((link) => ({
        article_id: link.article_id,
        title: this.articleSummaryService.articleForLocale(link.article_id, this.locale)?.title,
      }));
  }

  get hasRestrictedArticleLinks() {
    if (!this.links || this.articleSummaryService.isLoading) {
      return false;
    }
    return this.links.any((link) =>
      this.articleSummaryService.isArticleRestricted(link.article_id),
    );
  }

  addArticleLinkSlot() {
    if (this.isArticleSlotReachedLimit) {
      return;
    }

    this.links.pushObject(this.createDefaultArticleLink());
  }

  removeArticleLinkSlot(slotIndex: number) {
    this.links.removeAt(slotIndex);
  }

  setArticleId(slotIndex: number, articleId: string) {
    let article = this.links.objectAt(slotIndex);
    if (!article) {
      return;
    }

    article.article_id = articleId;
  }

  reorderArticleLinks(reorderedArticleIds: string[]) {
    let indexes: Record<string, number> = {};
    reorderedArticleIds.forEach((articleId, index) => {
      indexes[articleId] = index;
    });

    let orderedArticleLinks: ArticleLink[] = Array.from(
      { length: reorderedArticleIds.length },
      () => this.createDefaultArticleLink(),
    );

    this.links.forEach((link) => {
      if (link.article_id !== ARTICLE_SLOT_ID) {
        let newIndex = indexes[link.article_id];
        orderedArticleLinks[newIndex] = link;
      }
    });

    this.links.clear();
    this.links.pushObjects(orderedArticleLinks);
  }
}
