/* RESPONSIBLE TEAM: team-knowledge-foundations */

import Fragment from 'ember-data-model-fragments/fragment';
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';
import type OutboundContent from './outbound-content';
import type CollectionIdToOutboundContent from './collection-id-to-outbound-content';
import type CollectionCardGlobalOptions from './collection-card-global-options';
//@ts-ignore no type declaration available for ember-copy
import { copy } from 'ember-copy';

export enum CollectionCardImageType {
  Icons = 'icons',
  Images = 'images',
  None = 'none',
}

export const DEFAULT_GLOBAL_OPTIONS = {
  backgroundColor: '#F5F5F5',
};
export default class CollectionCardOptions extends Fragment {
  @fragment('customization-options/collection-card-global-options')
  declare global: CollectionCardGlobalOptions;
  @fragmentArray('customization-options/collection-id-to-outbound-content')
  declare collections: Array<CollectionIdToOutboundContent>;

  setDefaultValues() {
    if (this.global) {
      if (this.global.showIcons === null || this.global.showIcons === undefined) {
        this.global.showIcons = true;
      }

      this.global.imageType = this.defaultCollectionCardImageType();
    }
  }

  defaultCollectionCardImageType(): CollectionCardImageType {
    if (this.global.showIcons) {
      return CollectionCardImageType.Icons;
    }

    let allCollections: OutboundContent[] = [this.global];
    this.collections.forEach((collection) => {
      allCollections.push(collection.outboundContent);
    });

    if (allCollections.every((collection) => !collection.isBackgroundsEnabled)) {
      return CollectionCardImageType.None;
    }

    return CollectionCardImageType.Images;
  }

  initCollectionBackground(collectionOptions?: OutboundContent) {
    this.global.selectColor(
      collectionOptions?.backgroundColor ??
        collectionOptions?.lastSelectedBackgroundColor ??
        DEFAULT_GLOBAL_OPTIONS.backgroundColor,
    );
    this.global.duplicateGradient(collectionOptions?.backgroundGradient ?? null);
    this.global.backgroundImageUrl = collectionOptions?.backgroundImageUrl ?? null;
  }

  initFromSnapshot(snapshot: CollectionCardOptions, useDefaultBackroundColor = false) {
    let copyContent = (target: OutboundContent, source?: any) => {
      let defaultBackgroundColor = useDefaultBackroundColor
        ? DEFAULT_GLOBAL_OPTIONS.backgroundColor
        : null;
      target.selectColor(source?.background_color ?? defaultBackgroundColor);

      target.duplicateGradient(source?.background_gradient ?? null);
      target.backgroundImageUrl = source?.background_image_url ?? null;
      target.fontColor = source?.font_color ?? this.global.fontColor;
    };

    if (!snapshot) {
      copyContent(this.global);
      return;
    }

    let { collections, global } = snapshot;
    copyContent(this.global, global);

    collections.forEach((collectionToCopy: any) => {
      let collection = this.getOrCreateCollection(collectionToCopy.collection_id);
      copyContent(collection.outboundContent, collectionToCopy.outbound_content);
    });
  }

  deleteMissingCollectionFromSnapshot(snapshot: CollectionCardOptions) {
    if (!snapshot) {
      return;
    }

    let collectionSnapshot = snapshot?.collections ?? [];
    let collectionIds = new Set<string>(
      collectionSnapshot.map((collectionToCopy: any) => collectionToCopy.collection_id.toString()),
    );

    let objectsToRemove: CollectionIdToOutboundContent[] = [];
    this.collections.forEach((collection) => {
      if (!collectionIds.has(collection.collectionId.toString())) {
        objectsToRemove.push(collection);
      }
    });
    this.collections.removeObjects(objectsToRemove);
  }

  getCollection(collectionId: number): CollectionIdToOutboundContent | undefined {
    return this.collections.findBy('collectionId', collectionId);
  }

  getOrCreateCollection(collectionId: number): CollectionIdToOutboundContent {
    let collection = this.getCollection(collectionId);
    // Individual Collection Style already exists
    if (collection) {
      return collection;
    }

    // Create new Individual Collection Style
    this.collections.pushObject(
      this.store.createFragment('customization-options/collection-id-to-outbound-content', {
        collectionId,
        outboundContent: this.store.createFragment('customization-options/outbound-content', {
          fontColor: this.global.fontColor,
          backgroundColor: this.global.backgroundColor,
          backgroundGradient: copy(this.global.backgroundGradient),
          backgroundImageUrl: this.global.backgroundImageUrl,
        }),
      }),
    );

    return this.getCollection(collectionId)!;
  }
}
