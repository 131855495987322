/* RESPONSIBLE TEAM: team-knowledge-foundations */

import Model, { attr, belongsTo } from '@ember-data/model';
import { inject as service } from '@ember/service';
import ajax from 'embercom/lib/ajax';
import {
  CAN_CREATE_AND_EDIT_DRAFT_ARTICLES_PERMISSION,
  CAN_MANAGE_ARTICLES_PERMISSION,
} from 'embercom/lib/articles/constants';
import type IntlService from 'ember-intl/services/intl';
import { type Block } from '@intercom/interblocks.ts';
import buildArticleCard from 'embercom/objects/articles/article-card-builder';

export const MAX_SUMMARY_SIZE = 140;
export const MAX_TITLE_SIZE = 255;
export const MAX_PREVIEW_SIZE = 45;

export default class Article extends Model {
  @service appService: any;
  @service intl!: IntlService;

  @attr('string') declare title: string;
  @attr('string') declare publicUrl: string;
  @attr('string') declare summary: string;
  @attr() declare jsonBlocks: Array<Block>;
  @attr() declare jsonBlocksForEditing: Array<Block>;
  @attr('string') declare authorId: string;
  @attr('number') declare order: number;
  @attr('string', { defaultValue: 'draft' }) declare state: 'published' | 'draft';
  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: Date;
  @attr('number') declare missingTableErrorCount: number;
  @attr('number') declare missingListErrorCount: number;
  @attr('number') declare missingImageErrorCount: number;
  @attr('number') declare missingVideoErrorCount: number;
  @attr('number') declare missingContentErrorTotalCount: number;
  @attr('string') declare urlImportedFrom: string;
  @attr('boolean') declare visibleForUser: boolean;
  @attr('string') declare targetUserType: string;

  @belongsTo('article-folder', { inverse: 'articles' }) folder: any;

  get app() {
    return this.appService.app;
  }

  get hasMissingContent() {
    return this.missingContentErrorTotalCount > 0;
  }

  get hasRestrictedAudience() {
    return this.targetUserType !== 'everyone';
  }

  get collection() {
    if (this.folder.get('isHome') || this.folder.get('parent.isHome')) {
      return this.folder;
    }

    if (this.folder.get('parent.isCollection')) {
      return this.folder.get('parent');
    }

    return this.folder.get('parent.parent');
  }

  get section() {
    if (this.folder.isCollection) {
      return null;
    }

    if (this.folder.parent.isCollection) {
      return this.folder;
    }
    return this.folder.parent;
  }

  get subSection() {
    if (this.folder.isCollection) {
      return null;
    }

    if (this.folder.parent.isCollection) {
      return null;
    }
    return this.folder;
  }

  get public() {
    return !this.folder.isHome;
  }

  get isPublished() {
    return this.state === 'published';
  }

  get isDraft() {
    return this.state === 'draft';
  }

  get isNotPublished() {
    return !this.isPublished;
  }

  get titleForDisplay() {
    if (this.title) {
      return this.title;
    }

    return this.intl.t('articles.new-article');
  }

  get hasSummary() {
    return !!this.summary;
  }

  get notFullyLoaded() {
    return !!this.jsonBlocks;
  }

  get fullyLoaded() {
    return !!this.notFullyLoaded;
  }

  get author() {
    return this.app.humanAdmins.findBy('id', this.authorId);
  }

  set author(admin) {
    this.authorId = admin.id;
  }

  get requiredEditPermission() {
    return CAN_CREATE_AND_EDIT_DRAFT_ARTICLES_PERMISSION;
  }

  get requiredDeletePermission() {
    if (this.isDraft) {
      return CAN_CREATE_AND_EDIT_DRAFT_ARTICLES_PERMISSION;
    }

    return CAN_MANAGE_ARTICLES_PERMISSION;
  }

  get articleCard() {
    return buildArticleCard(this);
  }

  get analyticsData() {
    return {
      object: 'article',
      article_id: this.id,
      url: this.publicUrl,
      is_public: this.public,
    };
  }

  reorder(newIndex: string) {
    return ajax({
      type: 'POST',
      url: `/ember/articles/${this.id}/reorder`,
      data: JSON.stringify({ order: newIndex, app_id: this.app.id }),
    });
  }

  createMessengerCard(locale: string, conversationId: string) {
    return ajax({
      url: `/ember/articles/${this.id}/messenger_card`,
      data: {
        locale,
        app_id: this.app.id,
        conversation_id: conversationId,
      },
    });
  }

  static async updateArticleCountsForApp(app: any) {
    let counts = await ajax({
      url: '/ember/articles/counts',
      type: 'GET',
      data: {
        app_id: app.id,
        admin_id: app.currentAdmin.id,
      },
    });

    app.setProperties({
      allArticlesCount: counts.all,
      finishedArticlesCount: counts.finished,
      draftArticlesCount: counts.draft,
    });

    return counts;
  }
}
