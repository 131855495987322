/* RESPONSIBLE TEAM: team-frontend-tech */
import Model from '@ember-data/model';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import { post } from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';
import { copy } from 'ember-copy';
import ApplyableMacroAction from 'embercom/lib/macros/applyable-macro-action';
import { SNOOZED_UNTIL_MAP } from 'embercom/lib/inbox/constants';
import Admin from 'embercom/models/admin';
import moment from 'moment-timezone';
import { tracked } from '@glimmer/tracking';

const EXECUTION_ORDER = [
  'reply-to-conversation',
  'assign-conversation',
  'change-conversation-priority',
  'set-conversation-data-attribute',
  'add-tag-to-conversation',
  'snooze-conversation',
  'close-conversation',
];

export default class ComposerMacro extends Model {
  @service appService;
  @service conversationsService;
  @service permissionsService;
  @service store;
  @service conversationCloseService;
  @fragmentArray('macro/macro-action') actions;

  @tracked conversationIsDraft = false;

  get actionsPayload() {
    return this.actions.map(({ type, actionData }) => {
      return { type, actionData };
    });
  }

  get actionTypes() {
    return this.actions.map((action) => action.type);
  }

  get macroActionsToApply() {
    return this.actionsAndApplyable
      .filter((actionAndApplyable) => actionAndApplyable.applyable)
      .map((actionAndApplyable) => actionAndApplyable.action)
      .sort(function (a, b) {
        return EXECUTION_ORDER.indexOf(a.type) - EXECUTION_ORDER.indexOf(b.type);
      });
  }

  get actionsAndApplyable() {
    let uniquenessKeys = this.actions.map((action) => action.uniquenessKey);

    return this.actions.map((action, i) => {
      let applyable = i === uniquenessKeys.indexOf(action.uniquenessKey);

      return new ApplyableMacroAction(action, applyable);
    });
  }

  get hasCloseOrSnoozeAction() {
    return (
      this.actionTypes.includes('close-conversation') ||
      this.actionTypes.includes('snooze-conversation')
    );
  }

  get hasCloseAction() {
    return this.actionTypes.includes('close-conversation');
  }

  get hasAnyActions() {
    return this.actions.length > 0;
  }

  createActionsFragments(actionsData) {
    actionsData.map((action) => {
      this.actions.createFragment(copy(action));
    });
  }

  _createPartsLocallyAndAddUuidToPayload(conversation) {
    let createParts = this.macroActionsToApply.map(async ({ type, actionData }) => {
      let localPart;
      switch (type) {
        case 'close-conversation':
          localPart = this._createLocalClosePart(conversation);
          break;
        case 'snooze-conversation':
          localPart = this._createLocalSnoozePart(conversation, actionData);
          break;
        case 'assign-conversation':
          localPart = this._createLocalAssignmentPart(conversation, actionData);
          break;
        case 'change-conversation-priority':
          localPart = this._createLocalPriorityPart(conversation, actionData);
          break;
        case 'add-tag-to-conversation':
          localPart = this._addTagToInitialPart(conversation, actionData.tag_id);
          break;
        case 'reply-to-conversation':
          actionData.json_blocks = await this._transformBlocks(conversation, actionData);
          localPart = this._createLocalReplyPart(conversation, actionData);
          break;
        case 'set-conversation-data-attribute':
          this._createLocalConversationDataAttribute(conversation, actionData);
      }
      if (localPart) {
        actionData.client_assigned_uuid = localPart.client_assigned_uuid;
      }
      return { type, action_data: actionData };
    });
    return Promise.all(createParts);
  }

  _createLocalClosePart(conversation) {
    let localPart = this.conversationsService.addPart(conversation, 'close', []);
    return localPart;
  }

  _createLocalSnoozePart(conversation, actionData) {
    transformSnoozeUntilHoursToCustomTimer(actionData);

    let snoozedUntil =
      actionData.snoozed_until === 'custom_timer'
        ? actionData.snoozed_until
        : SNOOZED_UNTIL_MAP.findIndex((x) => x.key === actionData.snoozed_until);
    let localPart = this.conversationsService.addEventPart(conversation, 'snoozed', {
      snoozedUntil,
      customSnoozedUntil: actionData.custom_snoozed_until,
    });
    return localPart;
  }

  _createLocalAssignmentPart(conversation, actionData) {
    let assignment = this._assignmentFromAdminOrTeamId(conversation, actionData.assignee_id);
    let localPart = this.conversationsService.addPart(
      conversation,
      'assignment',
      [],
      null,
      assignment,
    );
    return localPart;
  }

  _assignmentFromAdminOrTeamId(conversation, adminOrTeamId) {
    let assignee = Admin.peekAndMaybeLoad(this.store, adminOrTeamId);

    if (assignee.is_team) {
      return {
        adminId: conversation.admin_assignee_id,
        teamId: assignee.id,
      };
    } else {
      return {
        teamId: conversation.team_assignee_id,
        adminId: assignee.id,
      };
    }
  }

  _createLocalPriorityPart(conversation, actionData) {
    let localPart = this.conversationsService.addEventPart(conversation, 'priority_changed', {
      priority: actionData.priority,
    });
    return localPart;
  }

  _createLocalConversationDataAttribute(conversation, { attribute_identifier, value }) {
    if (attribute_identifier && attribute_identifier.startsWith('conversation.custom_attribute')) {
      let descriptorId = attribute_identifier.split('.')[2];
      let attributeValueId = `${conversation.id}-${descriptorId}`;
      let attribute = conversation.conversationAttributes.find(({ id }) => id === attributeValueId);
      if (attribute) {
        attribute.value = value;
      }
    }
  }

  _addTagToFirstUserPart(conversation, tag_id) {
    let currentTags = conversation.firstUserPart?.taggings?.map((tagging) =>
      tagging.belongsTo('tag').id(),
    );
    if (currentTags && !currentTags.includes(tag_id)) {
      let tag = this.store.peekRecord('tag', tag_id);
      let admin = this.appService.app.currentAdmin;
      let newTagging = this.store.createRecord('tagging', {
        tag,
        admin,
        tagged_at: moment(),
      });
      conversation.firstUserPart.taggings.pushObject(newTagging);
    }
  }

  _addTagToInitialPart(conversation, tag_id) {
    let currentTags = conversation.initialPart?.taggings?.map((tagging) =>
      tagging.belongsTo('tag').id(),
    );
    if (currentTags && !currentTags.includes(tag_id)) {
      let tag = this.store.peekRecord('tag', tag_id);
      let admin = this.appService.app.currentAdmin;
      let newTagging = this.store.createRecord('tagging', {
        tag,
        admin,
        tagged_at: moment(),
      });
      conversation.initialPart.taggings.pushObject(newTagging);
    }
  }

  _createLocalReplyPart(conversation, actionData) {
    let localPart = this.conversationsService.addPart(
      conversation,
      'comment',
      actionData.json_blocks,
      [],
    );
    return localPart;
  }

  async _transformBlocks(conversation, actionData) {
    let response = await post(`/ember/conversations/transform_blocks`, {
      json_blocks: actionData.json_blocks,
      id: conversation.id,
      app_id: this.appService.app.id,
    });
    return response.json_blocks;
  }

  async applyToConversation(conversation) {
    let shouldCloseConversationWithService = false;
    if (this.hasCloseAction && conversation.hasRequiredAttributesWithEmptyValue) {
      this.removeCloseAction();
      shouldCloseConversationWithService = true;
    }
    let actions = await this._createPartsLocallyAndAddUuidToPayload(conversation);
    await post(`/ember/conversations/execute_actions`, {
      actions,
      id: conversation.id,
      app_id: this.appService.app.id,
    });
    if (shouldCloseConversationWithService) {
      this.conversationCloseService.closeConversation(conversation);
    }
  }

  clearFromConversation() {
    this.actions = [];
  }

  addCloseAction() {
    this.actions.createFragment({
      type: 'close-conversation',
      actionData: {},
    });
  }

  addSnoozeAction(snoozeOptions) {
    let snoozed_until =
      snoozeOptions.snoozedUntil === 'custom_timer'
        ? snoozeOptions.snoozedUntil
        : SNOOZED_UNTIL_MAP[snoozeOptions.snoozedUntil].key;

    this.actions.createFragment({
      type: 'snooze-conversation',
      actionData: {
        snoozed_until,
        custom_snoozed_until: snoozeOptions.customSnoozedUntil,
        author_timezone: moment.tz.guess(),
      },
    });
  }

  addReplyAction(blocks) {
    this.actions.createFragment({
      type: 'reply-to-conversation',
      actionData: {
        json_blocks: blocks,
      },
    });
  }

  removeCloseAction() {
    this.actions = this.actions.filter(({ type }) => type !== 'close-conversation');
  }
}
function transformSnoozeUntilHoursToCustomTimer(actionData) {
  if (actionData.snoozed_until === 'hours') {
    actionData.snoozed_until = 'custom_timer';
    actionData.custom_snoozed_until = moment()
      .add(actionData.selected_hours, 'hours')
      .toISOString();
    // selected_hours is NOT part of the existing contract for /ember/conversations/execute_actions
    // we're deleting it to adhere to it.
    // this property is used only for transporting data within components in the front-end
    delete actionData.selected_hours;
  }
}
