/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import { type MediaHelper } from 'embercom/objects/media/media-helper';

import type IntlService from 'embercom/services/intl';
import { inject as service } from '@ember/service';

export type FontFaceStyle = 'normal' | 'italic' | 'oblique';
type Option = {
  text: string;
  value: string | number | null;
  isDisabled?: boolean;
  searchKey: string;
};

export default class FontFace extends Fragment {
  declare mediaHelper: MediaHelper | null;
  @attr('string') declare fontFamily: string;
  @attr('string') declare fileName: string;
  @attr('number') declare weight: number;
  @attr('string') declare style: FontFaceStyle;
  @attr('number') declare width: number;

  @attr('string') declare assetId: string;
  @attr('string') declare url: string;

  @service declare intl: IntlService;

  get assetUrl() {
    return this.url || this.mediaHelper?.url;
  }

  preview() {
    return {
      fontFamily: this.fontFamily,
      fileName: this.fileName,
      weight: this.weight,
      style: this.style,
      width: this.width,
      url: this.assetUrl,
    };
  }

  fontFacesForSelectedFamily(fontFaces: FontFace[]) {
    return fontFaces.filter(
      (fontFace) => fontFace.fontFamily === this.fontFamily && fontFace !== this,
    );
  }

  existsInList(fontFaces: FontFace[]) {
    let sameFontFace = fontFaces.find(
      (fontFace) =>
        fontFace !== this &&
        fontFace.fontFamily === this.fontFamily &&
        fontFace.weight === this.weight &&
        fontFace.style === this.style,
    );
    return !!sameFontFace;
  }

  private matchStringToOption(
    options: Option[],
    fontFaceText: string,
    defaultValue: string | number | null,
  ) {
    let matchingOption = options.find((option) =>
      fontFaceText.toLowerCase().includes(option.searchKey.toLowerCase()),
    );
    return matchingOption?.value || defaultValue;
  }

  matchWeight(fontFaceText: string) {
    this.weight = this.matchStringToOption(this.allWeights, fontFaceText, null) as number;
  }

  matchStyle(fontFaceText: string) {
    this.style = this.matchStringToOption(this.allStyles, fontFaceText, 'normal') as FontFaceStyle;
  }

  get displayName() {
    let displayValues: [string] = [this.fontFamily];
    if (this.weight || this.weight === null) {
      displayValues.push(this.weightDisplayName as string);
    }
    if (this.style) {
      displayValues.push(this.styleDisplayName as string);
    }
    return displayValues.join(', ');
  }

  get weightDisplayName() {
    return this.allWeights.find((weight) => weight.value === this.weight)?.text;
  }

  get styleDisplayName() {
    return this.allStyles.find((style) => style.value === this.style)?.text;
  }

  get allWeights(): Option[] {
    return [
      {
        text: this.intl.t(
          'articles.settings.redesign.content.font.custom-fonts.font-face.weight.values.variable',
        ),
        searchKey: 'variable',
        value: null,
      },
      {
        text: this.intl.t(
          'articles.settings.redesign.content.font.custom-fonts.font-face.weight.values.thin',
        ),
        searchKey: 'thin',
        value: 100,
      },
      {
        text: this.intl.t(
          'articles.settings.redesign.content.font.custom-fonts.font-face.weight.values.extralight',
        ),
        searchKey: 'extralight',
        value: 200,
      },
      {
        text: this.intl.t(
          'articles.settings.redesign.content.font.custom-fonts.font-face.weight.values.light',
        ),
        searchKey: 'light',
        value: 300,
      },
      {
        text: this.intl.t(
          'articles.settings.redesign.content.font.custom-fonts.font-face.weight.values.regular',
        ),
        searchKey: 'regular',
        value: 400,
      },
      {
        text: this.intl.t(
          'articles.settings.redesign.content.font.custom-fonts.font-face.weight.values.medium',
        ),
        searchKey: 'medium',
        value: 500,
      },
      {
        text: this.intl.t(
          'articles.settings.redesign.content.font.custom-fonts.font-face.weight.values.semibold',
        ),
        searchKey: 'semibold',
        value: 600,
      },
      {
        text: this.intl.t(
          'articles.settings.redesign.content.font.custom-fonts.font-face.weight.values.bold',
        ),
        searchKey: 'bold',
        value: 700,
      },
      {
        text: this.intl.t(
          'articles.settings.redesign.content.font.custom-fonts.font-face.weight.values.extrabold',
        ),
        searchKey: 'extrabold',
        value: 800,
      },
      {
        text: this.intl.t(
          'articles.settings.redesign.content.font.custom-fonts.font-face.weight.values.black',
        ),
        searchKey: 'black',
        value: 900,
      },
    ];
  }

  get allStyles(): Option[] {
    return [
      {
        text: this.intl.t(
          'articles.settings.redesign.content.font.custom-fonts.font-face.style.values.normal',
        ),
        searchKey: 'normal',
        value: 'normal',
      },
      {
        text: this.intl.t(
          'articles.settings.redesign.content.font.custom-fonts.font-face.style.values.italic',
        ),
        searchKey: 'italic',
        value: 'italic',
      },
    ];
  }
}
