/* RESPONSIBLE TEAM: team-tickets-1 */
import Model, { attr, belongsTo } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { patchRequest, request } from 'embercom/lib/inbox/requests';
import { validator, buildValidations } from 'ember-cp-validations';

let Validations = buildValidations({
  label: [
    validator('presence', true),
    validator('length', {
      max: 64,
    }),
    validator('away-status-reason-label-unique'),
  ],
  emoji: validator('presence', true),
});

export default class AwayStatusReason extends Model.extend(Validations) {
  @service appService: any;

  @attr('string') declare label: string;
  @attr('number') declare order: number;
  @attr('string') declare emoji: string;
  @attr('boolean') declare deleted: boolean;
  @attr('string') declare associatedToPhoneStatus: string | undefined;

  @belongsTo('away-status-reason', { inverse: null }) declare duplicateOf: AwayStatusReason;

  toggleArchived(targetState: boolean) {
    let action = targetState ? 'archive' : 'unarchive';
    return patchRequest(`/ember/away_status_reasons/${this.id}/${action}`, {
      app_id: this.appService.app.id,
    });
  }

  reorder(order: number) {
    return patchRequest(`/ember/away_status_reasons/${this.id}/reorder`, {
      app_id: this.appService.app.id,
      order,
    });
  }

  dependentObjects() {
    return request(
      `/ember/away_status_reasons/${this.id}/dependent_objects?app_id=${this.appService.app.id}`,
    );
  }
}
