/* RESPONSIBLE TEAM: team-workflows */
import Step, { IsCustomerFacing } from 'embercom/models/operator/visual-builder/step';
import { belongsTo, attr, type SyncHasMany } from '@ember-data/model';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import { inject as service } from '@ember/service';

import { buildValidations, validator } from 'ember-cp-validations';
import { chatMessageStepBlockValidators } from 'embercom/models/operator/visual-builder/step/chat-message';
import type Store from '@ember-data/store';

/* eslint-disable ember/no-computed-properties-in-native-classes */
import { computed } from '@ember/object';
import { type Block } from 'embercom/models/common/blocks/block';
import type ConnectionPoint from '../connection-point';
import type InsertableAction from 'embercom/models/workflow-connector/insertable-action';
import { tracked } from '@glimmer/tracking';
import { TargetContext } from 'embercom/objects/operator/configuration/custom-bot';
import type ActionInputParameterMapping from 'embercom/models/workflow-connector/action-input-parameter-mapping';
import { SUPPORTED_CHANNELS } from 'embercom/lib/operator/custom-bots/constants-2';
import { extractTemplateVariables } from 'embercom/lib/workflows/template-variables';
import { LOCAL_VARIABLE_IDENTIFIER_PREFIX } from 'embercom/models/operator/visual-builder/attribute-descriptor';

const Validations = buildValidations({
  outwardConnectionPoints: [validator('has-many')],
  action: [validator('presence', true)],
  actionInputParameterMappings: [validator('workflow-step-input-paramater-mapping')],
  ...chatMessageStepBlockValidators,
});

export default class WorkflowConnector extends Step.extend(Validations) {
  @belongsTo('workflow-connector/insertable-action', { async: false })
  declare action?: InsertableAction;
  @fragmentArray('common/blocks/block', { polymorphic: true, typeKey: 'modelKey' })
  declare blocks: SyncHasMany<Block>;
  @service router: any;
  @attr('string') declare originalActionId?: string;
  @fragmentArray('workflow-connector/action-input-parameter-mapping')
  declare actionInputParameterMappings: SyncHasMany<ActionInputParameterMapping>;

  @attr('string') declare actionOutputAttributeDescriptorIdentifier: string;

  @tracked showDrawer = !!this.group.workflow.ruleset?.isNewRuleset;
  @tracked showInputMappingDrawer = false;

  get supportedChannels() {
    return SUPPORTED_CHANNELS.workflowConnector;
  }

  @computed('actionInputParameterMappings.@each.templatedValue')
  get localVariableIdentifiersUsed() {
    return this.actionInputParameterMappings
      .map((actionInputParameterMapping) => {
        let templateVariables = extractTemplateVariables([
          { type: 'paragraph', text: actionInputParameterMapping.templatedValue },
        ]);
        return templateVariables.filter((variable) =>
          variable.startsWith(LOCAL_VARIABLE_IDENTIFIER_PREFIX),
        );
      })
      .flat();
  }

  static get supportedContexts() {
    return [
      TargetContext.Conversation,
      TargetContext.CustomerTicket,
      TargetContext.BackOfficeTicket,
      TargetContext.TrackerTicket,
    ];
  }

  static get requiredBillingFeature() {
    return 'workflows_core_billing_feature';
  }

  get serializedBlocks() {
    // We only need to serialize the blocks if they are a FragmentArray (in tests they are already serialized)
    if (this.blocks.type === 'common/blocks/block') {
      return this.blocks.serialize();
    } else {
      return this.blocks;
    }
  }

  static get canBeCustomerFacing(): IsCustomerFacing {
    return IsCustomerFacing.Maybe;
  }

  get isCustomerFacing() {
    return this.action?.otpAuthEnabled ?? false;
  }

  @computed(
    'hasDirtyAttributes',
    'hasActionChanged',
    'outwardConnectionPoints.@each.hasUnsavedChanges',
  )
  get hasUnsavedChanges() {
    return (
      this.hasDirtyAttributes ||
      this.hasActionChanged ||
      this.outwardConnectionPoints.isAny('hasUnsavedChanges')
    );
  }

  @computed('originalActionId', 'action.id')
  get hasActionChanged() {
    return this.originalActionId !== this.action?.id;
  }

  static createNewStep(
    store: Store,
    params: { customAction: InsertableAction; listTemplates: boolean },
  ): WorkflowConnector {
    let outwardConnectionPoints = [
      store.createRecord('operator/visual-builder/connection-point', { type: 'fallback' }),
    ] as ConnectionPoint[];

    return store.createRecord('operator/visual-builder/step/workflow-connector', {
      outwardConnectionPoints,
      type: 'operator/visual-builder/step/workflow-connector',
      actionInputParameterMappings: [],
      action: params?.customAction,
      listTemplates: params?.listTemplates,
    });
  }
}
