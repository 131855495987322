/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { attr } from '@ember-data/model';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import HelpCenterBlock from './help-center-block';
import type LocalizedArticleListContent from '../home-page/localized-article-list-content';
import type SupportedLocale from 'embercom/models/articles/site/supported-locale';

export default class ArticleList extends HelpCenterBlock {
  @attr('string') declare type: 'article-list';
  @attr('boolean') declare enabled: boolean;

  @fragmentArray('customization-options/layout-options/home-page/localized-article-list-content')
  declare localized_content: LocalizedArticleListContent[];

  get title(): string {
    return this.intl.t('articles.settings.stylings.layout-and-sections.articles.header');
  }

  addMissingLocalizedContent(supportedLocales: SupportedLocale[]) {
    supportedLocales.forEach((locale) => this.addMissingContentForLocale(locale.localeId));
  }

  addMissingContentForLocale(locale: string) {
    if (this.contentForLocale(locale)) {
      return;
    }
    let content: LocalizedArticleListContent = this.store.createFragment(
      'customization-options/layout-options/home-page/localized-article-list-content',
      {
        locale,
        title: '',
        links: [],
      },
    );
    content.createDefaultLinks();
    this.localized_content.pushObject(content);
  }

  contentForLocale(locale: string) {
    return this.localized_content.find((content) => content.locale === locale);
  }

  preview(locale: string) {
    if (!this.enabled) {
      return null;
    }
    let content = this.contentForLocale(locale);
    return {
      type: this.type,
      columns: this.columns,
      title: content?.title ?? '',
      links: content?.availableArticleLinks ?? [],
    };
  }
}
