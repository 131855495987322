/* RESPONSIBLE TEAM: team-knowledge-foundations */
import { set } from '@ember/object';
import { type AsyncHasMany, attr, belongsTo, hasMany, type SyncHasMany } from '@ember-data/model';
import { inject as service } from '@ember/service';
import md5 from 'blueimp-md5';
import { RTL_LANGUAGES } from 'embercom/lib/articles/constants';
import type InternalArticleVersion from 'embercom/models/content-service/internal-article-version';
import type Admin from 'embercom/models/admin';
import { MonolingualKnowledgeContentModel } from 'embercom/objects/knowledge-hub/knowledge-hub-content';
import { EntityType } from 'embercom/models/data/entity-types';
import { State } from 'embercom/models/data/matching-system/matching-constants';
import { type EmberCPValidationsCompat } from 'embercom/validations/typescript/ember-cp-validations-compat';
/* eslint-disable-next-line ember/no-computed-properties-in-native-classes */
import { computed } from '@ember/object';
import type Folder from './folder';
import type FolderMembership from './folder-membership';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import { FinAvailability } from 'embercom/lib/ai-content-library/constants';
import { type KnowledgeHubEditorConfig } from 'embercom/objects/knowledge-hub/knowledge-hub-editor-config';
import type Tag from 'embercom/models/tag';
import { put } from 'embercom/lib/ajax';
import { ContentWrapperType } from './content-wrapper';
import { type Taggable } from 'embercom/objects/knowledge-hub/localized-knowledge-content';
import { UPDATE_TAGGING_URL } from 'embercom/lib/knowledge-hub/constants';

export default class InternalArticle
  extends MonolingualKnowledgeContentModel
  implements EmberCPValidationsCompat
{
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare appService: $TSFixMe;

  @attr('string') declare ownerId: string;
  @attr('string') declare createdById: string;
  @attr('string') declare locale: string;
  @attr('number') declare state: number;
  @attr('number') declare finAvailability: number;
  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: Date;
  @attr('boolean') declare isSynced: boolean;
  @attr('array') declare aiContentSegmentIds: Array<number> | [];
  @belongsTo('content-service/internal-article-version', { async: false })
  declare currentVersion?: InternalArticleVersion;
  @belongsTo('content-service/folder', { async: true })
  declare folder?: Folder;
  @belongsTo('content-service/folder-membership', { async: false })
  declare folderMembership?: FolderMembership;

  @attr('number') declare copilotAvailability: FinAvailability;
  @hasMany('tag', { async: true }) declare tags: AsyncHasMany<Tag>;
  @hasMany('tagging', { async: false }) declare taggings: SyncHasMany<any>;

  readonly entityType = EntityType.InternalArticle;

  get owner() {
    return this.app.humanAdmins.findBy('id', this.ownerId);
  }

  set owner(newOwner: Admin) {
    this.ownerId = newOwner.id;
  }

  get creatorId(): number | undefined {
    return Number(this.createdById);
  }

  get writerId(): string {
    return this.ownerId;
  }

  set writerId(newWriterId: string) {
    this.ownerId = newWriterId;
  }

  get updaterId(): number | undefined {
    if (this.currentVersion?.authorId) {
      return Number(this.currentVersion?.authorId);
    }
    return undefined;
  }

  get author() {
    return this.currentVersion?.author;
  }

  get hash() {
    return md5(JSON.stringify(this.serialize()));
  }

  get textDirection() {
    if (RTL_LANGUAGES.includes(this.locale)) {
      return 'rtl';
    }
    return 'ltr';
  }

  get isDraft() {
    return this.state === State.Draft;
  }

  get isLive() {
    return this.state === State.Live;
  }

  get editorConfig(): KnowledgeHubEditorConfig {
    let sidePanelConfig = [{ name: 'knowledge-hub/content-editor/internal-note/data-section' }];

    if (this.appService.app.canUseExternalAi) {
      sidePanelConfig.push({
        name: 'knowledge-hub/content-editor/shared/fin-section',
      });
    }

    sidePanelConfig.push(
      { name: 'knowledge-hub/content-editor/shared/tags-section' },
      ...(!this.appService.app.isStandaloneApp
        ? [{ name: 'knowledge-hub/content-editor/folder-section' }]
        : []),
    );

    return {
      viewMode: {
        headerConfig: { name: 'knowledge-hub/content-editor/internal-note/view/header' },
        editorConfig: { name: 'knowledge-hub/content-editor/internal-note/view/editor' },
        sidePanelConfig,
      },
      editMode: {
        headerConfig: { name: 'knowledge-hub/content-editor/internal-note/edit/header' },
        editorConfig: { name: 'knowledge-hub/content-editor/internal-note/edit/editor' },
        sidePanelConfig,
      },
    };
  }

  get needsReview() {
    if (!this.currentVersion) {
      return false;
    }

    return this.currentVersion.needsReview;
  }

  @computed('hasDirtyAttributes', 'currentVersion.hasDirtyAttributes')
  get hasUnsavedChanges() {
    return this.hasDirtyAttributes || this.currentVersion?.hasDirtyAttributes;
  }

  @computed('isSaving', 'currentVersion.isSaving')
  get saving() {
    return this.isSaving || this.currentVersion?.isSaving;
  }

  // start: KnowledgeHubContent / KnowledgeHubItemInterface

  get displayName(): string {
    return (
      this.currentVersion?.title ||
      this.intl.t('knowledge-hub.internal-note.editor.placeholder-title')
    );
  }

  get parentIdFromRelationship(): string | undefined {
    // @ts-ignore - TS doesn't like the 'folder' key here for some reason
    let folderRelationship = this.belongsTo('folder');
    return folderRelationship?.id();
  }

  get parent(): Folder | undefined {
    return this.folder;
  }

  set parent(parent: Folder | undefined) {
    this.folder = parent;
    this.onParentChange(parent);
  }

  get isEditable(): boolean {
    return !this.isSynced;
  }

  get chatbotAvailabilityOption(): FinAvailability {
    return FinAvailability.OFF;
  }

  get copilotAvailabilityOption(): FinAvailability {
    return this.copilotAvailability;
  }
  set copilotAvailabilityOption(availability: FinAvailability) {
    this.copilotAvailability = availability;
  }

  async isEligibleForChatbot() {
    return false;
  }

  get newOrCurrentVersion(): InternalArticleVersion {
    if (!this.currentVersion) {
      // This has to use `set` because of the use of computed properties for saving / unsaved changes
      set(
        this,
        'currentVersion',
        this.store.createRecord('content-service/internal-article-version', {
          authorId: this.app.currentAdmin.id,
        }),
      );
    }
    return this.currentVersion!;
  }

  get defaultTitle() {
    return this.intl.t('knowledge-hub.internal-note.editor.placeholder-title');
  }

  get title() {
    return this.currentVersion?.title || '';
  }

  set title(title: string) {
    this.newOrCurrentVersion.title = title;
  }

  get latestVersion() {
    return this.currentVersion;
  }

  async updateLanguageCode(languageCode: string) {
    this.locale = languageCode;

    try {
      await this.save();

      this.notificationsService.notifyConfirmation(
        this.intl.t('ai-content-library.file-content.language-update.success'),
      );
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: this.intl.t('ai-content-library.file-content.language-update.error'),
      });
    }
  }

  get taggable(): Taggable {
    return {
      tags: this.tags,
      taggings: this.taggings,
      type: 'internal-article',
      updateTaggings: async (
        _admin: Admin,
        addedTags: Tag[],
        removedTags: Tag[],
        _initialTags: Tag[],
      ) => {
        return await this.updateTaggings(addedTags, removedTags);
      },
    };
  }

  // end: KnowledgeHubContent / KnowledgeHubItemInterface

  async updateTaggings(addedTags: Tag[], removedTags: Tag[]) {
    await put(UPDATE_TAGGING_URL, {
      app_id: this.app.id,
      added_tag_ids: addedTags.map((tag) => tag.id),
      removed_tag_ids: removedTags.map((tag) => tag.id),
      content_wrapper_id: this.id,
      content_wrapper_type: ContentWrapperType.SUPPORT_CONTENT,
      entity_type: EntityType.InternalArticle,
      entity_id: this.id,
    });
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'content-service/internal-article': InternalArticle;
  }
}
