/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Model, { attr, belongsTo } from '@ember-data/model';
import { dependentKeyCompat } from '@ember/object/compat';
import { inject as service } from '@ember/service';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import Admin from 'embercom/models/admin';
import {
  defaultBlockWithModelKey,
  defaultEmptyTextBlockWithModelKey,
  emailTemplateTypes,
  premadeTemplateIDMapping,
  senderTypes,
} from 'embercom/models/data/outbound/constants';
import LocalizedEmailContentValidations from 'embercom/validations/localized-email-content';
import { isBlank, isPresent } from '@ember/utils';
import UserFacingAdminProxy from '../../utils/user-facing-admin-proxy';

export default class LocalizedEmailContentModel extends Model.extend(
  LocalizedEmailContentValidations,
) {
  @service appService;
  @service store;
  @belongsTo('emails/email', { inverse: 'localizedEmailContents' }) email;
  @fragmentArray('common/blocks/block', {
    polymorphic: true,
    typeKey: 'modelKey',
    defaultValue: () => [defaultBlockWithModelKey],
  })
  blocks;
  @fragmentArray('common/blocks/block', {
    polymorphic: true,
    typeKey: 'modelKey',
    defaultValue: () => [defaultEmptyTextBlockWithModelKey],
  })
  subjectBlocks;
  @attr('string') subject;
  @attr('string') locale;
  @attr('string') assignToId;
  @attr('number') assignToType;
  @attr('number') emailTemplateType;
  @attr('string') emailTemplateId;
  @attr('number') senderType;
  @attr('string') senderId;
  @attr('number') replyToType;
  @attr('string') replyToId;

  get app() {
    return this.appService.app;
  }

  get isUsingPremadeTemplate() {
    return this.emailTemplateType === emailTemplateTypes.premade;
  }

  get isUsingCustomTemplate() {
    return this.emailTemplateType === emailTemplateTypes.custom;
  }

  get isHtmlMode() {
    return this.emailTemplateType === emailTemplateTypes.htmlMode;
  }

  get emailTemplate() {
    if (this.isUsingPremadeTemplate) {
      return this.store.peekRecord(
        'email-template',
        premadeTemplateIDMapping[this.emailTemplateId],
      );
    } else if (this.isUsingCustomTemplate) {
      let template = this.store.peekRecord('email-template', this.emailTemplateId);
      //This findRecord check below should be removed. An async call shouldn't be made within a getter
      if (!template) {
        template = this.store.findRecord('email-template', this.emailTemplateId);
      }
      return template;
    } else if (this.isHtmlMode) {
      return this.store.peekRecord('email-template', 'html-block-template');
    } else {
      throw new Error(`Unknown email template`);
    }
  }

  @dependentKeyCompat
  get hasUnsavedChanges() {
    return this.hasDirtyAttributes || this.blocks.any((block) => block.hasDirtyAttributes);
  }

  get isSenderAdmin() {
    return this.senderType === senderTypes.admin;
  }

  get isSenderOwner() {
    return this.senderType === senderTypes.owner;
  }

  @dependentKeyCompat
  get isSenderUsingCustomDomain() {
    return this.isSenderCustomEmail || this.app.emailSenderAddressCustomized;
  }

  get isSenderCustomEmail() {
    return this.senderType === senderTypes.customEmailAddress;
  }

  get isSenderOwnerWithCustomEmailAddressFallback() {
    return this.senderType === senderTypes.ownerWithCustomEmailAddressFallback;
  }

  get isReplyToCustomEmail() {
    return this.replyToType === senderTypes.customEmailAddress;
  }

  get customFromAddress() {
    if (this.isSenderCustomEmail || this.isSenderOwnerWithCustomEmailAddressFallback) {
      return this.store.peekRecord('custom-email-address', this.senderId);
    }
  }

  get isAssignToOwner() {
    return this.assignToType === senderTypes.owner;
  }

  get contentHash() {
    return this.serialize();
  }

  get sender() {
    let sender = Admin.peekAndMaybeLoad(this.store, this.senderId);
    if (sender?.isTeam) {
      throw new Error(`Sending an Email from a team is not supported`);
    }
    return sender;
  }

  get assignTo() {
    return Admin.peekAndMaybeLoad(this.store, this.assignToId);
  }

  get senderAvatar() {
    if (this.isSenderAdmin) {
      return this.sender.avatar;
    }
  }

  get senderName() {
    let senderName;
    if (this.isSenderCustomEmail || this.isSenderOwnerWithCustomEmailAddressFallback) {
      senderName = this.customFromAddress?.displayAs;
    } else if (this.isSenderAdmin || this.isSenderOwner) {
      let name = new UserFacingAdminProxy(this.sender).localized_sender_name;
      let email = this.senderEmail;

      if (name) {
        if (email) {
          senderName = `${name} <${email}>`;
        } else {
          senderName = `${name}`;
        }
      }
    }
    return senderName || 'Unknown';
  }

  get senderEmail() {
    if (this.isSenderCustomEmail || this.isSenderOwnerWithCustomEmailAddressFallback) {
      return this.customFromAddress?.email || '';
    } else if (this.isSenderAdmin || this.isSenderOwner) {
      if (this.app.emailSenderAddressCustomized || this.sender.alias_enabled) {
        return new UserFacingAdminProxy(this.sender).email;
      } else {
        return this.sender.getEmailAddressForApp() || '';
      }
    }
  }

  get subjectBlocksHasContent() {
    if (this.subjectBlocks.length === 0) {
      return false;
    }
    return this.subjectBlocks.any((block) => block.hasOwnProperty('text') && !isBlank(block.text));
  }

  get subjectIsPresent() {
    return isPresent(this.subject);
  }

  @dependentKeyCompat
  get isDomainReturnPathAuthenticated() {
    return this.app.areCustomBounceSettingsValidForEmail(this.senderEmail);
  }

  @dependentKeyCompat
  get _dkimSettings() {
    return this.app.dkim_settings.find((setting) =>
      this.senderEmail.toLowerCase().endsWith(`@${setting.domain}`),
    );
  }

  @dependentKeyCompat
  get isDomainDkimAuthenticated() {
    return isPresent(this._dkimSettings) && this._dkimSettings.validRecordExists;
  }

  @dependentKeyCompat
  get isDomainDmarcAuthenticated() {
    return isPresent(this._dkimSettings) && this._dkimSettings.validDmarcExists;
  }
}
