/* RESPONSIBLE TEAM: team-ai-agent */
import Model, { attr } from '@ember-data/model';
import { type State } from 'embercom/models/data/matching-system/matching-constants';
import { buildValidations, validator } from 'ember-cp-validations';
import { states } from 'embercom/models/data/matching-system/matching-constants';
import { tracked } from '@glimmer/tracking';
import { VISUALIZATION_TYPES } from 'embercom/models/reporting/custom/visualization-options';
import type AiAgentGuidelineService from 'embercom/services/ai-agent-guideline-service';
import { inject as service } from '@ember/service';

const Validations = buildValidations({
  prompt: [
    validator('presence', {
      presence: true,
      messageKey: 'ai-agent.guidance.validation-errors.prompt.empty',
    }),
    validator('length', { max: 2500, min: 1 }),
  ],
  title: [
    validator('presence', {
      presence: true,
      messageKey: 'ai-agent.guidance.validation-errors.title.empty',
    }),
    validator('length', { max: 100, min: 1 }),
  ],
});

// Define the Stat enum in the model since we'll need it here
enum Stat {
  USED = 'used',
  RESOLVED = 'resolved',
  ROUTED = 'routed',
}

export default class Guideline extends Model.extend(Validations) {
  @attr('number') declare state: State;
  @attr('string') declare category: GuidelineCategory;
  @attr('string') declare prompt: string;
  @attr('string') declare title: string;
  @attr({ defaultValue: () => [] }) declare tags: GuidelineTag[];

  @service declare aiAgentGuidelineService: AiAgentGuidelineService;

  @tracked assessment: GuidelineAssessment | null = null;
  @tracked promptModified = false;
  @tracked createdFromTemplate = false;
  @tracked _originalPrompt: string | null = null;
  @tracked _originalTitle: string | null = null;

  get hasPrompt() {
    return this.prompt.trim().length > 0;
  }

  get hasState() {
    return this.state !== undefined;
  }

  get enabled() {
    return this.state === states.live;
  }

  get serializedBlocks() {
    return this.aiAgentGuidelineService.serializedBlocksFromPrompt(this.prompt);
  }

  get chartDefinitions() {
    return {
      used: this.chartDefinitionForStat(Stat.USED),
      resolved: this.chartDefinitionForStat(Stat.RESOLVED),
      routed: this.chartDefinitionForStat(Stat.ROUTED),
    };
  }

  chartDefinitionForStat(stat: Stat): any {
    let metricId = '';
    let chartId = `${this.id}_${stat}`;

    switch (stat) {
      case Stat.USED:
        metricId = 'fin.conversations_participated.count';
        break;
      case Stat.RESOLVED:
        metricId = 'fin.resolution_rate';
        break;
      case Stat.ROUTED:
        metricId = 'fin.routed_to_team_rate';
        break;
      default:
        throw new Error(`Invalid stat: ${stat}`);
    }

    return {
      id: chartId,
      visualization_type: VISUALIZATION_TYPES.COUNTER,
      chart_series: [
        {
          metric_id: metricId,
          filters: {
            type: 'and',
            filters: [
              {
                type: 'category',
                data: {
                  property: 'fin.guidelines_applied.guideline_ids',
                  attribute: 'conversation.fin.guidelines_applied.guideline_ids',
                  values: [this.id],
                },
              },
            ],
          },
        },
      ],
    };
  }

  setAssessment(data: KeysToSnakeCase<GuidelineAssessment>) {
    this.assessment = {
      hasIssue: data.has_issue || false,
      advice: data.advice || null,
      shouldRewrite: data.should_rewrite || false,
      suggestedRewrite: data.suggested_rewrite || null,
      originalPrompt: this.prompt,
      adviceHeadline: data.advice_headline || null,
    };
  }

  discardAssessment() {
    this.assessment = null;
  }

  get hasChangedPromptOrTitle() {
    return this.prompt !== this._originalPrompt || this.title !== this._originalTitle;
  }
}

export type GuidelineCategory = 'tone' | 'clarification' | 'routing' | 'other';

export interface GuidelineAssessment {
  hasIssue: boolean;
  advice: string | null;
  shouldRewrite: boolean;
  suggestedRewrite: string | null;
  originalPrompt: string | null;
  adviceHeadline: string | null;
}

export interface GuidelineTag {
  id: string;
  name: string;
  isSelected: boolean;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'ai-agent/guidelines': Guideline;
  }
}
