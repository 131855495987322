/* RESPONSIBLE TEAM: team-knowledge-foundations */

import Model, {
  attr,
  hasMany,
  belongsTo,
  type SyncHasMany,
  type AsyncHasMany,
} from '@ember-data/model';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import { isEqual } from '@ember/utils';
import { post } from 'embercom/lib/ajax';
import promise from 'embercom/lib/promise';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type ArticleMultilingual from 'embercom/models/article-multilingual';
import type ArticleGroupContent from 'embercom/models/articles/article-group-content';
import ENV from 'embercom/config/environment';

type PositionMap = {
  [key: string]: number;
};

export default class ArticleGroup extends Model {
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare helpCenterService: $TSFixMe;

  @attr('string') declare name: string;
  @attr('string') declare description: string;
  @attr('string', { defaultValue: 'folder' }) declare icon: string;

  @attr('string') declare externalIconUrl?: string;
  @attr('string') declare externalIconType: string;
  @attr('number') declare externalIconId: number;
  @attr('number') declare order: number;
  @attr('string') declare helpCenterId: string;
  @attr('number') declare count: number;
  @attr('boolean') declare readOnly: boolean;
  @attr('string') declare siteProvider: string;

  shouldFocusName = false;

  get read_only() {
    return this.readOnly;
  }
  get app() {
    return this.appService.app;
  }

  @hasMany('articles/article-group', { inverse: 'parent' })
  declare children: SyncHasMany<ArticleGroup>;

  @belongsTo('articles/article-group', { inverse: 'children' })
  declare parent: ArticleGroup;

  @hasMany('article-multilingual', { inverse: 'folder' })
  declare articles: AsyncHasMany<ArticleMultilingual & Model>;

  @hasMany('article-multilingual', { inverse: 'inCollections' })
  declare containedArticles: AsyncHasMany<ArticleMultilingual & Model>;

  @fragmentArray('articles/article-group-content')
  declare localizedContent: SyncHasMany<ArticleGroupContent>;

  get sortedArticles() {
    return this.articles.sortBy('order');
  }

  get sortedChildren() {
    return this.children.sortBy('order');
  }

  get sortedSections() {
    return this.sortedChildren;
  }

  get sortedSiblings() {
    return this.parent.sortedChildren;
  }

  get isHome() {
    return this.id === 'home';
  }

  get isNotHome() {
    return !this.isHome;
  }

  get hasChildren() {
    return this.children.toArray().length > 0;
  }

  get hasArticles() {
    return this.articles.toArray().length > 0;
  }

  get hasContent() {
    return this.hasChildren || this.hasArticles;
  }

  get allArticles() {
    let allArticles = this.articles.toArray();
    this.children.forEach((section) => {
      allArticles = allArticles.concat(section.articles.toArray());
    });
    return allArticles;
  }

  get isFirstSection() {
    return isEqual(this.sortedSiblings.firstObject?.id, this.id);
  }

  get isLastSection() {
    return isEqual(this.sortedSiblings.lastObject?.id, this.id);
  }

  get isCollection() {
    return this.parent?.get('id') === 'home';
  }

  get isSection() {
    return !this.isCollection;
  }

  get isANonHomeSection() {
    return this.isNotHome && this.isSection;
  }

  get type() {
    if (this.isHome) {
      return 'home';
    }
    if (this.isCollection) {
      return 'collection';
    }
    if (this.parent?.get('parent')?.get('id') === 'home') {
      return 'section';
    }
    return 'sub-section';
  }

  get titleizedLocale() {
    return this.defaultLocaleId
      .split('-')
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1, part.length))
      .join('-');
  }

  get defaultLocaleId(): string {
    return this.helpCenterService.site.locale;
  }

  get defaultLocalizedContent() {
    return this.localizedContentByLocaleId(this.defaultLocaleId);
  }

  get hasDefaultLocalizedName() {
    if (!this.defaultLocalizedContent) {
      return false;
    }

    return this.defaultLocalizedContent.name !== '';
  }

  get hasDefaultLocalizedDescription() {
    if (!this.defaultLocalizedContent) {
      return false;
    }

    return this.defaultLocalizedContent.description !== '';
  }

  get defaultNameWithFallback() {
    return this.hasDefaultLocalizedName
      ? this.defaultLocalizedContent?.name
      : this.missingNamePlaceholder;
  }

  get selectedLocaleId() {
    return this.helpCenterService.currentlySelectedLocaleId;
  }

  get selectedLocalizedContent() {
    return this.localizedContentByLocaleId(this.selectedLocaleId);
  }

  get hasSelectedLocalizedName() {
    let name = this.selectedLocalizedContent?.name;
    return !!name && name !== '';
  }

  get hasSelectedLocalizedDescription() {
    let description = this.selectedLocalizedContent?.description;
    return !!description && description !== '';
  }

  get missingNamePlaceholder() {
    return this.intl.t('articles.collections.list.placeholder.missing_name');
  }

  get missingDescriptionPlaceholder() {
    return this.intl.t('articles.collections.list.placeholder.missing_description');
  }

  get iconUrl() {
    return this.externalIconUrl
      ? this.externalCustomIconUrl
      : this.intercomIconUrl(`icon:${this.icon}`);
  }

  get externalCustomIconUrl() {
    if (this.externalIconId && this.externalIconUrl?.endsWith('.svg')) {
      return this.intercomIconUrl(this.externalIconId.toString());
    }

    return this.externalIconUrl;
  }

  private intercomIconUrl(endpoint: string) {
    let baseUrl =
      ENV.environment === 'development' || ENV.environment === 'test'
        ? 'http://help-center.test'
        : 'https://intercom.help';
    let helpCenterIdentifier = this.helpCenterService.site.identifier;

    // disabling linter since the icons are hosted in the HC domain
    // eslint-disable-next-line @intercom/intercom/use-asset-url
    return `${baseUrl}/${helpCenterIdentifier}/assets/svg/${endpoint}`;
  }

  localizedContentByLocaleId(localeId: string) {
    return this.localizedContent.findBy('localeId', localeId);
  }

  reorder(newIndex: number, positionMap: PositionMap = {}, helpCenterId?: string) {
    return post(`/ember/article_folders/${this.id}/reorder`, {
      order: newIndex,
      position_map: positionMap,
      help_center_id: helpCenterId,
      app_id: this.app.id,
    });
  }

  saveIfDirty() {
    if (Boolean(this.hasDirtyAttributes) && !this.isSaving) {
      return this.save();
    } else {
      return promise();
    }
  }

  createContent(localeId: string) {
    if (!this.localizedContentByLocaleId(localeId)) {
      this.localizedContent.createFragment({ localeId });
    }
  }

  initLocalizedContent() {
    this.helpCenterService.site.selectedLocales.map((locale: { localeId: string }) => {
      this.createContent(locale.localeId);
    });
  }

  // eslint-disable-next-line @intercom/intercom/no-bare-strings
  static createNewFolder(parent: ArticleGroup, prefix = 'Section heading', helpCenterId?: string) {
    let newFolderName = `${prefix}`;
    let existingFolders = parent.children;
    let nameInUse = false;
    let nextIndex = 1;

    do {
      nameInUse = !!existingFolders.findBy('name', newFolderName);
      if (nameInUse) {
        newFolderName = `${prefix} (${nextIndex++})`;
      }
    } while (nameInUse);

    let maxOrder = Math.max(...existingFolders.map((f) => f.order));
    let folder = parent.store.createRecord('articles/article-group', {
      name: newFolderName,
      order: maxOrder + 1,
      helpCenterId,
    });
    folder.set('parent', parent);
    return folder;
  }
}
