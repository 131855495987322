/* RESPONSIBLE TEAM: team-knowledge-foundations */
export default class UrlPartialFilter {
  static type = 'url-partial';
  static icon = 'link';
  static labelKey = 'articles.list.search.link-contains';

  static create({ value }) {
    return new UrlPartialFilter(value);
  }

  type = UrlPartialFilter.type;
  icon = UrlPartialFilter.icon;
  labelKey = UrlPartialFilter.labelKey;

  constructor(value) {
    this.value = value;
  }

  get text() {
    return this.value;
  }
}
